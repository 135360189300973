import classNames from 'classnames';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, Spinner, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useBreadcrumbs } from '@hooks/panel.hooks';
import { useProjects } from '@selectors/consumer/projects.selectors';
import { LoginServerSettingsTab, EndpointSettingsTab } from '../../../../consumer/projects/tabs';
import { withPage } from '../../../../panel';

interface EditLoginServerPagePath {
    server_id: string;
}

const EditLoginServerPage: React.FC = () => {

    const [currentTab, openTab] = useState(0);
    const { server_id } = useParams<EditLoginServerPagePath>();
    const { loading, data: { loginServers, projects } } = useProjects();

    const server = loginServers.find(l => l.id.toString() === server_id);

    const project = server && projects.find(p => p.id === server.projectId);

    useBreadcrumbs('admin.consumer.project.login.name', true, server?.name);
    useBreadcrumbs('admin.consumer.project.name', false, project?.name, `/projects/${project?.id}/`);

    if (!server) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        Настройки сервера авторизации
                    </CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                    {loading ? <Spinner style={{ width: '3rem', height: '3rem' }} /> : 'Сервер не найден'}
                </CardBody>
            </Card>
        )
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    Настройки сервера авторизации
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: currentTab === 0 })}
                            onClick={() => openTab(0)}>
                            Основные
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: currentTab === 1 })}
                            onClick={() => openTab(1)}>
                            Подключение
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={currentTab}>
                    <TabPane tabId={0}>
                        <LoginServerSettingsTab server={server} />
                    </TabPane>
                    <TabPane tabId={1}>
                        <EndpointSettingsTab
                            projectId={server.projectId}
                            serverId={server.id} />
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    )

}

export default withPage(EditLoginServerPage);