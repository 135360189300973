import ServiceBase from "@api/serviceBase";
import {ICurrency} from "@interfaces/consumer/payments";
import {PatchCurrencyModel, PutCurrencyModel} from "@models/consumer/currencies";

export default class CurrenciesService extends ServiceBase {
    getCurrencies(projectId: string) {
        return this.getAndThrowApi<ICurrency[]>(`/admin/payments/currencies/${projectId}/`);
    }

    putCurrency(projectId: string, currency: PutCurrencyModel) {
        return this.putApi<ICurrency>(`/admin/payments/currencies/${projectId}/`, currency);
    }

    patchCurrency(projectId: string, currency: PatchCurrencyModel) {
        return this.patchApi<ICurrency>(`/admin/payments/currencies/${projectId}/`, currency);
    }

    getCurrency(projectId: string, id: string) {
        return this.getAndThrowApi<ICurrency>(`/admin/payments/currencies/${projectId}/${id}/`);
    }

    deleteCurrency(projectId: string, id: string) {
        return this.deleteApi(`/admin/payments/currencies/${projectId}/${id}/`);
    }
}