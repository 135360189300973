import React from 'react';
import { useSidebarState } from '@selectors/panel.selectors';
import { PanelNavbar } from '../panel/navbar';
import { Sidebar } from '../panel/sidebar';
import {useSelector} from "react-redux";
import {IStore} from "@store";
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

const DashboardLayout: React.FC = ({children}) => {

    const { isOnRight } = useSidebarState();

    return (
        <div className="wrapper">
            {!isOnRight && <Sidebar />}
            <div className="main">
                <PanelNavbar />
                <div className="content">
                    {children}
                </div>
                <Footer />
            </div>
            {isOnRight && <Sidebar />}
        </div>
    )
}

const Footer: React.FC = React.memo(() => {

    const projectName = useSelector(({ panel }: IStore) => panel.settings.projectName);

    return (
        <footer className="footer">
            <Container fluid>
                <Row className="text-muted">
                    <Col xs={8} className="text-left">
                        {/* <ul className="list-inline">
            <li className="list-inline-item">
              <Link to="dashboard" className="text-muted mr-1">
                Support
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="dashboard" className="text-muted mr-1">
                Privacy
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="dashboard" className="text-muted mr-1">
                Terms of Service
              </Link>
            </li>
            <li className="list-inline-item">
              <Link to="dashboard" className="text-muted">
                Contact
              </Link>
            </li>
          </ul> */}
                    </Col>
                    <Col xs={4} className="text-right">
                        <p className="mb-0">
                            &copy; {new Date().getFullYear()} -{" "}
                            <Link to="/" className="text-muted">
                                {projectName}
                            </Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
});

export default DashboardLayout;