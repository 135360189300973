
export interface IMasterProxy {
    id: string;
    serverId: number;
    ownerId?: string;
    projectId?: string;
    publicIP: string | null;
    privateIP: string | null;
    isPublic: boolean;
    port: number;
    createTime: string;
    state: EProxyServerState;
}

export enum EProxyServerState {
    Creating,
    Installing,
    Active,
    Error
}