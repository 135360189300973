import { useParams } from 'react-router-dom';
import { Card, CardBody, Spinner, CardHeader, CardTitle } from 'reactstrap';
import { useBreadcrumbs } from '@hooks/panel.hooks';
import { useProjects } from '@selectors/consumer/projects.selectors';
import { ProjectSettingsTab } from '../../../consumer/projects/tabs';
import { withPage } from '../../../panel';
import {FC} from "react";

interface ProjectEditPagePath {
    project_id: string;
}

const ProjectEditPage: FC = () => {

    const { loading, data: { projects } } = useProjects();
    const { project_id } = useParams<ProjectEditPagePath>();

    const project = projects.find(p => p.id === project_id);

    useBreadcrumbs('admin.consumer.project.name', false, project?.name, `/projects/${project?.id}/`);

    if (!project) {
        return (
            <Card>
                <CardBody className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Проект не найден"
                    )}
                </CardBody>
            </Card>
        )
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Настройки проекта {project.name}
                </CardTitle>
            </CardHeader>
            <ProjectSettingsTab project={project} />
        </Card>
    )
}

export default withPage(ProjectEditPage);