import ServiceBase from "@api/serviceBase";
import { IApiToken, IApiTokenFull } from "@interfaces/consumer/projects";
import { PutApiToken } from "@models/consumer/projects";

export default class ApiTokensService extends ServiceBase {

    async getTokens(projectId: string) {
        return await this.getApi<IApiToken[]>(`/api/tokens/${projectId}/`);
    }

    async putToken(model: PutApiToken) {
        return await this.putApi<IApiTokenFull>('/api/tokens/', model);
    }

    async deleteToken(tokenId: string) {
        return await this.deleteApi(`/api/tokens/${tokenId}/`);
    }
}