import ServiceBase from "@api/serviceBase";
import {
    EPaymentCurrency, IAntilopaySettings,
    IEnotPaymentSettings,
    IPaymentSettings,
    IPayPalychSettings,
    IPrimePaymentSettings, IProjectSettings, IStreamPaySettings,
    IUnitpayPaymentSettings
} from "@interfaces/consumer/payments";
import {
    IPatchProjectSettingsModel, PatchAntilopayPaymentSettings,
    PatchEnotPaymentSettings,
    PatchPayPalychPaymentSettings,
    PatchPrimePaymentSettings, PatchStreamPayPaymentSettings,
    PatchUnitpayPaymentSettings, PutAntilopayPaymentSettings,
    PutEnotPaymentSettings,
    PutPayPalychPaymentSettings,
    PutPrimePaymentSettings, PutStreamPayPaymentSettings,
    PutUnitpayPaymentSettings
} from "@models/consumer/payments";

export default class PaymentsService extends ServiceBase {

    async getUnitpaySettings(id: string) {
        return await this.getApi<IUnitpayPaymentSettings>(`/admin/projects/payments/unitpay/${id}/`);
    }

    async patchUnitpaySettings(settings: PatchUnitpayPaymentSettings) {
        return await this.patchApi<IUnitpayPaymentSettings>('/admin/projects/payments/unitpay/', settings);
    }

    async putUnitpaySettings(settings: PutUnitpayPaymentSettings) {
        return await this.putApi<IUnitpayPaymentSettings>('/admin/projects/payments/unitpay/', settings);
    }

    async deleteUnitpaySettings(id: string) {
        return await this.deleteApi(`/admin/projects/payments/unitpay/${id}/`);
    }

    async getEnotSettings(id: string) {
        return await this.getApi<IEnotPaymentSettings>(`/admin/projects/payments/enot/${id}/`);
    }

    async patchEnotSettings(settings: PatchEnotPaymentSettings) {
        return await this.patchApi<IEnotPaymentSettings>('/admin/projects/payments/enot/', settings);
    }

    async putEnotSettings(settings: PutEnotPaymentSettings) {
        return await this.putApi<IEnotPaymentSettings>('/admin/projects/payments/enot/', settings);
    }

    async deleteEnotSettings(id: string) {
        return await this.deleteApi(`/admin/projects/payments/enot/${id}/`);
    }

    async getPrimeSettings(id: string) {
        return await this.getApi<IPrimePaymentSettings>(`/admin/projects/payments/prime/${id}/`);
    }

    async patchPrimeSettings(settings: PatchPrimePaymentSettings) {
        return await this.patchApi<IPrimePaymentSettings>('/admin/projects/payments/prime/', settings);
    }

    async putPrimeSettings(settings: PutPrimePaymentSettings) {
        return await this.putApi<IPrimePaymentSettings>('/admin/projects/payments/prime/', settings);
    }

    async deletePrimeSettings(id: string) {
        return await this.deleteApi(`/admin/projects/payments/prime/${id}/`);
    }

    async getPayPalychSettings(id: string) {
        return await this.getApi<IPayPalychSettings>(`/admin/projects/payments/paypalych/${id}/`);
    }

    async patchPayPalychSettings(settings: PatchPayPalychPaymentSettings) {
        return await this.patchApi<IPayPalychSettings>('/admin/projects/payments/paypalych/', settings);
    }

    async putPayPalychSettings(settings: PutPayPalychPaymentSettings) {
        return await this.putApi<IPayPalychSettings>('/admin/projects/payments/paypalych/', settings);
    }

    async deletePayPalychSettings(id: string) {
        return await this.deleteApi(`/admin/projects/payments/paypalych/${id}/`);
    }

    async getAntilopaySettings(id: string) {
        return await this.getApi<IAntilopaySettings>(`/admin/projects/payments/antilopay/${id}/`);
    }

    async patchAntilopaySettings(settings: PatchAntilopayPaymentSettings) {
        return await this.patchApi<IAntilopaySettings>('/admin/projects/payments/antilopay/', settings);
    }

    async putAntilopaySettings(settings: PutAntilopayPaymentSettings) {
        return await this.putApi<IAntilopaySettings>('/admin/projects/payments/antilopay/', settings);
    }

    async deleteAntilopaySettings(id: string) {
        return await this.deleteApi(`/admin/projects/payments/antilopay/${id}/`);
    }

    async getStreamPaySettings(id: string) {
        return await this.getApi<IStreamPaySettings>(`/admin/projects/payments/streampay/${id}/`);
    }

    async patchStreamPaySettings(settings: PatchStreamPayPaymentSettings) {
        return await this.patchApi<IStreamPaySettings>('/admin/projects/payments/streampay/', settings);
    }

    async putStreamPaySettings(settings: PutStreamPayPaymentSettings) {
        return await this.putApi<IStreamPaySettings>('/admin/projects/payments/streampay/', settings);
    }

    async deleteStreamPaySettings(id: string) {
        return await this.deleteApi(`/admin/projects/payments/streampay/${id}/`);
    }

    async fetchPaymentsSettings() {
        return await this.getAndThrowApi<IPaymentSettings>('/admin/projects/payments/');
    }

    getProjectSettings(projectId: string) {
        return this.getApi<IProjectSettings>(`/admin/payments/settings/${projectId}/`);
    }

    patchProjectSettings(projectId: string, model: IPatchProjectSettingsModel) {
        return this.patchApi<IProjectSettings>(`/admin/payments/settings/${projectId}/`, model);
    }
}