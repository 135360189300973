import React, {FC, useCallback} from "react";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {useParams} from "react-router-dom";
import {useStreamPay} from "@selectors/consumer/payments.selectors";
import {IStreamPaySettings} from "@interfaces/consumer/payments";
import {paymentActions} from "@store/consumer/projectsStore";
import {CardBody} from "reactstrap";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";
import SettingsForm from "@components/consumer/payments/settings/settingsForm";

const StreamPaySettingsForm: FC = () => {

    const dispatch = usePromisedDispatch();
    const {settings_id} = useParams<{settings_id: string}>();
    const settings = useStreamPay(settings_id);

    const updateStreamPay = useCallback(async (settings: IStreamPaySettings) =>
        await dispatch(paymentActions.updateStreamPay(settings)), [dispatch]);

    if(!settings) {
        return (
            <CardBody>Настройки не найдены</CardBody>
        )
    }

    return (
        <SettingsForm settings={settings} updateSettings={updateStreamPay}>
            <Field
                label="Store ID"
                type="text"
                name="merchantId"
                placeholder="s+k2vmp/CVR..."
                disabled
                component={FormikRowInput} />
            <Field
                label="Публичный ключ"
                type="textarea"
                name="publicKey"
                placeholder="baea03e67..."
                description="Оставьте поле пустым, если не хотите менять ключ"
                component={FormikRowInput} />
            <Field
                label="Приватный ключ"
                type="textarea"
                name="privateKey"
                placeholder="4ec3e7846..."
                description="Оставьте поле пустым, если не хотите менять ключ"
                component={FormikRowInput} />
        </SettingsForm>
    )
}

export default StreamPaySettingsForm;