import React, { useCallback, useState } from 'react';
import { useProjects } from '@selectors/consumer/projects.selectors';
import { ProjectListTable } from '../../../consumer/projects';
import { AddProjectModal } from '../../../consumer/projects/modals';
import { withPage } from '../../../panel';

const ProjectsPage: React.FC = () => {

    const [isModalOpened, setModalOpened] = useState(false);
    const toggleModal = useCallback(() => setModalOpened(!isModalOpened), [isModalOpened]);
    const { loading, data: { projects } } = useProjects();

    return (
        <React.Fragment>
            <ProjectListTable
                projects={projects}
                onAddClick={toggleModal}
                loading={loading} />
            <AddProjectModal isOpen={isModalOpened} toggle={toggleModal} />
        </React.Fragment>
    )
}

export default withPage(ProjectsPage);