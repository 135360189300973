import ApiTokensService from "@api/consumer/apiTokensService";
import { IApiToken } from "@interfaces/consumer/projects";
import { FC, useCallback, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface DeleteApiTokenModalProps {
    token?: IApiToken;
    isOpen: boolean;
    toggle: () => void;
    reload: () => void;
}

const DeleteApiTokenModal: FC<DeleteApiTokenModalProps> = ({ token, isOpen, toggle, reload }) => {

    const [disabled, setDisabled] = useState(false);

    const deleteToken = useCallback(async () => {

        if(!token) {
            return;
        }
        setDisabled(true);
        const api = new ApiTokensService();
        const result = await api.deleteToken(token.id);
        setDisabled(false);
        if(result.ok) {
            toastr.success("Удаление", `Токен ${token.name} успешно удален`);
            reload();
            toggle();
        }
        else {
            if(result.errorCode === 'TokenNotFound') {
                toastr.error("Удаление", `Токен не найден`);
            }
            else {
                toastr.error("Удаление", `Что-то пошло не так`);
            }
        }
    }, [toggle, token]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Удаление токена {token?.name}
            </ModalHeader>
            <ModalBody>
                Вы действительно хотите удалить токен {token?.name} ({token?.token})? Это действие нелья отменить
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" type="button" disabled={disabled} onClick={toggle}>
                    Отмена
                </Button>{" "}
                <Button type="button" color="primary" disabled={disabled} onClick={deleteToken}>
                    Удалить токен
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteApiTokenModal;