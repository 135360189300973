import { FormikHelpers, Formik, Form, Field } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { CreateProjectModel } from '../../../../models/consumer/projects';
import { projectActions } from '../../../../redux/consumer/projectsStore';
import { toPromise } from '../../../../utils/helpers';
import * as Yup from 'yup';
import { FormikInput } from '../../../panel/formik';

interface AddProjectModalProps {
    isOpen: boolean;
    toggle(): void;
}

interface AddProjectModalForm {
    name: string;
    description: string;
    url: string;
}

const AddProjectModal: React.FC<AddProjectModalProps> = ({ toggle, isOpen, }) => {

    const dispatch = useDispatch();

    const onSubmit = useCallback(async (values: AddProjectModalForm, actions: FormikHelpers<AddProjectModalForm>) => {

        const project: CreateProjectModel = {
            name: values.name,
            description: values.description,
            url: values.url,
        }

        const result = await toPromise(dispatch(projectActions.addProject(project)));
        if (result.ok) {
            await dispatch(projectActions.fetchSmtps());
            toggle();
        }
        else {
            if (result.errorCode === 'HttpsOnly') {
                actions.setFieldError('url', 'Только https');
            }
        }
    }, [dispatch, toggle]);

    const validation = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .min(6)
            .max(64)
            .required(),
        description: Yup.string()
            .max(256)
            .notRequired(),
        url: Yup.string()
            .url()
            .min(6)
            .max(64)
            .required()
    }), []);

    const initial: AddProjectModalForm = {
        name: "",
        description: "",
        url: ""
    }

    return (

        <Modal
            isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Создание нового проекта
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <ModalBody>
                            <Field
                                label="Название проекта"
                                type="text"
                                name="name"
                                placeholder="Введите название проекта"
                                component={FormikInput} />
                            <Field
                                label="Описание проекта"
                                type="text"
                                name="description"
                                placeholder="Введите описание проекта (необязательно)"
                                component={FormikInput} />
                            <Field
                                label="URL проекта"
                                type="text"
                                name="url"
                                placeholder="Введите url проекта"
                                component={FormikInput} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" onClick={toggle}>
                                Закрыть
                            </Button>{" "}
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Создать проект
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>

    )
}

export default AddProjectModal;