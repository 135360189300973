import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { Loadable } from "../../interfaces/helpers";
import { MasterStorage } from "../../interfaces/master/storages";
import { PatchMasterStorageModel, PutMasterStorageModel } from "../../models/master/storages";
import MasterStoragesService from "../../services/master/masterStorageService";

export type MasterStoragesStore = Loadable<MasterStorage[]>;

const storagesSlice = createSlice({
    name: 'masterStorageStore',
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as MasterStoragesStore,
    reducers: {
        setStorages: (state, action: PayloadAction<MasterStorage[]>) => {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
            state.error = null;
        },
        setLoading: (state) => {
            state.error = null;
            state.loading = true;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.loaded = false;
            state.data = [];
        },
        updateStorage: (state, action: PayloadAction<MasterStorage>) => {
            const idx = state.data.findIndex(s => s.id === action.payload.id);
            if(idx >= 0) {
                state.data[idx] = action.payload;
            }
            else {
                state.data.push(action.payload);
            }
        }
    }
});

export const { reducer: masterStoragesReducer } = storagesSlice;

const { setStorages, updateStorage, setLoading, setError } = storagesSlice.actions;

export const masterStoragesActions = {
    fetchStorages: () => async (dispatch: Dispatch) => {
        const api = new MasterStoragesService();
        dispatch(setLoading());
        try {
            const storages = await api.fetchStorages();
            dispatch(setStorages(storages));
            return storages;
        }
        catch(e) {
            dispatch(setError(e));
        }
    },
    addStorage: (storage: PutMasterStorageModel) => async (dispatch: Dispatch) => {
        const api = new MasterStoragesService();
        const result = await api.putStorage(storage);
        if(result.ok && result.result) {
            dispatch(updateStorage(result.result));
        }
        return result;
    },
    updateStorage: (storage: PatchMasterStorageModel) => async (dispatch: Dispatch) => {
        const api = new MasterStoragesService();
        const result = await api.patchStorage(storage);
        if(result.ok && result.result) {
            dispatch(updateStorage(result.result));
        }
        return result;
    }
}