import React from 'react';
import { faArrowAltCircleRight, faCog, faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const NavbarSettings: React.FC = () => {

    const history = useHistory();

    const linkTo = (url: string) => {
        history.push(url);
    }

    return (
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
            <DropdownToggle nav caret>
                <FontAwesomeIcon icon={faCog} className="align-middle" />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem onClick={() => linkTo('/settings/')}>
                    <FontAwesomeIcon
                        icon={faCogs}
                        fixedWidth
                        className="mr-2 align-middle"
                    />
                    Настройки
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => linkTo('/logout/')}>
                    <FontAwesomeIcon
                        icon={faArrowAltCircleRight}
                        fixedWidth
                        className="mr-2 align-middle"
                    />
                    Выйти
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default React.memo(NavbarSettings);