import {FC} from "react";
import {Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap";
import {CurrenciesList} from "@components/consumer/payments/currencies";
import {AnimatedFontAwesomeIcon, withPage} from "@components/panel";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {useHistory} from "react-router-dom";

const CurrenciesPage: FC = () => {

    const projectId = useCurrentProjectId();
    const history = useHistory();
    const onAdd = () => {
        history.push(`/p/${projectId}/ps/cr/add`);
    }

    return (
        <Card>
            <CardHeader>
                <div className='card-actions float-end'>
                    <AnimatedFontAwesomeIcon
                        icon={faPlus}
                        onClick={onAdd}
                    />
                </div>
                <CardTitle>
                    Валюты
                </CardTitle>
            </CardHeader>
            <CardBody>
                <CurrenciesList />
            </CardBody>
        </Card>
    )
}

export default withPage(CurrenciesPage);