import React, {FC, useCallback} from "react";
import {useModal, usePromisedDispatch} from "../../../../utils/helpers";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {useAntilopays} from "@selectors/consumer/payments.selectors";
import {useHistory} from "react-router-dom";
import {paymentActions} from "@store/consumer/projectsStore";
import {PutAntilopayPaymentSettings} from "@models/consumer/payments";
import {GatewaysList} from "@components/consumer/payments";
import {AddSettingsModal} from "@components/consumer/projects/payments";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";


const AntilopayList: FC = () => {
    const dispatch = usePromisedDispatch();
    const project_id = useCurrentProjectId();
    const gateways = useAntilopays(project_id);
    const {isOpened: isEOpened, toggle: toggleAntilopay} = useModal();
    const history = useHistory();

    const onSelect = useCallback((gatewayId: string) => {
        history.push(`/p/${project_id}/ps/gw/anp/${gatewayId}/`);
    }, [history, project_id])

    const onDelete = useCallback((gatewayId: string) => dispatch(paymentActions.deleteAntilopay(gatewayId)),
        [dispatch]);

    const createAntilopay = useCallback(async (model: PutAntilopayPaymentSettings) => await dispatch(paymentActions.addAntilopay(model)), [dispatch]);
    const nextAntilopayOrder = gateways.reduce((order, settings) => Math.max(order, settings.order + 1), 0);

    return (
        <>
            <GatewaysList
                title="Antilopay"
                gateways={gateways}
                onSelect={onSelect}
                onDelete={onDelete}
                onAdd={toggleAntilopay}
            />
            <AddSettingsModal
                isOpen={isEOpened}
                toggle={toggleAntilopay}
                projectId={project_id}
                nextOrder={nextAntilopayOrder}
                createSettings={createAntilopay}>
                <Field
                    label="Идентификатор мерчанта"
                    type="text"
                    name="merchantId"
                    placeholder="s+k2vmp/CVR..."
                    component={FormikRowInput} />
                <Field
                    label="Идентификатор проекта мерчанта"
                    type="text"
                    name="projectIdentificator"
                    placeholder="PEFF..."
                    component={FormikRowInput} />
                <Field
                    label="Название товара/услуги"
                    type="text"
                    name="productName"
                    placeholder="Пополнение баланса"
                    component={FormikRowInput} />
            </AddSettingsModal>
        </>
    )
}

export default AntilopayList;