import {FC, useState} from "react";
import {withPage} from "@components/panel";
import {Card, CardBody, CardHeader, CardText, CardTitle, Col, Input, Row} from "reactstrap";

const PermissionsPage: FC = () => {
  const [modules, setModules] = useState<Module[]>([
    { id: 1, name: 'Инвентарь', description: 'Описание модуля Инвентарь', price: '1000', active: false },
    { id: 2, name: 'Подарки', description: 'Описание модуля Подарки', price: '2000', active: false },
    // добавьте здесь другие модули
  ]);

  const handleToggle = (id: number) => {
    setModules(modules.map(module =>
      module.id === id ? { ...module, active: !module.active } : module
    ));
  };

  return (
    <Card>
        <CardHeader>
            <CardTitle tag="h5">Модули</CardTitle>
        </CardHeader>
        <CardBody className="bg-body-tertiary">
            <div className="d-flex flex-column gap-3">
                <Row>
                    {modules.map(module =>
                        <ModuleCard key={module.id} module={module} onToggle={handleToggle} />
                    )}
                </Row>
            </div>
        </CardBody>
    </Card>
  );
};

interface Module {
    id: number;
    name: string;
    description: string;
    price: string;
    active: boolean;
}

interface ModuleCardProps {
    module: Module;
    onToggle: (id: number) => void;
}

const ModuleCard: FC<ModuleCardProps> = ({ module, onToggle }) => {
  return (
    <Col className="m-2 bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
        style={{padding: "1.25rem"}}>
        <div className="d-flex align-items-start align-items-sm-center">
            <div className="fs-9 form-check me-2 me-xxl-3 mb-0">
                <Input
                    type="checkbox"
                    id={`module-${module.id}`}
                    checked={module.active}
                    onChange={() => onToggle(module.id)}
                />
            </div>
            <div className="vr" />
            <div className="ms-1 ms-sm-3">
                <p className="fw-semibold mb-3 mb-sm-2">{module.name}</p>
                <div className="align-items-center gx-0 gy-2 row">
                    <div className="me-2 col-auto">
                        <h6>Descs</h6>
                    </div>
                </div>
            </div>
        </div>
    </Col>
  );
};

export default withPage(PermissionsPage);