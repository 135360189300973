import StoragesService from "@api/consumer/storagesService";
import { IStorage } from "@interfaces/consumer/projects";
import { Loadable } from "@interfaces/helpers";
import { PatchStorageModel, PutStorageModel } from "@models/consumer/storages";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export type StoragesStore = Loadable<IStorage[]>;

const storagesSlice = createSlice({
    name: 'storageStore',
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: []
    } as StoragesStore,
    reducers: {
        setStorages: (state, action: PayloadAction<IStorage[]>) => {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
            state.error = null;
        },
        setLoading: (state) => {
            state.error = null;
            state.loading = true;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.loaded = false;
            state.data = [];
        },
        updateStorage: (state, action: PayloadAction<IStorage>) => {
            const idx = state.data.findIndex(s => s.id === action.payload.id);
            if(idx >= 0) {
                state.data[idx] = action.payload;
            }
            else {
                state.data.push(action.payload);
            }
        },
        deleteStorage: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter(s => s.id !== action.payload);
        }
    }
});

export const { reducer: storagesReducer } = storagesSlice;

const { setStorages, updateStorage, deleteStorage, setLoading, setError } = storagesSlice.actions;

export const storagesActions = {
    fetchStorages: () => async (dispatch: Dispatch) => {
        const api = new StoragesService();
        dispatch(setLoading());
        try {
            const storages = await api.getStorages();
            dispatch(setStorages(storages));
            return storages;
        }
        catch(e) {
            dispatch(setError(e));
        }
    },
    fetchStorage: (id: string) => async (dispatch: Dispatch) => {
        const api = new StoragesService();
        try {
            const storage = await api.getStorage(id);
            dispatch(updateStorage(storage));
        } catch (error) {
            dispatch(deleteStorage(id));
        }
    },
    addStorage: (storage: PutStorageModel) => async (dispatch: Dispatch) => {
        const api = new StoragesService();
        const result = await api.putStorage(storage);
        if(result.success) {
            dispatch(updateStorage(result.data));
        }
        return result;
    },
    updateStorage: (storage: PatchStorageModel) => async (dispatch: Dispatch) => {
        const api = new StoragesService();
        const result = await api.patchStorage(storage);
        if(result.success) {
            dispatch(updateStorage(result.data));
        }
        return result;
    },
    deleteStorage: (id: string) => async (dispatch: Dispatch) => {
        const api = new StoragesService();
        const result = await api.deleteStorage(id);
        if(result.ok) {
            dispatch(deleteStorage(id));
        }
        return result;
    }
}