import ApiTokensService from "@api/consumer/apiTokensService";
import { FormikInput } from "@components/panel/formik";
import { IApiTokenFull } from "@interfaces/consumer/projects";
import { KeyValueSet } from "@interfaces/helpers";
import { Field, Form, Formik } from "formik";
import { FC } from "react";
import { toastr } from "react-redux-toastr";
import { Button, Input, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface CreateApiTokenModalProps {
    projectId: string;
    toggle: () => void;
    isOpen: boolean;
    reload: () => void;
    showToken: (token: IApiTokenFull) => void;
}

interface CreateApiTokenModalForm {
    name: string;
    expireTime: string;
    roles: string[]
}

const roles = [
    "api.user.read",
    "api.user.write",
    "api.item.read",
    "api.item.write",
    "api.query.read",
    "api.query.write",
    "api.query.execute",
    "api.goal.read",
    "api.goal.write",
    "api.goal.reach"
];

const roleNames: KeyValueSet<string> = {
    "api.user.read": 'Чтение пользователей',
    "api.user.write": 'Редактирование пользователей',
    "api.item.read": 'Чтение предметов',
    "api.item.write": 'Редактирование предметов',
    "api.query.read": 'Чтение запросов',
    "api.query.write": 'Обновление запросов',
    "api.query.execute": 'Выполнение запросов',
    "api.goal.read": 'Чтение достижений',
    "api.goal.write": 'Редактирование достижений',
    "api.goal.reach": 'Выдача достижений'
}

const CreateApiTokenModal: FC<CreateApiTokenModalProps> = ({ projectId, toggle, isOpen, reload, showToken }) => {

    const initial: CreateApiTokenModalForm = {
        name: '',
        expireTime: '',
        roles: []
    }

    const onSubmit = async (values: CreateApiTokenModalForm) => {
        const api = new ApiTokensService();
        const result = await api.putToken({
            projectId,
            name: values.name,
            expireTime: values.expireTime || undefined,
            roles: values.roles
        });

        if (result.success) {
            toastr.success('Создание токена', 'Токен успешно создан');
            reload();
            showToken(result.data);
            toggle();
        }
        else {
            if (result.errorCode === 'EmptyRoles') {
                toastr.error('Создание токена', 'У токена должны быть права доступа');
            }
            else if (result.errorCode === 'UnknownRoles') {
                toastr.error('Создание токена', 'Неизвестные права');
            }
            else if (result.errorCode === 'TooMuch') {
                toastr.error('Создание токена', 'На проекте слишком много токенов');
            }
            else {
                toastr.error('Создание токена', 'Что-то пошло не так');
            }
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Создание нового токена
            </ModalHeader>
            <Formik
                initialValues={initial}
                onSubmit={onSubmit}>
                {
                    ({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <ModalBody>
                                <Field
                                    label="Название токена"
                                    type="text"
                                    name="name"
                                    placeholder="Введите название токена"
                                    component={FormikInput} />
                                <Field
                                    label="Дата истечения"
                                    type="datetime-local"
                                    name="expireTime"
                                    placeholder="Введите название токена"
                                    component={FormikInput} />
                                <FormGroup>
                                    <Label>Права доступа</Label>
                                    {roles.map(role => (
                                        <FormGroup check key={role}>
                                            <Input
                                                id={`role-${role}`}
                                                type='checkbox'
                                                checked={values.roles.includes(role)}
                                                onChange={e => {
                                                    const roles = values.roles.filter(r => r !== role);
                                                    if (e.target.checked) {
                                                        roles.push(role);
                                                    }
                                                    setFieldValue('roles', roles);
                                                }} />
                                            <Label check for={`role-${role}`}>{roleNames[role]}</Label>
                                        </FormGroup>
                                    ))}
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" type="button" onClick={toggle}>
                                    Закрыть
                                </Button>{" "}
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    Создать токен
                                </Button>
                            </ModalFooter>
                        </Form>
                    )
                }
            </Formik>
        </Modal>
    )
}

export default CreateApiTokenModal;