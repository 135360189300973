import { IApiTokenFull } from "@interfaces/consumer/projects";
import { FC } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ShowTokenModalProps {
    token?: IApiTokenFull;
    isOpen: boolean;
    toggle: () => void;
}

const ShowTokenModal: FC<ShowTokenModalProps> = ({ toggle, token, isOpen }) => {

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>
                Новый токен {token?.name}
            </ModalHeader>
            <ModalBody>
                Токен: <b>{token?.fullToken}</b>
                <br />
                Сохраните токен, повторно посмотреть токен нельзя.
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" type="button" onClick={toggle}>
                    Закрыть
                </Button>{" "}
            </ModalFooter>
        </Modal>
    )
}

export default ShowTokenModal;