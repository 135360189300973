import {FC} from "react";
import {useCurrentProject} from "@selectors/consumer/projects.selectors";
import {LoginServerListTable} from "@components/consumer/projects";
import {withPage} from "@components/panel";

const LoginServersListPage: FC = () => {
    const project = useCurrentProject();

    return (
        <LoginServerListTable project={project} />
    )
}

export default withPage(LoginServersListPage);