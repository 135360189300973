import React, { useCallback, useMemo } from 'react';
import { FormikHelpers, Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Project } from '../../../../interfaces/consumer/projects';
import { PutLoginServerModel } from '../../../../models/consumer/projects';
import { projectActions } from '../../../../redux/consumer/projectsStore';
import { toPromise } from '../../../../utils/helpers';
import * as Yup from 'yup';
import { FormikInput } from '../../../panel/formik';

interface AddLoginServerModalProps {
    project: Project;
    isOpen: boolean;
    toggle(): void;
}

interface AddLoginServerModalForm {
    name: string;
    maxAccounts: number;
}

const AddLoginServerModal: React.FC<AddLoginServerModalProps> = ({ project, isOpen, toggle }) => {

    const dispatch = useDispatch();
    //const { data: { providers } } = useProjects();

    const onSubmit = useCallback(async (values: AddLoginServerModalForm, actions: FormikHelpers<AddLoginServerModalForm>) => {

        var login: PutLoginServerModel = {
            name: values.name,
            projectId: project.id,
            maxAccounts: values.maxAccounts,
        }

        const result = await toPromise(dispatch(projectActions.addLoginServer(login)));

        if (result.ok) {

            const options: BasicToastrOptions = {
                timeOut: 10000,
                showCloseButton: true,
                progressBar: true,

            }
            toastr.success("Создание сервера авторизации", "Сервер успешно создан", options);

            toggle();
        }
        else {
            actions.setFieldError('name', 'Ошибка запроса. Обновите страницу');
        }
    }, [dispatch, project.id, toggle]);

    const validation = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .min(6)
            .max(64)
            .required(),
        maxAccounts: Yup.number()
            .min(0)
            .max(64)
            .required()
    }), []);

    const initial: AddLoginServerModalForm = {
        name: '',
        maxAccounts: 5
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Создание нового сервера авторизации
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <ModalBody>
                            <Field
                                label="Название сервера"
                                type="text"
                                name="name"
                                placeholder="Введите название сервера"
                                component={FormikInput} />
                            <Field
                                label="Максимальное количество аккаунтов"
                                type="number"
                                name="maxAccounts"
                                placeholder="Введите максимальное количество аккаунтов на этом сервере"
                                component={FormikInput} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" onClick={toggle}>
                                Закрыть
                            </Button>{" "}
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Создать сервер
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )

}

export default AddLoginServerModal;