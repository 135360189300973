import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {KeyValueSet, Loadable} from "@interfaces/helpers";
import {IProjectPermission} from "@interfaces/consumer/permissions";
import PermissionsService from "@api/consumer/permissionsService";
import {FetchResult} from "../../helpers";

const slice = createSlice({
    name: 'permissions',
    initialState: {} as KeyValueSet<Loadable<IProjectPermission[]>>,
    reducers: {
        setLoading: (state, {payload: projectId}:PayloadAction<string>) => {
            state[projectId] = {
                ...state[projectId],
                loading: true,
                loaded: false,
                error: null
            }
        },
        setLoaded: (state, {payload: {projectId, data}}:PayloadAction<{projectId: string, data: IProjectPermission[]}>) => {
            state[projectId] = {
                ...state[projectId],
                loading: false,
                loaded: true,
                error: null,
                data
            }
        },
        setError: (state, {payload: {projectId, error}}:PayloadAction<{projectId: string, error: any}>) => {
            state[projectId] = {
                loading: false,
                loaded: false,
                error: error,
                data: []
            }
        },
        setPermissions: (state, {payload: {projectId, permissions}}:PayloadAction<{projectId: string, permissions: IProjectPermission[]}>) => {
            state[projectId] = {
                ...state[projectId],
                data: permissions
            }
        }
    }
});

export const { reducer: permissionsReducer } = slice;

export const {
    setLoading,
    setLoaded,
    setError,
    setPermissions
} = slice.actions;

export const permissionsActions = {
    loadPermissions: (projectId: string) => async (dispatch: any) => {
        dispatch(setLoading(projectId));
        try {
            const api = new PermissionsService();
            const result = await api.getProjectPermissions(projectId);
            if(result.success) {
                dispatch(setLoaded({projectId, data: result.data}));
            }
            return result;
        } catch (e) {
            dispatch(setError({projectId, error: e}));
            return e as FetchResult<IProjectPermission[]>;
        }
    }
}