import { FieldProps } from 'formik';
import { memo } from 'react';
import { FormGroup, FormFeedback, Input, FormText, Label } from 'reactstrap';

interface Props {
    description?: string;
    label: string;
    showValid?: boolean;
    id?: string;
    placeholder?: string;
}

const FormikCheckbox: React.FC<Props & FieldProps> = (prop: any) => {
    let {
        field: { ...fields },
        form: { touched, errors },
        description,
        id,
        label,
        placeholder,
        ...props
    } = prop;

    if (id === undefined) {
        id = fields.name;
    }

    return (
        <FormGroup check>
            {label && <Label check>{label}</Label>}
            <Input checked={fields.value} id={id} {...props} {...fields} invalid={Boolean(touched[fields.name] && errors[fields.name])} />
            {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
            {description ? <FormText>{description}</FormText> : ''}
            
        </FormGroup>
    );
}
export default memo(FormikCheckbox);