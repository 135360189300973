import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "@store";
import { panelActions } from "@store/panel/panelStore";

export function useRecaptchaPublic() {
    return useSelector(({ panel }: IStore) => panel.settings.recaptchaPublic);
}

export function useBreadcrumbs(name: string, clear: boolean, text?: string, link?: string) {

    const dispatch = useDispatch();

    useEffect(() => {
        if (text) {
            dispatch(panelActions.setBreadcrumb(name, {
                name: text,
                link
            }));
        }

        if (clear) {
            return () => {
                dispatch(panelActions.unsetBreadcrumb(name));
            }
        }
    }, [clear, dispatch, link, name, text]);
}