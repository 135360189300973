import {FC} from "react";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import CurrencyForm, {CurrencyFormValues} from "@components/consumer/payments/currencies/currencyForm";
import {toastr} from "react-redux-toastr";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {currenciesActions} from "@store/consumer/currenciesStore";
import {ExchangeRateSource, ICurrency} from "@interfaces/consumer/payments";

interface EditCurrencyFormProps {
    currency: ICurrency
}

const EditCurrencyForm: FC<EditCurrencyFormProps> = ({currency}) => {

    const dispatch = usePromisedDispatch();
    const projectId = useCurrentProjectId();
    const onSubmit = async (values: CurrencyFormValues) => {
        const result = await dispatch(currenciesActions.updateCurrency(projectId, {
            id: currency.id,
            isEnabled: values.isEnabled,
            isStatic: values.isStatic,
            source: !values.isStatic ? values.source : undefined,
            value: values.isStatic ? values.value : undefined,
            donateMin: values.donateMin,
            donateMax: values.donateMax
        }));

        if(result.success) {
            toastr.success("Редактирование валют", "Валюта добавлена");
        }
        else {
            if(result.errorCode === 'CurrencyNotFound') {
                toastr.error("Настройки валют", "Валюта не найдена");
            }
            else if(result.errorCode === 'ProjectNotFound') {
                toastr.error("Настройки валют", "Проект не найден");
            }
            else if(result.errorCode === 'ValueRequired') {
                toastr.error("Настройки валют", "Не указано значение");
            }
            else if(result.errorCode === 'SourceRequired') {
                toastr.error("Настройки валют", "Не указан источник");
            }
            else {
                toastr.error("Настройки валют", "Неизвестная ошибка");
            }
        }
    }

    const initialValues: CurrencyFormValues = {
        currency: currency.currency,
        source: currency.source || ExchangeRateSource.CBR,
        value: currency.value || 100,
        isStatic: currency.isStatic,
        isEnabled: currency.isEnabled,
        donateMin: currency.donateMin,
        donateMax: currency.donateMax
    }

    return (
        <CurrencyForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            editForm />
    )
}

export default EditCurrencyForm;