import MasterProxyService from "@api/master/masterProxyService";
import { Loadable } from "@interfaces/helpers";
import { IMasterProxy } from "@interfaces/master/proxies";
import { AddProxyModel, PutProxyModel } from "@models/master/proxies";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export type IMasterProxyStore = Loadable<IMasterProxy[]>;

const slice = createSlice({
    name: 'masterProxyStore',
    initialState: {
        error: null,
        loaded: false,
        loading: false,
        data: []
    } as IMasterProxyStore,
    reducers: {
        setProxies: (state, action: PayloadAction<IMasterProxy[]>) => {
            state.data = action.payload;
            state.loaded = true;
            state.loading = false;
            state.error = null;
        },
        setLoading: (state) => {
            state.error = null;
            state.loading = true;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.loaded = false;
            state.data = [];
        },
        setProxy: (state, action: PayloadAction<IMasterProxy>) => {
            const idx = state.data.findIndex(s => s.id === action.payload.id);
            if(idx >= 0) {
                state.data[idx] = action.payload;
            }
            else {
                state.data.push(action.payload);
            }
        }
    }
});

export const { reducer: masterProxyReducer } = slice;

const { setError, setLoading, setProxies, setProxy } = slice.actions;

export const masterProxyActions = {
    loadProxies: () => async (dispatch: Dispatch) => {
        const api = new MasterProxyService();
        dispatch(setLoading());

        const result = await api.getProxies();
        if(result.success) {
            dispatch(setProxies(result.data));
        }
        else {
            dispatch(setError(result));
        }
    },
    createProxy: (model: PutProxyModel) => async (dispatch: Dispatch) => {
        const api = new MasterProxyService();
        const result = await api.putProxy(model);

        if(result.success) {
            dispatch(setProxy(result.data));
        }

        return result;
    },
    addProxy: (model: AddProxyModel) => async (dispatch: Dispatch) => {
        const api = new MasterProxyService();
        const result = await api.addProxy(model);

        if(result.success) {
            dispatch(setProxy(result.data));
        }

        return result;
    }
}