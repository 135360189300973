import React, { useMemo } from 'react';
import { consumerRoutes, guestRoutes, masterRoutes, sharedRoutes } from '.';
import { PageRouting, RouterItem, RouterItemInfo } from '../interfaces/panel/routes';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useAuth } from '../utils/hooks/auth.hooks';
import { DashboardLayout, GuestLayout } from '../components/layouts';
import { useUserRoles } from '../utils/selectors/user.selectors';

function makeRoutes(routes: RouterItem, roles: string[], isAuthed: boolean, items: RouterItemInfo[] = []): React.ReactNode[] {

    const { path, children = null, exact } = routes;
    const elems: React.ReactNode[] = [];
    const Component = routes.component;

    const route_info: PageRouting = {
        code: routes.code,
        name: routes.name,
        title: routes.title,
        items
    };

    if ((routes.needAuth === undefined || routes.needAuth === isAuthed) &&
        (routes.authRole === undefined || roles.includes(routes.authRole))) {

        if(children) {
            const infoArray = items.concat({ name: routes.name, link: routes.path, useLink: Component !== undefined, code: routes.code });
            children.forEach(route => {
                elems.push(...makeRoutes(route, roles, isAuthed, infoArray));
            });
        }
        

        const child = Component && <Component route_info={route_info}/>;

        if (child) {
            elems.push(
                <Route key={path} path={path} exact={exact === undefined || exact}>
                    {child}
                </Route>
            )
        }

    }

    return elems;
}

const Routes: React.FC = () => {

    const isAuthed = useAuth();
    const roles = useUserRoles();

    const guest = useMemo(() => {
        return makeRoutes(guestRoutes, roles, isAuthed);
    }, [isAuthed, roles]);

    const shared = useMemo(() => {
        return makeRoutes(sharedRoutes, roles, isAuthed);
    }, [isAuthed, roles]);

    const master = useMemo(() => makeRoutes(masterRoutes, roles, isAuthed), [isAuthed, roles]);

    const consumer = useMemo(() => makeRoutes(consumerRoutes, roles, isAuthed), [isAuthed, roles]);

    const authedRedirect = useMemo(() => [
        <Redirect key='fsinrd' from='/signin/' to='/' exact/>,
        <Redirect key='fsuprd' from='/signup/' to='/' exact/>,
        <Redirect key='ffgtrd' from='/forgot/' to='/' exact/>
    ], []);

    return (
        <Router>
            {isAuthed ? (
                <DashboardLayout>
                    <Switch>
                        {authedRedirect}
                        {master}
                        {consumer}
                        {shared}
                    </Switch>
                </DashboardLayout>
            ) : (
                <GuestLayout>
                    <Switch>
                        {guest}
                        {shared}
                        <Redirect key='tsinrd' to='/signin/'/>
                    </Switch>
                </GuestLayout>
            )}
        </Router>
    )
}

export default Routes;