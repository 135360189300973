import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { withPage } from '../../panel';
import { Navigation, UserInfo, ChangePassword, UserAuthLogs } from '../../user/settings';

const Settings: React.FC = () => {

    const [selected, setSelected] = useState(0);

    return (
        <Row>
            <Col md="3" xl="2">
                <Navigation selected={selected} onSelect={setSelected} />
            </Col>
            <Col md="8" xl="9">
                {selected === 0 && <UserInfo />}
                {selected === 1 && <ChangePassword />}
                {selected === 2 && <UserAuthLogs />}
            </Col>
        </Row>
    )
}

export default withPage(Settings);