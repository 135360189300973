import { combineReducers } from "redux";
import { projectsReducer, ProjectsStore } from "./projectsStore";
import { storagesReducer, StoragesStore } from "./storagesStore";
import {KeyValueSet, Loadable} from "@interfaces/helpers";
import {ICurrency} from "@interfaces/consumer/payments";
import {currenciesReducer} from "@store/consumer/currenciesStore";
import {IProjectPermission} from "@interfaces/consumer/permissions";
import {permissionsReducer} from "@store/consumer/permissionsStore";

export interface ConsumerStore {
    projects: ProjectsStore;
    storages: StoragesStore;
    currencies: KeyValueSet<Loadable<ICurrency[]>>
    permissions: KeyValueSet<Loadable<IProjectPermission[]>>
}

export const consumerReducer = combineReducers<ConsumerStore>({
    projects: projectsReducer,
    storages: storagesReducer,
    currencies: currenciesReducer,
    permissions: permissionsReducer
});