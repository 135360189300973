import React, {FC, useCallback} from "react";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {useParams} from "react-router-dom";
import {usePayPalych} from "@selectors/consumer/payments.selectors";
import {IPayPalychSettings} from "@interfaces/consumer/payments";
import {paymentActions} from "@store/consumer/projectsStore";
import {CardBody} from "reactstrap";
import SettingsForm from "@components/consumer/payments/settings/settingsForm";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";

const PayPalychSettingsForm: FC = () => {
    const dispatch = usePromisedDispatch();
    const {settings_id} = useParams<{settings_id: string}>();
    const settings = usePayPalych(settings_id);

    const updatePayPalych = useCallback(async (settings: IPayPalychSettings) =>
        await dispatch(paymentActions.updatePayPalych(settings)), [dispatch]);

    if(!settings) {
        return (
            <CardBody>Настройки не найдены</CardBody>
        )
    }

    return (
        <SettingsForm settings={settings} updateSettings={updatePayPalych}>
            <Field
                label="ID проекта в PayPalych"
                type="text"
                name="merchantId"
                placeholder="123456"
                disabled
                component={FormikRowInput} />
            <Field
                label="Токен"
                type="text"
                name="apiKey"
                placeholder="72|oBCB7Z3SmU..."
                component={FormikRowInput} />
            <Field
                label="Название ссылки"
                type="text"
                name="linkName"
                placeholder="Донат"
                component={FormikRowInput} />
        </SettingsForm>
    )
}

export default PayPalychSettingsForm;