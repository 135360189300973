import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Collapse } from 'reactstrap';
import { SidebarCategoryLink } from '../../../interfaces/panel/sidebar';
import SidebarItem from './sidebarItem';

interface SidebarCategoryProps {
    category: SidebarCategoryLink;
}

const SidebarCategory: React.FC<SidebarCategoryProps> = ({category}) => {

    const location = useLocation();

    const {
        name,
        badgeColor,
        badgeText,
        icon,
        isOpen: defaultOpened,
        children,
        path
    } = category;

    const [isOpen, setOpened] = useState(defaultOpened);
    const toggleOpen = useCallback(() => setOpened(!isOpen), [isOpen]);

    const getSidebarItemClass = useCallback((path: string) => {
        return location.pathname.indexOf(path) !== -1 ||
            (location.pathname === "/" && path === "/dashboard")
            ? "active"
            : "";
    }, [location.pathname]);

    return (
        <li className={"sidebar-item " + getSidebarItemClass(path)}>
            <span
                data-toggle="collapse"
                className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
                onClick={toggleOpen}
                aria-expanded={isOpen ? "true" : "false"}
            >
                {icon &&
                    <FontAwesomeIcon
                        icon={icon}
                        fixedWidth
                        className="align-middle mr-2"
                    />}{" "}
                <span className="align-middle">{name}</span>
                {badgeColor && badgeText ? (
                    <Badge color={badgeColor} size={18} pill className="sidebar-badge">
                        {badgeText}
                    </Badge>
                ) : null}
            </span>
            <Collapse isOpen={isOpen}>
                <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                    {
                        children?.map(item => (
                            <SidebarItem key={item.path} item={item} />
                        ))
                    }
                </ul>
            </Collapse>
        </li>
    );
};

export default SidebarCategory;