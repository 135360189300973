import React, { useCallback, useMemo } from 'react';
import { FormikHelpers, Formik, Form, Field } from 'formik';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { SmtpSettingsCheck } from '../../../../interfaces/consumer/projects';
import ProjectsService from '../../../../services/consumer/projectsService';
import * as Yup from 'yup';
import { FormikInput } from '../../../panel/formik';

interface SmtpSettingsCheckModalProps {
    isOpen: boolean;
    toggle: () => void;
    smtpId: string;
}

interface SmtpSettingsCheckModalForm {
    email: string;
}

const SmtpSettingsCheckModal: React.FC<SmtpSettingsCheckModalProps> = ({ isOpen, toggle, smtpId }) => {

    const onSubmit = useCallback(async (values: SmtpSettingsCheckModalForm, actions: FormikHelpers<SmtpSettingsCheckModalForm>) => {
        const check: SmtpSettingsCheck = {
            id: smtpId,
            email: values.email
        }
        const api = new ProjectsService();
        const result = await api.testConsumerSmtp(check);

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true
        }

        if (result.ok) {
            toastr.success("Проверка smtp", "Письмо успешно отправлено!", options);
            toggle();
        }
        else {
            toastr.success("Проверка smtp", "Не удалось отправить письмо. Проверьте настройки.", options);
        }
    }, [smtpId, toggle]);

    const validation = useMemo(() => Yup.object().shape({
        email: Yup.string().email().required()
    }), []);

    const initial: SmtpSettingsCheckModalForm = {
        email: ''
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Проверка настроек SMTP
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <ModalBody>
                            <Field
                                label="Ваш email"
                                type="email"
                                name="email"
                                placeholder="Введите ваш email дя проверки"
                                component={FormikInput} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" onClick={toggle}>
                                Закрыть
                                </Button>{" "}
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Отправить письмо
                                </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default React.memo(SmtpSettingsCheckModal);