import { Formik, Form, Field } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Spinner, CardHeader, CardTitle, Button } from 'reactstrap';
import { useMasterStorages } from '../../../utils/selectors/master.hooks';
import { useBreadcrumbs } from '../../../utils/hooks/panel.hooks';
import * as Yup from 'yup';
import { masterStoragesActions } from '../../../redux/master/storagesStore';
import { usePromisedDispatch } from '../../../utils/helpers';
import { FormikCheckbox, FormikInput, FormikRowCheckbox, FormikRowInput } from '../../panel/formik';
import { withPage } from '../../panel';

interface EditStorageForm {
    name: string;
    endpoint: string;
    accessKey: string;
    secretKey: string;
    region: string;
    size: number;
    isActive: boolean;
    useProxy: boolean;
    useSSL: boolean;
    ownerId: string;
}

const EditStorage: React.FC = () => {

    const { storage_id } = useParams<{ storage_id: string }>();
    const { loading, data: storages } = useMasterStorages();
    const dispatch = usePromisedDispatch();
    const storage = storages.find(s => s.id === storage_id);
    useBreadcrumbs('admin.master.storage.name', true, storage?.name);

    const validation = useMemo(() => Yup.object().shape<EditStorageForm>({
        name: Yup.string().required(),
        endpoint: Yup.string().required(),
        accessKey: Yup.string().required(),
        secretKey: Yup.string(),
        region: Yup.string(),
        size: Yup.number().required(),
        isActive: Yup.boolean(),
        useProxy: Yup.boolean().required(),
        useSSL: Yup.boolean().required(),
        ownerId: Yup.string()
    }), []);

    const onSubmit = useCallback(async (values: EditStorageForm) => {

        if (!storage) {
            return;
        }

        const data = {
            ...values,
            id: storage.id
        };

        const result = await dispatch(masterStoragesActions.updateStorage(data));

        if (result.ok) {
            toastr.success("Хранилище", "Хранилище успешно изменено");
        }
        else {
            if (result.status === 404) {
                toastr.error("Хранилище", "Хранилище не найдено. Обновите страницу и попробуйте еще раз.");
            }
            else {
                toastr.error("Хранилище", "Неизвестная ошибка. Обновите страницу и попробуйте еще раз.");
            }
        }
    }, [dispatch, storage]);

    if (!storage) {
        return (
            <Card>
                <CardBody className="text-center">
                    {loading ? <Spinner className="mr-2" color="primary" /> : (
                        "Хранилище не найдено"
                    )}
                </CardBody>
            </Card>
        )
    }

    //console.log(storage);
    const initial: EditStorageForm = {
        ...storage,
        secretKey: '',
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Редактирование хранилища {storage.name}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initial}
                    validationSchema={validation}
                    onSubmit={onSubmit}>
                    {
                        ({ isSubmitting, isValid }) => (
                            <Form>
                                <Field
                                    label="Название хранилища"
                                    type="text"
                                    name="name"
                                    placeholder="Введите название хранилища"
                                    component={FormikRowInput} />
                                <Field
                                    label="Адрес"
                                    type="text"
                                    name="endpoint"
                                    placeholder="Введите адрес хранилища"
                                    component={FormikRowInput} />
                                <Field
                                    label="Ключ доступа"
                                    type="text"
                                    name="accessKey"
                                    placeholder="Введите ключ доступа хранилища"
                                    component={FormikRowInput} />
                                <Field
                                    label="Секретный ключ"
                                    type="password"
                                    name="secretKey"
                                    placeholder="Введите секретный ключ хранилища"
                                    description="Оставьте поле пустым если не хотите менять ключ"
                                    component={FormikRowInput} />
                                <Field
                                    label="Активно"
                                    name="isActive"
                                    type="switch"
                                    component={FormikRowCheckbox} />
                                <Field
                                    label="Регион"
                                    type="text"
                                    name="region"
                                    placeholder="Введите регион хранилища"
                                    component={FormikRowInput} />
                                <Field
                                    label="Размер хранилища (mb)"
                                    type="text"
                                    name="size"
                                    placeholder="Введите размер хранилища"
                                    component={FormikRowInput} />
                                <Field
                                    label="Использовать прокси"
                                    name="useProxy"
                                    type="switch"
                                    description="Публичные сервера выделяются проектам без купленного прокси"
                                    component={FormikCheckbox} />
                                <Field
                                    label="Использовать SSL"
                                    name="useSSL"
                                    type="switch"
                                    description="Подключаться к хранилищу через SSL"
                                    component={FormikCheckbox} />
                                <Field
                                    label="Владелец"
                                    type="text"
                                    name="ownerId"
                                    placeholder="ID владельца хранилища"
                                    disabled
                                    component={FormikInput} />
                                <div className="text-center mt-3">
                                    <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
                                        {isSubmitting && <Spinner size="sm" color="red" />}
                                        Сохранить
                                    </Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </CardBody>
        </Card>
    )
}

export default withPage(EditStorage);