import { FormikHelpers, Formik, Form, Field, ErrorMessage } from 'formik';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Col, FormText, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormFeedback } from 'reactstrap';
import { IBucket } from '@interfaces/consumer/projects';
import {useProjectBucket, useProjects} from '@selectors/consumer/projects.selectors';
import { FormikCheckbox, FormikInput } from '@components/panel/formik';
import { useStorages } from '@selectors/consumer/storages.selectors';
import { usePromisedDispatch } from '../../../../utils/helpers';
import { projectActions } from '@store/consumer/projectsStore';
import * as Yup from 'yup';
import { storagesActions } from '@store/consumer/storagesStore';

interface BucketInfoTabProps {
    projectId: string;
}

const BucketInfoTab: React.FC<BucketInfoTabProps> = ({ projectId }) => {

    const { data: { buckets } } = useProjects();
    const [opened, setOpened] = useState(false);
    const bucket = useProjectBucket(projectId);
    const toggle = useCallback(() => setOpened(!opened), [opened]);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Хранилище
                </CardTitle>
            </CardHeader>
            <CardBody>
                {
                    bucket ? <BucketInfo bucket={bucket} /> :
                        <>
                            <NoBucket toggle={toggle} />
                            <CreateBucketModal toggle={toggle} isOpen={opened} projectId={projectId} />
                        </>
                }
            </CardBody>
        </Card>
    )
}

interface BucketInfoProps {
    bucket: IBucket;
}

const BucketInfo: React.FC<BucketInfoProps> = React.memo(({ bucket }) => {
    return (
        <React.Fragment>
            <FormGroup row>
                <Label className="label-color" sm={3}>
                    Имя
                </Label>
                <Col sm={9}>
                    <Input value={bucket.name} type="text" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="label-color" sm={3}>
                    Адрес
                </Label>
                <Col sm={9}>
                    <Input value={bucket.shortName} type="text" disabled />
                    <FormText>Это ваши пользователи в адресной строке</FormText>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="label-color" sm={3}>
                    Хранилище
                </Label>
                <Col sm={9}>
                    <Input value={bucket.name} type="text" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="label-color" sm={3}>
                    Размер
                </Label>
                <Col sm={9}>
                    {bucket.size} GB
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="label-color" sm={3}>
                    Url бакета
                </Label>
                <Col sm={9}>
                    {bucket.bucketUrl}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="label-color" sm={3}>
                    Использовать временные ссылки
                </Label>
                <Col sm={9}>
                    {bucket.usePresignedUrls ? 'Да' : 'Нет'}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="label-color" sm={3}>
                    Время жизни ссылок
                </Label>
                <Col sm={9}>
                    {bucket.urlLifetime} сек.
                </Col>
            </FormGroup>
        </React.Fragment>
    )
})

interface NoBucketProps {
    toggle: () => void;
}

const NoBucket: React.FC<NoBucketProps> = React.memo(({ toggle }) => {
    return (
        <CardBody className="text-center">
            Бакета еще нет. Создайте его, выбрав подходящее имя.
            <div className="text-center mt-3">
                <Button type="button" color="primary" onClick={toggle}>
                    Создать бакет
                </Button>
            </div>
        </CardBody>
    )
})

interface CreateBucketModalProps {
    isOpen: boolean;
    toggle: () => void;
    projectId: string;
}

interface CreateBucketModalForm {
    name: string;
    shortName: string;
    storageId: string;
    size: number;
    usePresignedUrls: boolean;
    urlLifetime: number;
    bucketUrl: string
}

const CreateBucketModal: React.FC<CreateBucketModalProps> = ({ toggle, isOpen, projectId }) => {

    const dispatch = usePromisedDispatch();
    const storages = useStorages();

    const initial: CreateBucketModalForm = {
        name: '',
        shortName: '',
        storageId: '',
        size: 10,
        bucketUrl: '',
        urlLifetime: 3600,
        usePresignedUrls: false
    }

    const validation = useMemo(() => Yup.object().shape<CreateBucketModalForm>({
        name: Yup.string()
            .min(3)
            .max(100)
            .required(),
        shortName: Yup.string()
            .min(3)
            .max(32)
            .matches(/[A-Za-z0-9]/)
            .required(),
        storageId: Yup.string()
            .required(),
        size: Yup.number()
            .min(1)
            .required(),
        usePresignedUrls: Yup.boolean(),
        bucketUrl: Yup.string()
            .url()
            .required(),
        urlLifetime: Yup.number()
    }), []);

    const onSubmit = useCallback(async (values: CreateBucketModalForm, actions: FormikHelpers<CreateBucketModalForm>) => {

        const result = await dispatch(projectActions.addBucket({
            ...values,
            projectId
        }));

        if (result.ok) {
            toastr.success("Хранилище", "Хранилище успешно создано.");
            toggle();
        }
        else {
            if (result.errorCode === 'InvalidShortname') {
                actions.setFieldError("shortName", "Некорректное имя");
            }
            else if (result.errorCode === 'BucketNameAlreadyExists') {
                actions.setFieldError("shortName", "Это имя уже занято");
            }
            else if (result.errorCode === 'ProjectNotFound') {
                toastr.error("Хранилище", "Проект не найден. Обновите страницу и попробуйте еще раз");
            }
            else if (result.errorCode === 'BucketAlreadyExists') {
                toastr.error("Хранилище", "У этого проекта уже имеется хранилище. Обновите страницу.");
            }
            else if (result.errorCode === 'StorageNotFound') {
                toastr.error("Хранилище", "Хранилище не найдено");
                dispatch(storagesActions.fetchStorages());
            }
            else if (result.errorCode === 'InvalidName') {
                actions.setFieldError("shortName", "Не удалось создать хранилище с таким именем");
            }
            else if (result.errorCode === 'ConnectionError') {
                actions.setFieldError("shortName", "Не удалось подключиться к хранилищу. Проверьте настройки и попробуйте еще раз.");
            }
            else if (result.errorCode === 'AccessDenied') {
                actions.setFieldError("shortName", "Доступ к хранилищу запрещен. Проверьте ключи доступа и попробуйте еще раз.");
            }
            else if (result.errorCode === 'InternalError') {
                toastr.error("Хранилище", "Ошибка создания. Обратитесь к администратору.");
            }
            else {
                toastr.error("Хранилище", "Что-то пошло не так.");
            }
        }
    }, [dispatch, projectId, toggle]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Создание бакета
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={onSubmit}>
                {
                    ({ isSubmitting, touched, errors, values }) => (
                        <Form>
                            <ModalBody>
                                <Field
                                    label="Название бакета"
                                    type="text"
                                    name="name"
                                    placeholder="Введите название бакета"
                                    component={FormikInput} />
                                <FormGroup>
                                    <Label for="storageId" className={"label-color"}>Хранилише</Label>
                                    <Field
                                        type="select"
                                        name="storageId"
                                        id="storageId"
                                        as={Input}
                                        invalid={Boolean(touched.storageId && errors.storageId)}>
                                        <option value=''>Выберите хранилище</option>
                                        {
                                            storages.data.map(storage => (
                                                <option key={storage.id} value={storage.id}>{storage.name}</option>
                                            ))
                                        }
                                    </Field>
                                    <ErrorMessage name="storageId" component={FormFeedback} />
                                </FormGroup>
                                <Field
                                    label="Адрес"
                                    type="text"
                                    name="shortName"
                                    placeholder="Введите адрес бакета"
                                    description="Это увидят пользователи в адресной строке"
                                    component={FormikInput} />
                                <Field
                                    label="Размер"
                                    type="number"
                                    name="size"
                                    placeholder="Введите размер бакета"
                                    min={1}
                                    description='В гигабайтах'
                                    component={FormikInput} />
                                <Field
                                    label="Url"
                                    type="text"
                                    name="bucketUrl"
                                    placeholder="Введите url бакета"
                                    description='Этот url будет использоваться в качестве префикса url скачиваемых файлов'
                                    component={FormikInput} />
                                <Field
                                    label="Использовать временные ссылки"
                                    name="usePresignedUrls"
                                    type="switch"
                                    description="Для каждого файла будет создаваться ссылка с ограниченным временем жизни"
                                    component={FormikCheckbox} />
                                {
                                    values.usePresignedUrls && (
                                        <Field
                                            label="Время жизни ссылки"
                                            type="number"
                                            name="urlLifetime"
                                            placeholder="Введите размер бакета"
                                            min={1}
                                            max={604800}
                                            description='В сек. От 1 до 604800 (7 дней)'
                                            component={FormikInput} />
                                    )
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" type="button" onClick={toggle}>
                                    Закрыть
                                </Button>{" "}
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    Создать
                                </Button>
                            </ModalFooter>
                        </Form>
                    )
                }
            </Formik>
        </Modal>
    )
}

export default memo(BucketInfoTab);