import { UserAuthLog, UserInfo, UserNotification } from "../../interfaces/user/user";
import { ChangePasswordModel, PatchUserDataModel } from "../../models/user/data";
import ServiceBase from "../serviceBase";

export default class UserService extends ServiceBase {
    public async fetchUserInfo() {
        return await this.getAndThrowApi<UserInfo>('/user/');
    }

    public async fetchNotifications() {
        return await this.getAndThrowApi<UserNotification[]>('/user/notification/');
    }

    public async readNotification(id: string) {
        return await this.patchApi(`/user/notification/${id}`);
    }

    async patchUserData(user: PatchUserDataModel) {
        return await this.patchApi('/user/', user);
    }

    async fetchAuthLogs() {
        return await this.getAndThrowApi<UserAuthLog[]>('/user/logs/auth/');
    }

    async changePassword(data: ChangePasswordModel) {
        return await this.patchApi('/user/password/', data);
    }
}