import {usePromisedDispatch} from "../../../../utils/helpers";
import {useParams} from "react-router-dom";
import {useAntilopay} from "@selectors/consumer/payments.selectors";
import React, {useCallback} from "react";
import {IAntilopaySettings} from "@interfaces/consumer/payments";
import {paymentActions} from "@store/consumer/projectsStore";
import {CardBody} from "reactstrap";
import SettingsForm from "@components/consumer/payments/settings/settingsForm";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";

const AntilopaySettingsForm = () => {
    const dispatch = usePromisedDispatch();
    const {settings_id} = useParams<{settings_id: string}>();
    const settings = useAntilopay(settings_id);

    const updateAntilopay = useCallback(async (settings: IAntilopaySettings) =>
        await dispatch(paymentActions.updateAntilopay(settings)), [dispatch]);

    if(!settings) {
        return (
            <CardBody>Настройки не найдены</CardBody>
        )
    }

    return (
        <SettingsForm settings={settings} updateSettings={updateAntilopay}>
            <Field
                label="Идентификатор мерчанта"
                type="text"
                name="merchantId"
                placeholder="s+k2vmp/CVR..."
                disabled
                component={FormikRowInput} />
            <Field
                label="Идентификатор проекта мерчанта"
                type="text"
                name="projectIdentificator"
                placeholder="PEFF..."
                component={FormikRowInput} />
            <Field
                label="Название товара/услуги"
                type="text"
                name="productName"
                placeholder="Пополнение баланса"
                component={FormikRowInput} />
            <Field
                label="Ключ проверки callback"
                type="textarea"
                name="publicKey"
                placeholder="MFwwDQYJK..."
                description="Оставьте поле пустым, если не хотите менять ключ"
                component={FormikRowInput} />
            <Field
                label="Ключ подписи запроса"
                type="textarea"
                name="secretKey"
                placeholder="MIIBVQIBADANB..."
                description="Оставьте поле пустым, если не хотите менять ключ"
                component={FormikRowInput} />
        </SettingsForm>

    )
}

export default AntilopaySettingsForm;