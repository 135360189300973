import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Container } from 'reactstrap';
import { PageRouting, RouterItemInfo } from '@interfaces/panel/routes';
import { useBreadcrumbsData } from '@selectors/panel.selectors';
import { useCurrentProjectId} from "@selectors/consumer/projects.selectors";

interface Props {
    route_info: PageRouting;
}

const PanelPage: React.FC<Props> = ({ route_info: { name, items, title, code }, children }) => {
    return (
        <Container fluid>
            <Helmet title={title} />
            <div className="header">
                <h1 className="header-title">{name}</h1>
                <Breadcrumbs items={items} currentName={name} codeName={code} />
            </div>

            {children}

        </Container>
    )
}

interface BreadcrumbsProps {
    items: RouterItemInfo[];
    currentName?: string;
    codeName?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items, currentName, codeName }) => {

    const infos = useBreadcrumbsData();
    const bname = (codeName && infos[codeName] && infos[codeName].name) || currentName;
    const projectId = useCurrentProjectId();

    return (
        <Breadcrumb>
            {
                items.map(({ name, code, useLink, link }, idx) => {

                    const bc = code && infos[code];
                    name = (bc && bc.name) || name;
                    link = (bc && bc.link) || link;
                    link = link && link.replace(':project_id', projectId);

                    return (
                        name &&
                        <BreadcrumbItem key={idx}>
                            {
                                useLink ?
                                    <Link to={link}>{name}</Link> :
                                    name
                            }
                        </BreadcrumbItem>
                    )
                })
            }
            <BreadcrumbItem active>{bname}</BreadcrumbItem>
        </Breadcrumb>
    )
}

export const withPage = <T extends {}>(View: React.ComponentType<T>) => {

    const p: React.FC<Props & T> = (props) => {

        const { route_info, children, ...nextProps } = props;

        return (
            <PanelPage route_info={route_info}>
                 <View {...props} />
            </PanelPage>
        )
    }

    return p;
}

export default PanelPage;