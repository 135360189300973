import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { withPage } from '../panel';

const EmptyPage: React.FC = () => {
    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h2">Заголовок</CardTitle>
            </CardHeader>
            <CardBody>
                Пустой текст
            </CardBody>
        </Card>
    )
}

export default withPage(EmptyPage);