import { IPutPaymentSettingsModel } from "@models/consumer/payments";
import { projectActions } from "@store/consumer/projectsStore";
import {Field, Form, Formik} from "formik"
import React, { PropsWithChildren, useCallback } from "react"
import { toastr } from "react-redux-toastr";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import * as Yup from 'yup';
import { FetchResult } from "../../../../helpers";
import { usePromisedDispatch } from "../../../../utils/helpers";
import {FormikRowInput} from "@components/panel/formik";

interface AddSettingsModalProps<T extends IPutPaymentSettingsModel> {
    projectId: string;
    nextOrder: number;
    isOpen: boolean;
    toggle(): void;
    addInitial?: Partial<T>;
    createSettings: (settings: T) => Promise<FetchResult>
}

interface AddSettingsModalForm {
    merchantId: string
}

const AddSettingsModal = <T extends IPutPaymentSettingsModel>({ projectId, nextOrder, isOpen, toggle, addInitial, createSettings, children }: PropsWithChildren<AddSettingsModalProps<T>>) => {

    const dispatch = usePromisedDispatch();

    const initial: T = {
        ...addInitial
    } as T;

    const validation = Yup.object().shape<AddSettingsModalForm>({
        merchantId: Yup.string().required()
    });

    const onSubmit = useCallback(async (values: T) => {

        const result = await createSettings({
            ...values,
            projectId,
            order: nextOrder
        });

        if (result.success) {
            toastr.success("Настройки платежей", "Платежная система успешно добавлена");
            toggle();
        }
        else {
            if (result.errorCode === 'ProjectNotFound') {
                toastr.error("Настройки платежей", "Проект не найден");
                dispatch(projectActions.fetchAll(true));
                toggle();
            }
            else {
                toastr.error("Настройки платежей", "Что-то пошло не так.");
            }
        }

    }, [createSettings, dispatch, nextOrder, projectId, toggle]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered>
            <ModalHeader toggle={toggle}>
                Добавление новой платежной системы
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <ModalBody>
                            <Field
                                label="Название"
                                type="text"
                                name="name"
                                placeholder="Название платежки"
                                component={FormikRowInput} />
                            {children}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" onClick={toggle}>
                                Закрыть
                            </Button>{" "}
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Добавить
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default AddSettingsModal;