import React, {FC, useCallback} from "react";
import {useModal, usePromisedDispatch} from "../../../../utils/helpers";
import {useHistory} from "react-router-dom";
import {usePayPalyches} from "@selectors/consumer/payments.selectors";
import {paymentActions} from "@store/consumer/projectsStore";
import {PutPayPalychPaymentSettings} from "@models/consumer/payments";
import {GatewaysList} from "@components/consumer/payments";
import {AddSettingsModal} from "@components/consumer/projects/payments";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";

const PayPalychList: FC = () => {

    const dispatch = usePromisedDispatch();
    const project_id = useCurrentProjectId();
    const gateways = usePayPalyches(project_id);
    const {isOpened: isPPLOpened, toggle: togglePayPalych} = useModal();
    const history = useHistory();

    const onSelect = useCallback((gatewayId: string) => {
        history.push(`/p/${project_id}/ps/gw/ppl/${gatewayId}/`);
    }, [history, project_id])

    const onDelete = useCallback((gatewayId: string) => dispatch(paymentActions.deletePayPalych(gatewayId)),
        [dispatch]);

    const createPayPalych = useCallback(async (model: PutPayPalychPaymentSettings) => await dispatch(paymentActions.addPayPalych(model)),
        [dispatch]);
    const nextPayPalychOrder = gateways.reduce((order, settings) => Math.max(order, settings.order + 1), 0);

    return (
        <>
            <GatewaysList
                title="PayPalych"
                gateways={gateways}
                onSelect={onSelect}
                onDelete={onDelete}
                onAdd={togglePayPalych}
            />
            <AddSettingsModal
                isOpen={isPPLOpened}
                toggle={togglePayPalych}
                projectId={project_id}
                nextOrder={nextPayPalychOrder}
                createSettings={createPayPalych}>
                <Field
                    label="ID проекта в PayPalych"
                    type="text"
                    name="merchantId"
                    placeholder="123456"
                    component={FormikRowInput} />
                <Field
                    label="Токен"
                    type="text"
                    name="apiKey"
                    placeholder="72|oBCB7Z3SmU..."
                    component={FormikRowInput} />
                <Field
                    label="Название ссылки"
                    type="text"
                    name="linkName"
                    placeholder="Донат"
                    component={FormikRowInput} />
            </AddSettingsModal>
        </>
    )
}

export default PayPalychList;