import { Provider } from 'react-redux';
import { store } from './redux';
import ReduxToastr from 'react-redux-toastr';
import PanelLoader from './components/panel/panelLoader';
import Routes from './routes/routes';

function App() {
  return (
    <Provider store={store}>
      <PanelLoader>
        <Routes />
      </PanelLoader>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
}

export default App;
