import {FC} from "react";
import {Placeholder, Table} from "reactstrap";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {useProjectCurrencies} from "@selectors/consumer/currencies.selectors";
import {ExchangeRateSource} from "@interfaces/consumer/payments";
import {Link} from "react-router-dom";

function sourceToText(source: ExchangeRateSource) {
    switch (source) {
        case ExchangeRateSource.CBR:
            return 'ЦБ РФ';
        default:
            return 'Неизвестно';
    }
}

const CurrenciesList: FC = () => {

    const projectId = useCurrentProjectId();
    const {
        loading,
        data: currencies
    } = useProjectCurrencies(projectId);

    return (
        <Table>
            <thead>
                <tr>
                    <th>Валюта</th>
                    <th>Активно</th>
                    <th>Тип</th>
                    <th>Курс</th>
                </tr>
            </thead>
            <tbody>
            {
                loading ? (
                    <tr>
                        <PlaceholderRow />
                        <PlaceholderRow />
                        <PlaceholderRow />
                    </tr>
                ) : currencies.length === 0 ? (
                    <tr>
                        <td colSpan={4} align='center'>Нет данных</td>
                    </tr>
                ) : currencies.map(currency => (
                    <tr key={currency.id}>
                        <td><Link to={`/p/${projectId}/ps/cr/${currency.id}/`}>{currency.currency}</Link></td>
                        <td>{currency.isEnabled ? 'Да' : 'Нет'}</td>
                        <td>{currency.isStatic ? 'Фиксированный' : 'Динамический'}</td>
                        <td>{currency.value.toString()} {!currency.isStatic && ` (${sourceToText(currency.source!)})`}</td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    )
}

const PlaceholderRow: FC = () => {
    return (
        <td className='placeholder-wave'>
            <Placeholder animation='glow' className='col-6'/>
        </td>
    )
}

export default CurrenciesList;