import React, {FC, useCallback} from "react";
import SettingsForm from "@components/consumer/payments/settings/settingsForm";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";
import {IEnotPaymentSettings} from "@interfaces/consumer/payments";
import {paymentActions} from "@store/consumer/projectsStore";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {useParams} from "react-router-dom";
import {useEnot} from "@selectors/consumer/payments.selectors";
import {CardBody} from "reactstrap";

const EnotSettingsForm: FC = () => {

    const {settings_id} = useParams<{settings_id: string}>();
    const settings = useEnot(settings_id);
    const dispatch = usePromisedDispatch();
    const updateEnot = useCallback(async (settings: IEnotPaymentSettings) => await dispatch(paymentActions.updateEnot(settings)),
        [dispatch]);

    if(!settings) {
        return (
            <CardBody>Настройки не найдены</CardBody>
        )
    }

    return (
        <SettingsForm settings={settings} updateSettings={updateEnot}>
            <Field
                label="ID проекта в Enot.io"
                type="text"
                name="merchantId"
                placeholder="123456"
                disabled
                component={FormikRowInput} />
            <Field
                label="Публичный ключ"
                type="text"
                name="publicKey"
                placeholder="112233-a1b2c"
                component={FormikRowInput} />
            <Field
                label="Секретный ключ"
                type="text"
                name="secretKey"
                placeholder="123456"
                component={FormikRowInput} />
        </SettingsForm>
    )
}

export default EnotSettingsForm;