import ApiTokensService from "@api/consumer/apiTokensService";
import { AnimatedFontAwesomeIcon, TableSpinner } from "@components/panel";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IApiToken, IApiTokenFull } from "@interfaces/consumer/projects";
import { FC, useCallback, useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, Table } from "reactstrap";
import { useModal } from "../../../../utils/helpers";
import { CreateApiTokenModal, DeleteApiTokenModal, ShowTokenModal } from "../modals/tokens";

interface ApiTokensTabProps {
    projectId: string;
}

const ApiTokensTab: FC<ApiTokensTabProps> = ({ projectId }) => {

    const [loading, setLoading] = useState(true);
    const [tokens, setTokens] = useState<IApiToken[]>([]);
    const [deletingToken, setDeletingToken] = useState<IApiToken>();
    const [newToken, setNewToken] = useState<IApiTokenFull>();

    const {
        isOpened: isDeleteOpened,
        toggle: toggleDelete,
        setOpened: setDeleteOpened
    } = useModal();

    const {
        isOpened: isCreateOpened,
        toggle: toggleCreate
    } = useModal();

    const {
        isOpened: isNewOpened,
        setOpened: setNewOpened
    } = useModal();

    const closeNew = useCallback(() => {
        setNewOpened(false);
        setNewToken(undefined);
    }, [setNewOpened]);

    const setDeleteToken = (tokenId: string) => {
        const token = tokens.find(t => t.id === tokenId);
        if (token) {
            setDeletingToken(token);
            setDeleteOpened(true);
        }
    }

    const load = useCallback(async () => {
        setLoading(true);
        const api = new ApiTokensService();
        const result = await api.getTokens(projectId);
        if (result.success) {
            setTokens(result.data);
        }
        else {

        }
        setLoading(false);
    }, [projectId]);

    const showToken = useCallback((token: IApiTokenFull) => {
        setNewToken(token);
        setNewOpened(true);
    }, [setNewOpened]);

    useEffect(() => {
        load();
    }, [load]);

    return (
        <Card>
            <CardHeader>
                <div className="card-actions float-end">
                    <AnimatedFontAwesomeIcon
                        icon={faPlus}
                        fixedWidth
                        className="align-middle"
                        onClick={toggleCreate}
                        animate="spin"
                    />
                </div>
                <CardTitle tag="h5">
                    API токены
                </CardTitle>
            </CardHeader>
            <Table className="table table-hover">
                <thead>
                    <tr>
                        <th style={{ width: "30%" }}>Наименование</th>
                        <th style={{ width: "60%" }}>Токен</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <TableSpinner colSpan={3} />
                    ) : tokens.length > 0 ? tokens.map(token => (
                        <tr key={token.id}>
                            <td>{token.name}</td>
                            <td>{token.token}</td>
                            <td className="table-action">
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    fixedWidth
                                    className="align-middle"
                                    onClick={() => setDeleteToken(token.id)}
                                />
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan={3}>Нет ни одного токена</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <DeleteApiTokenModal
                isOpen={isDeleteOpened}
                toggle={toggleDelete}
                reload={load}
                token={deletingToken} />
            <CreateApiTokenModal 
                isOpen={isCreateOpened}
                toggle={toggleCreate}
                projectId={projectId}
                showToken={showToken}
                reload={load} />
            <ShowTokenModal
                isOpen={isNewOpened}
                toggle={closeNew}
                token={newToken} />
        </Card>
    )
}

export default ApiTokensTab;