import { PanelInfo } from "../interfaces/panelInfo";
import { PanelSettings } from "../models/panel/panelSettings";
import ServiceBase from "./serviceBase";

export default class PanelService extends ServiceBase {
    async fetchPanelSettings() {
        return await this.getAndThrowApi<PanelSettings>('/panel/settings/');
    }

    async fetchPanelInfo() {
        return await this.getAndThrowApi<PanelInfo>('/admin/info/');
    }
}