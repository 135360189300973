import { IMasterProxy } from "@interfaces/master/proxies";
import { masterProxyActions } from "@store/master/proxyStore";
import { useSelector } from "react-redux";
import { MasterStorage } from "../../interfaces/master/storages";
import { IStore } from "../../redux";
import { masterStoragesActions } from "../../redux/master/storagesStore";
import { useDataLoading } from "../hooks/loading.hooks";

export function useMasterStorages() {
    const data = useSelector(({ master }: IStore) => master.storages);
    return useDataLoading<MasterStorage[]>(masterStoragesActions.fetchStorages, data);
}

export function useMasterProxies() {
    const data = useSelector(({ master }: IStore) => master.proxies);
    return useDataLoading<IMasterProxy[]>(masterProxyActions.loadProxies, data);
}