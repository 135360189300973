import { combineReducers } from "redux";
import { IMasterProxyStore, masterProxyReducer } from "./proxyStore";
import { masterStoragesReducer, MasterStoragesStore } from "./storagesStore";

export interface MasterStore {
    storages: MasterStoragesStore;
    proxies: IMasterProxyStore;
}

export const masterReducer = combineReducers<MasterStore>({
    storages: masterStoragesReducer,
    proxies: masterProxyReducer
})