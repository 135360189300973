import { Project } from "@interfaces/consumer/projects";
import { useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { IStore } from "../../../redux";
import { projectActions } from "../../../redux/consumer/projectsStore";
import { useDataLoading } from "../../hooks/loading.hooks";
import {useVar} from "@selectors/panel.selectors";

export function useProjects() {
    const data = useSelector(({ consumer }: IStore) => consumer.projects, shallowEqual);
    return useDataLoading(projectActions.fetchAll, data);
}

export function useProject(id: string) {
    return useSelector(({ consumer }: IStore) => consumer.projects.data.projects.find(p => p.id === id));
}

export function useCurrentProjectId() {
    const { project_id } = useParams<{ project_id: string }>();
    const projectVar = useVar('project');
    return projectVar?.toString() || project_id || '';
}

export function useCurrentProject(): Project {
    return useProject(useCurrentProjectId()) as Project;
}

export function useBackendEndpoint(projectId: string, serverId: string) {
    return useSelector(({ consumer: { projects } }: IStore) => projects.data.endpoints.find(e => e.projectId === projectId && e.serverId === serverId));
}

export function useProjectSmtp(projectId: string) {
    return useSelector(({ consumer: { projects } }: IStore) => projects.data.smtps.find(e => e.projectId === projectId));
}

export function useProjectBucket(projectId: string) {
    return useSelector(({ consumer: { projects } }: IStore) => projects.data.buckets.find(e => e.projectId === projectId));
}