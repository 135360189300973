import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {  useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardText, CardTitle } from 'reactstrap';
import { IStore } from '@store';
import { authActions } from '@store/panel/authStore';
import { panelActions } from '@store/panel/panelStore';
import { userActions } from '@store/user/userStore';
import { usePromisedDispatch } from '../../utils/helpers';
import { useFetching } from '@selectors/panel.selectors';
import { GuestLayout } from '../layouts';
import { auth } from '@api/authService';

const PanelLoader: React.FC = ({ children }) => {

    const dispatch = usePromisedDispatch();
    const [loading, setLoading] = useState(true);
    const [hasError, setError] = useState(false);
    const isFetching = useFetching() || loading;
    const panelTitle = useSelector(({ panel }: IStore) => panel.settings.title);

    useEffect(() => auth().onAuthStateChange(user => {

        const authed = !!user;
        dispatch(authActions.setAuthed(authed));

        if (authed) {
            dispatch(userActions.fetchAll());
        }
        
    }), [dispatch]);

    useEffect(() => {
        const load = async () => {

            /*await auth.startupTokenRefresh();
            dispatch(authActions.setAuthed(auth.isAuthed));

            if (auth.isAuthed) {
                dispatch(userActions.fetchAll());
            }*/

            const settings = await dispatch(panelActions.fetchPanelSettings());

            if (!settings) {
                setError(true);
            }
            else {
                //await dispatch(panelActions.fetchPanelInfo());
            }

            setLoading(false);
        }

        load();

    }, [dispatch]);

    return (
        <>
            <Helmet
                defaultTitle={panelTitle}
                titleTemplate={`${panelTitle} - %s`}
            />
            <div className={classNames('splash', { 'active': isFetching })}>
                <div className="splash-icon"></div>
            </div>
            {
                isFetching || (
                    hasError ? <ErrorLayout
                        title="Ошибка загрузки"
                        body="Не удалось загрузить данные личного кабинета. Попробуйте позднее или обратитесь к администрации" />
                        : children)
            }
        </>
    )
}

interface ErrorLayoutProps {
    title: string;
    body: string;
}
const ErrorLayout: React.FC<ErrorLayoutProps> = React.memo(({ title, body }) => (
    <GuestLayout>
        <Card className="text-center">
            <CardHeader>
                <CardTitle tag="h5">
                    {title}
                </CardTitle>
                <CardBody>
                    <CardText>
                        {body}
                    </CardText>
                </CardBody>
            </CardHeader>
        </Card>
    </GuestLayout>
))

export default PanelLoader;