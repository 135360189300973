import React, { useCallback, useMemo, useState } from 'react';
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Table } from 'reactstrap';
import { LServer, Project } from '@interfaces/consumer/projects';
import { useProjects } from '@selectors/consumer/projects.selectors';
import { AnimatedFontAwesomeIcon } from '../../panel';
import { AddLoginServerModal } from './modals';

interface LoginServerListTabProps {
    project: Project;
}

const LoginServerListTable: React.FC<LoginServerListTabProps> = ({ project }) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const toggleModal = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen]);

    const { data: { loginServers } } = useProjects();
    const servers = useMemo(() => loginServers.filter((ls) => ls.projectId === project.id), [loginServers, project.id]);

    return (
        <Card>
            <ListTable servers={servers} toggleModal={toggleModal}/>
            <AddLoginServerModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                project={project} />
        </Card>
    )
}

interface ListProps {
    servers: LServer[];
    toggleModal: () => void;
}

const ListTable: React.FC<ListProps> = React.memo(({ servers, toggleModal }) => (
    <>
        <CardHeader>
            <div className="card-actions float-end">
                <AnimatedFontAwesomeIcon
                    icon={faPlus}
                    fixedWidth
                    className="align-middle"
                    onClick={toggleModal}
                    animate="spin"
                />
            </div>
            <CardTitle tag="h5">
                Список серверов авторизации
                </CardTitle>
        </CardHeader>
        <Table className="table table-hover">
            <thead>
                <tr>
                    <th style={{ width: "90%" }}>Наименование</th>
                    <th>Действия</th>
                </tr>
            </thead>
            <tbody>
                {servers.length > 0 ? servers.map((server, idx) => (
                    <tr key={server.id}>
                        <td>{server.name}</td>
                        <td>
                            <Link to={`/p/${server.projectId}/s/l/${server.id}/`}>
                                <FontAwesomeIcon
                                    icon={faPen}
                                    fixedWidth
                                    className="align-middle mr-1"
                                />
                            </Link>
                        </td>
                    </tr>
                )) : (
                        <tr>
                            <td align="center" colSpan={2}>Серверов еще нет</td>
                        </tr>
                    )}
            </tbody>
        </Table>
    </>
))

export default LoginServerListTable;