import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavbarDropdownItem } from '.';
import { NavbarDropdown } from '..';
import { UserNotification } from '../../../../interfaces/user/user';
import { userActions } from '../../../../redux/user/userStore';
import { fromUtcDate } from '../../../../utils/helpers';
import { useUserNotifications, useUserUnreadedNotificationsCount } from '../../../../utils/selectors/user.selectors';

interface NavbarNotificationProps {
    notification: UserNotification;
}
const NavbarNotification: React.FC<NavbarNotificationProps> = ({ notification }) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const time = useMemo(() => fromUtcDate(notification.createTime).calendar(), [notification.createTime]);

    console.log(notification);
    //const icon = notification.icon ? <FontAwesomeIcon icon={notification.icon as IconProp} /> : undefined;
    const icon = undefined;
    const onMouseLeave = useCallback(async () => {
        if(!notification.isReaded && !fetching) {
            setFetching(true);
            await dispatch(userActions.readNotification(notification.id));
            setFetching(false);
        }
    }, [dispatch, fetching, notification.id, notification.isReaded]);

    return (
        <NavbarDropdownItem
            icon={icon}
            title={notification.title}
            description={notification.message}
            indicator={!notification.isReaded}
            time={time}
            onMouseLeave={onMouseLeave}
            />
    )
}

const NavbarNotifications: React.FC = () => {

    const notifications = useUserNotifications();
    const newCount = useUserUnreadedNotificationsCount();

    return (
        <NavbarDropdown
            header="Уведомления"
            icon={faBell}
            indicator={newCount > 0}
        >
            {notifications.length > 0 ?
                notifications.map((item) => (
                    <NavbarNotification key={item.id} notification={item} />
                )) : (
                    <NavbarDropdownItem
                        icon={<FontAwesomeIcon icon={faBell} />}
                        description="Нет новых уведомлений"
                    />
                )
            }
        </NavbarDropdown>
    )
}

export default NavbarNotifications;