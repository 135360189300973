import { AnimatedFontAwesomeIcon, TableSpinner, withPage } from "@components/panel";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EProxyServerState } from "@interfaces/master/proxies";
import { useMasterProxies } from "@selectors/master.hooks";
import { FC, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardTitle, Input, Spinner, Table } from "reactstrap";
import { AddProxyModal } from ".";

function stateToText(state: EProxyServerState) {
    switch (state) {
        case EProxyServerState.Active:
            return 'Активен';
        case EProxyServerState.Creating:
            return 'Создан';
        case EProxyServerState.Error:
            return 'Ошибка';
        case EProxyServerState.Installing:
            return 'Установка'
    }
}

const ProxiesList: FC = () => {
    const [modalOpened, setModalOpened] = useState(false);
    const { loading, data: proxies } = useMasterProxies();

    const toggleModal = useCallback(() => setModalOpened(!modalOpened), [modalOpened]);

    return (
        <Card>
            <CardHeader>
                <div className="card-actions float-end">
                    <AnimatedFontAwesomeIcon
                        icon={faPlus}
                        fixedWidth
                        className="align-middle"
                        onClick={toggleModal}
                        animate="spin"
                    />
                </div>
                <CardTitle tag="h5">
                    Список прокси
                </CardTitle>
            </CardHeader>
            <Table className="table table-hover">
                <thead>
                    <tr>
                        <th>Публичный IP</th>
                        <th>Приватный IP</th>
                        <th>Статус</th>
                        <th>Публичный</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? <TableSpinner colSpan={5} /> :
                            proxies.length === 0 ? <tr><td align="center" colSpan={5}>Прокси пока нет</td></tr> :
                                proxies.map(proxy => (
                                    <tr key={proxy.id}>
                                        <td>{proxy.publicIP || 'Не назначен'}</td>
                                        <td>{proxy.privateIP || 'Не назначен'}</td>
                                        <td>{stateToText(proxy.state)}</td>
                                        <td><Input id={`cb-${proxy.id}`} type="checkbox" defaultChecked={proxy.isPublic} disabled /></td>
                                        <td className="table-action">
                                            {
                                                proxy.state > EProxyServerState.Installing ? (
                                                    <Link to={`/master/proxy/${proxy.id}`}>
                                                        <FontAwesomeIcon
                                                            icon={faPen}
                                                            fixedWidth
                                                            className="align-middle mr-1"
                                                        />
                                                    </Link>
                                                ) : (
                                                    <Spinner />
                                                )
                                            }
                                        </td>
                                    </tr>
                                ))
                    }
                </tbody>
            </Table>
            <AddProxyModal isOpen={modalOpened} toggle={toggleModal} />
        </Card>
    )
}

export default withPage(ProxiesList);