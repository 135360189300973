import React from 'react';
import { Collapse, Nav } from 'reactstrap';
import { useSidebarState } from '../../../utils/selectors/panel.selectors';
import { NavbarNotifications, NavbarSettings } from './dropdowns';

const NavbarDropdowns: React.FC = () => {

    const {isOnRight} = useSidebarState();

    return (
        <Collapse navbar>
            <Nav className={!isOnRight ? "ml-auto" : "mr-auto"} navbar>
                <NavbarNotifications />
                <NavbarSettings />
            </Nav>
        </Collapse>
    );
};

export default NavbarDropdowns;