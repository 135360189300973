import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { KeyValueSet } from "../../interfaces/helpers";
import { BreadcrumbAction, BreadcrumbInfo } from "../../interfaces/panel/breadcrumbs";
import { PanelInfo } from "../../interfaces/panelInfo";
import { PanelSettings, SidebarState } from "../../models/panel/panelSettings";
import PanelService from "../../services/panelService";

export interface PanelStore {
    settings: PanelSettings;
    sidebar: SidebarState;
    breadcrumbs: KeyValueSet<BreadcrumbInfo>;
    info: PanelInfo;
    vars: KeyValueSet<string | number>;
}

const panelSlice = createSlice({
    name: 'panelStore',
    initialState: {
        settings: {
            baseUrl: '',
            isActive: false,
            projectName: '',
            title: '',
            defaultLang: '',
            recaptchaPublic: ''
        },
        sidebar: {
            isOpened: true,
            isOnRight: false
        },
        breadcrumbs: {},
        info: {
            prices: []
        },
        vars: {}
    } as PanelStore,
    reducers: {
        setSettings: (state, action: PayloadAction<PanelSettings>) => {
            state.settings = action.payload
        },
        toggleSidebar: (state) => {
            state.sidebar.isOpened = !state.sidebar.isOpened
        },
        setBreadcrumb: (state, action: PayloadAction<BreadcrumbAction>) => {
            state.breadcrumbs[action.payload.code] = action.payload.info;
        },
        unsetBreadcrumb: (state, action: PayloadAction<string>) => {
            delete state.breadcrumbs[action.payload];
        },
        setInfo: (state, action: PayloadAction<PanelInfo>) => {
            state.info = action.payload;
        },
        setVar: (state, action: PayloadAction<{ key: string, value: string | number }>) => {
            state.vars[action.payload.key] = action.payload.value;
        },
        deleteVar: (state, action: PayloadAction<string>) => {
            delete state.vars[action.payload];
        }
    }
});

const {
    setSettings,
    toggleSidebar,
    setBreadcrumb,
    unsetBreadcrumb,
    setInfo,
    setVar,
    deleteVar
} = panelSlice.actions;

export const panelActions = {

    setVar,
    deleteVar,

    fetchPanelSettings: () => async (dispatch: Dispatch) => {
        const api = new PanelService();
        try {
            const result = await api.fetchPanelSettings();
            dispatch(setSettings(result));
            return result;
        }
        catch(e) {
            return undefined;
        }
    },
    toggleSidebar,
    setBreadcrumb: (code: string, info: BreadcrumbInfo) => (dispatch: Dispatch) => {
        dispatch(setBreadcrumb({
            code,
            info
        }));
    },
    unsetBreadcrumb: (code: string) => (dispatch: Dispatch) => {
        dispatch(unsetBreadcrumb(code));
    },
    fetchPanelInfo: () => async (dispatch: Dispatch) => {
        const api = new PanelService();
        try{
            const info = await api.fetchPanelInfo();
            dispatch(setInfo(info));
            return info;
        }
        catch(e){
            return undefined;
        }
    }
}

export const { reducer: panelReducer } = panelSlice;