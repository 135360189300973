import React from 'react';

import { Navbar } from 'reactstrap';
import { NavbarToggle } from '.';
import { useSidebarState } from '../../../utils/selectors/panel.selectors';
import NavbarDropdowns from './navbarDropdowns';

const PanelNavbar: React.FC = () => {

  const { isOnRight } = useSidebarState();

  return (
    <Navbar expand className="navbar-theme">
      {!isOnRight ? (
        <React.Fragment>
          <NavbarToggle />
          <NavbarDropdowns />
        </React.Fragment>
      ) : (
          <React.Fragment>
            <NavbarDropdowns />
            <NavbarToggle />
          </React.Fragment>
        )}
    </Navbar>
  );
};


export default PanelNavbar;