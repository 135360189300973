import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import { useRecaptchaPublic } from '../../../utils/hooks/panel.hooks';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FormikInput } from '../../panel/formik';
import { Link } from 'react-router-dom';
import { auth } from '../../../services/authService';

interface ForgotPasswordForm {
    email: string;
}

const ForgotPage: React.FC = () => {

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const recaptchaKey = useRecaptchaPublic();

    const validate = useMemo(() => Yup.object().shape<ForgotPasswordForm>({
        email: Yup.string()
            .email("Введите корректный email")
            .required('Обязательно')
    }), []);

    const initial: ForgotPasswordForm = {
        email: ''
    }

    const onSubmit = useCallback(async (values: ForgotPasswordForm, actions: FormikHelpers<ForgotPasswordForm>) => {

        if (!recaptcha) {
            actions.setFieldError("email", "ReCAPTCHA обязательна");
            return;
        }

        const result = await auth().forgotPassword({ email: values.email, recaptcha });

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true,
        }

        if (result.ok) {
            toastr.success("Восстановление пароля", "Код для восстановления пароля отправлен вам на почту.", options);
        }
        else {
            if (result.errorCode === 'SmtpError') {
                toastr.error("Восстановление пароля", "Ошибка отправки письма. Обратитесь к администратору.");
            }
            else if (result.errorCode === 'InvalidModel') {
                actions.setFieldError('email', 'Некорректный запрос. Обновите страницу и попробуйте еще раз.');
            }
            recaptchaRef.current?.reset();
        }
    }, [recaptcha])

    return (
        <>
            <div className="text-center mt-4">
                <p className="lead">
                    Восстановление пароля мастер-аккаунта
                </p>
            </div>
            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <Formik
                            initialValues={initial}
                            onSubmit={onSubmit}
                            validationSchema={validate}>
                            {
                                ({ isSubmitting, isValid }) => (
                                    <Form>
                                        <Field
                                            name="email"
                                            type="email"
                                            label="Ваш Email"
                                            placeholder="Введите email"
                                            component={FormikInput}
                                        />
                                        <FormGroup className="text-center">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={recaptchaKey}
                                                onChange={setRecaptcha}
                                                badge="inline"
                                                size="normal"
                                                {...{ style: { display: 'inline-block' } }}
                                            />
                                        </FormGroup>
                                        <small>
                                            <Link to="/signin/">Авторизация</Link>
                                            {' | '}
                                            <Link to="/signup/">Регистрация</Link>
                                        </small>
                                        <div className="text-center mt-3">
                                            <Button color="primary" size="lg" disabled={isSubmitting || !isValid} type="submit">
                                                Восстановить пароль
                                    </Button>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default ForgotPage;