import { FormikHelpers, Formik, Field, Form, ErrorMessage } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Modal, ModalHeader, ModalBody, FormGroup, Label, Input, ModalFooter, Button, FormFeedback } from 'reactstrap';
import { Project } from '../../../../interfaces/consumer/projects';
import { PutGameServerModel } from '../../../../models/consumer/projects';
import * as Yup from 'yup';
import { useProjects } from '../../../../utils/selectors/consumer/projects.selectors';
import { toPromise } from '../../../../utils/helpers';
import { projectActions } from '../../../../redux/consumer/projectsStore';
import { FormikInput } from '../../../panel/formik';

interface AddGameServerModalProps {
    project: Project;
    isOpen: boolean;
    toggle(): void;
}

interface AddGameServerModalForm {
    name: string;
    description: string;
    login: string;
}

const AddGameServerModal: React.FC<AddGameServerModalProps> = ({ project, isOpen, toggle }) => {

    const dispatch = useDispatch();
    const { data: { loginServers } } = useProjects();
    const logins = useMemo(() => loginServers.filter(l => l.projectId === project.id), [loginServers, project.id]);


    const onSubmit = useCallback(async (values: AddGameServerModalForm, actions: FormikHelpers<AddGameServerModalForm>) => {

        const game: PutGameServerModel = {
            name: values.name,
            description: values.description,
            loginId: values.login,
            projectId: project.id,
        }

        const result = await toPromise(dispatch(projectActions.addGameServer(game)));

        if (result.ok) {
            const options: BasicToastrOptions = {
                timeOut: 10000,
                showCloseButton: true,
                progressBar: true,

            }
            toastr.success("Создание игрового сервера", "Сервер успешно создан", options);
            toggle();
        }
        else {
            actions.setFieldError('name', 'Ошибка запроса. Обновите страницу');
        }
    }, [dispatch, project.id, toggle]);

    const validation = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .min(6)
            .max(64)
            .required('Укажите имя сервера'),
        description: Yup.string()
            .max(256)
            .notRequired(),
        login: Yup.string()
            .required('Выберите логин')
    }), []);

    const initial: AddGameServerModalForm = {
        login: '',
        description: '',
        name: '',
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Создание нового игрового сервера
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={onSubmit}>
                {({ isSubmitting, errors, touched }) => (
                    <Form>
                        <ModalBody>
                            <Field
                                label="Название сервера"
                                type="text"
                                name="name"
                                placeholder="Введите название сервера"
                                component={FormikInput} />
                            <Field
                                label="Описание сервера"
                                type="text"
                                name="description"
                                placeholder="Введите описание сервера (необязательно)"
                                component={FormikInput} />
                            <FormGroup>
                                <Label for="login">Сервер авторизации</Label>
                                <Field
                                    name="login"
                                    id="login"
                                    as={Input}
                                    type="select"
                                    invalid={Boolean(touched.login && errors.login)}>
                                    <option value={''} key={'def'}>Выберите сервер авторизации</option>
                                    {logins.map((ls) => <option value={ls.id} key={ls.id}>{ls.name}</option>)}
                                </Field>
                                <ErrorMessage name="login" component={FormFeedback} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" onClick={toggle}>
                                Закрыть
                            </Button>{" "}
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                Создать сервер
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default AddGameServerModal;