import { IStore } from "@store";
import { shallowEqual, useSelector } from "react-redux";

export function useUnitpays(projectId?: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { unitpay } } } } }: IStore) =>
        projectId ? unitpay.filter(u => u.projectId === projectId) : unitpay, shallowEqual);
}

export function useUnitpay(unitpayId: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { unitpay } } } } }: IStore) =>
        unitpay.find(u => u.id === unitpayId));
}

export function useEnots(projectId?: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { enot } } } } }: IStore) =>
        projectId ? enot.filter(u => u.projectId === projectId) : enot, shallowEqual);
}

export function useEnot(enotId: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { enot } } } } }: IStore) =>
        enot.find(u => u.id === enotId));
}

export function usePrimes(projectId?: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { prime } } } } }: IStore) =>
        projectId ? prime.filter(u => u.projectId === projectId) : prime, shallowEqual);
}

export function usePrime(primeId: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { prime } } } } }: IStore) =>
        prime.find(u => u.id === primeId));
}

export function usePayPalyches(projectId?: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { payPalych } } } } }: IStore) =>
        projectId ? payPalych.filter(u => u.projectId === projectId) : payPalych, shallowEqual);
}

export function usePayPalych(palychId: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { payPalych } } } } }: IStore) =>
        payPalych.find(u => u.id === palychId));
}

export function useAntilopays(projectId?: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { antilopay } } } } }: IStore) =>
        projectId ? antilopay.filter(u => u.projectId === projectId) : antilopay, shallowEqual);
}

export function useAntilopay(antilopayId: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { antilopay } } } } }: IStore) =>
        antilopay.find(u => u.id === antilopayId));
}

export function useStreamPay(streamPayId: string) {
    return useSelector(({ consumer: { projects: { data: { payments: { streamPay } } } } }: IStore) =>
        streamPay.find(u => u.id === streamPayId));
}

export function useStreamPays(projectId?: string) {
    return useSelector(({consumer: {projects: {data: {payments: {streamPay}}}}}: IStore) =>
        projectId ? streamPay.filter(u => u.projectId === projectId) : streamPay, shallowEqual);
}