import { FormikInput, FormikCheckbox } from "@components/panel/formik";
import { storagesActions } from "@store/consumer/storagesStore";
import { Formik, Field, Form } from "formik";
import { useMemo, useCallback } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { usePromisedDispatch } from "../../../utils/helpers";
import * as Yup from 'yup';

interface AddStorageModalProps {
    isOpen: boolean;
    toggle: () => void;
}

interface AddStorageModalForm {
    name: string;
    endpoint: string;
    accessKey: string;
    secretKey: string;
    region: string;
    size: number;
    useSSL: boolean;
}

const AddStorageModal: React.FC<AddStorageModalProps> = ({ isOpen, toggle }) => {

    const history = useHistory();
    const dispatch = usePromisedDispatch();

    const initial: AddStorageModalForm = {
        name: '',
        endpoint: '',
        accessKey: '',
        secretKey: '',
        region: '',
        size: 10,
        useSSL: true
    }

    const validation = useMemo(() => Yup.object().shape<AddStorageModalForm>({
        name: Yup.string().required(),
        endpoint: Yup.string().required(),
        accessKey: Yup.string().required(),
        secretKey: Yup.string().required(),
        region: Yup.string(),
        size: Yup.number().required(),
        useSSL: Yup.boolean().required()
    }), []);

    const onSubmit = useCallback(async (values: AddStorageModalForm) => {

        const result = await dispatch(storagesActions.addStorage(values));

        if (result.success) {
            toastr.success("Добавление хранилища", "Хранилище успешно добавлено.");
            toggle();
            history.push(`/storage/${result.data.id}/`);
        }
        else {
            toastr.error("Ошибка", "Неизвестная ошибка. Обновите страницу и попробуйте еще раз.");
        }
    }, [dispatch, history, toggle]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Добавить хранилище
            </ModalHeader>
            <Formik
                initialValues={initial}
                validationSchema={validation}
                onSubmit={onSubmit}>
                {({ isSubmitting, isValid }) => (
                    <Form>
                        <ModalBody>
                            <Field
                                label="Название хранилища"
                                type="text"
                                name="name"
                                placeholder="Введите название хранилища"
                                component={FormikInput} />
                            <Field
                                label="Адрес"
                                type="text"
                                name="endpoint"
                                placeholder="Введите адрес хранилища"
                                component={FormikInput} />
                            <Field
                                label="Ключ доступа"
                                type="text"
                                name="accessKey"
                                placeholder="Введите ключ доступа хранилища"
                                component={FormikInput} />
                            <Field
                                label="Секретный ключ"
                                type="password"
                                name="secretKey"
                                placeholder="Введите секретный ключ хранилища"
                                component={FormikInput} />
                            <Field
                                label="Регион"
                                type="text"
                                name="region"
                                placeholder="Введите регион хранилища"
                                component={FormikInput} />
                            <Field
                                label="Размер хранилища (mb)"
                                type="number"
                                name="size"
                                placeholder="Введите размер хранилища"
                                component={FormikInput} />
                            <Field
                                label="Использовать SSL"
                                name="useSSL"
                                type="switch"
                                description="Подключаться к хранилищу через SSL"
                                component={FormikCheckbox} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" onClick={toggle}>
                                Закрыть
                            </Button>{" "}
                            <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
                                Создать
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default AddStorageModal;