import { useSelector } from "react-redux";
import { IStore } from "@store";

export function useFetching() {
    return useSelector((state: IStore) => {
        return state.user.fetching
    });
}

export function useSidebarState() {
    return useSelector(({ panel }: IStore) => panel.sidebar);
}

export function useBreadcrumbsData() {
    return useSelector(({ panel }: IStore) => panel.breadcrumbs);
}

export function useVar(key: string): string | number | undefined {
    return useSelector(({ panel }: IStore) => panel.vars[key]);
}