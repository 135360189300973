import {FC, useCallback, useMemo, useState} from 'react';
import { FormikHelpers, Formik, Form, Field } from 'formik';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import {Card, CardBody, Button, Spinner, CardHeader, CardTitle} from 'reactstrap';
import { ISmtpSettings } from '@interfaces/consumer/projects';
import { FormikRowInput, FormikRowCheckbox } from '../../../panel/formik';
import { SmtpSettingsCheckModal } from '../modals';
import * as Yup from 'yup';
import { usePromisedDispatch } from '../../../../utils/helpers';
import { projectActions } from '@store/consumer/projectsStore';
import {useProjectSmtp} from "@selectors/consumer/projects.selectors";

interface SmtpSettingsTabProps {
    settings?: ISmtpSettings;
    projectId: string;
}

interface SmtpSettingsTabForm {
    fromName: string;
    fromMail: string;
    host: string;
    port: string;
    useSSL: boolean;
    user: string;
    password: string;
}

const SmtpSettingsTab: FC<SmtpSettingsTabProps> = ({ projectId }) => {

    const settings = useProjectSmtp(projectId);
    const dispatch = usePromisedDispatch();
    const [isModalOpen, setModalOpen] = useState(false);
    const toggle = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen]);

    const onSubmit = useCallback(async ({fromMail, fromName, host, password, port, useSSL, user}: SmtpSettingsTabForm, actions: FormikHelpers<SmtpSettingsTabForm>) => {

        const result = settings ? await dispatch(projectActions.updateSmtp({
            id: settings.id,
            projectId: settings.projectId,
            fromMail,
            fromName,
            host,
            password,
            port,
            useSSL,
            user
        })) : await dispatch(projectActions.addSmtp({
            projectId,
            fromMail,
            fromName,
            host,
            password,
            port,
            useSSL,
            user
        }));

        if (result.ok) {
            const options: BasicToastrOptions = {
                timeOut: 10000,
                showCloseButton: true,
                progressBar: true,

            }
            toastr.success("Настройки smtp", "Настройки сохранены", options);
        }
        else {
            if(result.errorCode === 'SMTPNotFound') {
                toastr.error('Настройки smtp', 'Проект не найден');
                dispatch(projectActions.fetchSmtps(true));
            }
            else if(result.errorCode === 'ProjectNotFound') {
                toastr.error('Настройки smtp', 'Проект не найден');
                dispatch(projectActions.fetchAll(true));
            }
            else if(result.errorCode === 'SmtpAlreadyExists') {
                toastr.error('Настройки smtp', 'Настройки уже были изменены');
                dispatch(projectActions.fetchSmtps(true));
            }
            else {
                toastr.error('Настройки smtp', 'Что-то пошло не так');
            }
        }
    }, [dispatch, projectId, settings]);

    const validate = useMemo(() => Yup.object().shape({
        fromMail: Yup.string().email().required(),
        fromName: Yup.string().max(64).required(),
        host: Yup.string().required(),
        port: Yup.number().required(),
        user: Yup.string().required(),
        password: Yup.string().notRequired(),
    }), []);


    const initial: SmtpSettingsTabForm = {
        host: settings?.host || '',
        port: settings?.port || '',
        useSSL: settings ? settings.useSSL : true,
        user: settings?.user || '',
        password: settings?.password || '',
        fromMail: settings?.fromMail || '',
        fromName: settings?.fromName || ''
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">Настройки почты</CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initial}
                    validationSchema={validate}
                    onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Field
                                label="Адрес отправителя"
                                type="email"
                                name="fromMail"
                                placeholder="your@mail.com"
                                component={FormikRowInput} />
                            <Field
                                label="Имя отправителя"
                                type="text"
                                name="fromName"
                                placeholder="Администрация"
                                component={FormikRowInput} />
                            <Field
                                label="Адрес SMPT сервера"
                                type="text"
                                name="host"
                                placeholder="smtp.yandex.ru"
                                component={FormikRowInput} />
                            <Field
                                label="SMTP порт"
                                type="number"
                                name="port"
                                placeholder="465"
                                component={FormikRowInput} />
                            <Field
                                label="Использовать SSL"
                                name="useSSL"
                                type="switch"
                                component={FormikRowCheckbox} />
                            <Field
                                label="Имя пользователя"
                                type="text"
                                name="user"
                                placeholder="admin@yandex.ru"
                                component={FormikRowInput} />
                            <Field
                                label="Пароль"
                                type="password"
                                name="password"
                                placeholder="******"
                                description="Оставьте поле пустым если не хотите менять пароль"
                                component={FormikRowInput} />
                            <div className="text-center mt-3">
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    {isSubmitting && <Spinner size="sm" color="red" />}
                                        Сохранить
                                </Button>
                            </div>
                            <div className="text-right mt-3">
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Проверить
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                {
                    settings && <SmtpSettingsCheckModal isOpen={isModalOpen} smtpId={settings.id} toggle={toggle} />
                }
            </CardBody>
        </Card>
    )

}

export default SmtpSettingsTab;