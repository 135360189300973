import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Button, Card, CardBody, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { useRecaptchaPublic } from '../../../utils/hooks/panel.hooks';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../redux/panel/authStore';
import { userActions } from '../../../redux/user/userStore';
import { auth } from '../../../services/authService';

interface FormValues {
    username: string;
    password: string;
}

const SignInPage: React.FC = () => {

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const recaptchaKey = useRecaptchaPublic();
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = useCallback(async (values: FormValues, actions: FormikHelpers<FormValues>) => {

        if (!recaptcha) {
            actions.setFieldError("username", 'Авторизация');
            return;
        }

        const token = await auth().signIn({
            ...values,
            recaptcha,
        });

        if (!token.ok) {
            if (token.errorCode === 'invalid_grant')
                actions.setFieldError("username", "Неправильные имя пользователя или пароль");
            else if (token.errorCode === "unknown_project")
                actions.setFieldError("username", "Неизвестный проект");
            else if (token.errorCode === 'EmailUnconfirmed') {
                toastr.error('Авторизация', 'Подтвердите почту');
            }
            else if (token.errorCode === 'InvalidRecaptcha') {
                toastr.error('Авторизация', "Некорректная рекапча");
            }
            else if (token.errorCode === 'FetchError') {
                toastr.error('Авторизация', "Не удалось подключиться к серверу");
            }
            else {
                toastr.error('Авторизация', "Неизвестная ошибка");
            }
            recaptchaRef.current?.reset();
        }
        else {
            dispatch(authActions.setAuthed(true));
            await dispatch(userActions.fetchAll(true));
            history.replace('/');
        }

    }, [dispatch, history, recaptcha]);

    const initial: FormValues = {
        username: '',
        password: ''
    }

    const validate = useMemo(() => {
        return Yup.object().shape<FormValues>({
            username: Yup.string()
                .min(5, "Не менее 5 символов")
                .max(32, "Не более 32 символов")
                .required("Обязательно"),
            password: Yup.string()
                .min(6, "Не менее 6 символов")
                .max(64, "Не более 64 символов")
                .required("Обязательно")
        })
    }, []);

    return (
        <>
            <div className="text-center mt-4">
                <p className="lead">
                    Авторизация
                </p>
            </div>
            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <Formik
                            onSubmit={onSubmit}
                            initialValues={initial}
                            validationSchema={validate}>
                            {
                                ({ isSubmitting, isValid, touched, errors }) => (
                                    <Form>
                                        <FormGroup>
                                            <Label for="username">Имя пользователя</Label>
                                            <Field
                                                id="username"
                                                name="username"
                                                type="text"
                                                placeholder="Введите имя пользователя"
                                                invalid={Boolean(touched['username'] && errors['username'])}
                                                as={Input} />
                                            <FormFeedback valid={false}>{errors['username']}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="password">Пароль</Label>
                                            <Field
                                                id="password"
                                                name="password"
                                                type="password"
                                                placeholder="Введите пароль"
                                                invalid={Boolean(touched['password'] && errors['password'])}
                                                as={Input} />
                                            <FormFeedback valid={false}>{errors['password']}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup className="text-center">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={recaptchaKey}
                                                onChange={setRecaptcha}
                                                badge="inline"
                                                size="normal"
                                                {...{ style: { display: 'inline-block' } }}
                                            />
                                        </FormGroup>
                                        <small>
                                            <Link to="/signup/">Регистрация</Link>
                                            {' | '}
                                            <Link to="/forgot/">Восстановить пароль</Link>
                                        </small>
                                        <div className="text-center mt-3">
                                            <Button color="primary" size="lg" disabled={isSubmitting || !isValid} type="submit">
                                                Войти
                                            </Button>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default SignInPage;