import React from 'react';
import { ListGroupItem, Row, Col } from 'reactstrap';

interface NavbarDropdownItemProps {
    icon?: React.ReactNode;
    title?: string;
    description?: string;
    time?: string;
    spacing?: boolean;
    indicator?: boolean;
    onMouseLeave?(): void;
}

const NavbarDropdownItem: React.FC<NavbarDropdownItemProps> = ({ icon, title, description, time, spacing, indicator, onMouseLeave }) => (
    <ListGroupItem onMouseLeave={onMouseLeave}>
        <Row className="align-items-center g-0">
            <Col xs={2}>{icon}</Col>
            <Col xs={10} className={spacing ? "pl-2" : undefined}>
                {title && <div className="text-dark">{title}</div>}
                {indicator && <span className="indicator" />}
                {description && <div className="text-muted small mt-1">{description}</div>}
                {time && <div className="text-muted small mt-1">{time}</div>}
            </Col>
        </Row>
    </ListGroupItem>
);

export default React.memo(NavbarDropdownItem);