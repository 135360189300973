import React, {FC} from "react";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import { BucketInfoTab } from "@components/consumer/projects/tabs";
import {withPage} from "@components/panel";

const BucketPage: FC = () => {
    const projectId = useCurrentProjectId();
    return (
        <BucketInfoTab projectId={projectId} />
    )
}

export default withPage(BucketPage);