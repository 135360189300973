import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Formik, FormikHelpers, Form, Field } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import { useRecaptchaPublic } from '../../../utils/hooks/panel.hooks';
import { FormikInput } from '../../panel/formik';
import { auth } from '../../../services/authService';

interface ResetPasswordForm {
    password: string;
    repeatPassword: string;
}

const ResetPasswordPage: React.FC = () => {

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const recaptchaKey = useRecaptchaPublic();
    const history = useHistory();

    const { code } = useParams<{ code: string }>();

    const validation = useMemo(() => Yup.object().shape<ResetPasswordForm>({
        password: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(16, "Не более 16 символов")
            .required("Обязательно")
            .matches(/[A-Z]/, "Должен содержать заглавные буквы")
            .matches(/[a-z]/, "Должен содержать прописные буквы")
            .matches(/[0-9]/, "Должен содержать цифры"),
        repeatPassword: Yup.string()
            .required()
            .oneOf([Yup.ref('password')], "Пароли не совпадают")
    }), []);

    const initial: ResetPasswordForm = {
        password: '',
        repeatPassword: ''
    }

    const onSubmit = useCallback(async (values: ResetPasswordForm, actions: FormikHelpers<ResetPasswordForm>) => {

        if (!recaptcha) {
            actions.setFieldError("password", "ReCAPTCHA обязательна");
            return;
        }

        const result = await auth().resetPassword({
            code,
            password: values.password,
            recaptcha
        });

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true,
        }

        if (result.ok) {
            toastr.success("Восстановления пароля", "Пароль успешно изменен", options);
            history.replace("/signin/");
        }
        else {
            if (result.errorCode === 'UserNotFound') {
                toastr.error("Восстановления пароля", "Код восстановления недействителен или время его действия истекло.", options);
            }
            else if (result.errorCode === 'InvalidPassword') {
                actions.setFieldError('password', 'Некорректный пароль');
            }
            else if (result.errorCode === 'UpdateError') {
                toastr.error("Восстановления пароля", "Не удалось изменить пароль. Обратитесь к администратору.", options);
            }
        }
    }, [code, history, recaptcha]);

    return (
        <>
            <div className="text-center mt-4">
                <p className="lead">
                    Сброс пароля мастер-аккаунта
                </p>
            </div>
            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <Formik
                            initialValues={initial}
                            onSubmit={onSubmit}
                            validationSchema={validation}>
                            {
                                ({ isSubmitting, isValid }) => (
                                    <Form>
                                        <Field
                                            label="Введите пароль"
                                            type="password"
                                            name="password"
                                            placeholder="Пароль"
                                            component={FormikInput} />
                                        <Field
                                            label="Подтвердите пароль"
                                            type="password"
                                            name="repeatPassword"
                                            placeholder="Подтверждение"
                                            component={FormikInput} />
                                        <FormGroup className="text-center">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={recaptchaKey}
                                                onChange={setRecaptcha}
                                                badge="inline"
                                                size="normal"
                                                {...{ style: { display: 'inline-block' } }}
                                            />
                                        </FormGroup>
                                        <small>
                                            <Link to="/signin/">Авторизация</Link>
                                            {' | '}
                                            <Link to="/signup/">Регистрация</Link>
                                        </small>
                                        <div className="text-center mt-3">
                                            <Button color="primary" size="lg" disabled={isSubmitting || !isValid} type="submit">
                                                Изменить пароль
                                            </Button>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default ResetPasswordPage;