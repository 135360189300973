import {FC} from "react";
import {EPaymentCurrency, ExchangeRateSource} from "@interfaces/consumer/payments";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import {Button, Col, FormFeedback, FormGroup, Input, Label, Spinner} from "reactstrap";
import {currencies} from "../../../../utils/data";

export interface CurrencyFormValues {
    isEnabled: boolean;
    isStatic: boolean;
    currency: EPaymentCurrency;
    value: number;
    source: ExchangeRateSource;
    donateMin: number;
    donateMax: number;
}

interface CurrencyFormProps {
    initialValues: CurrencyFormValues;
    onSubmit: (values: CurrencyFormValues, actions: FormikHelpers<CurrencyFormValues>) => any;
    editForm?: boolean;
}

const CurrencyForm: FC<CurrencyFormProps> = ({initialValues, onSubmit, editForm}) => {

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {
                ({isSubmitting, values, touched, errors, setFieldValue}) => (
                    <Form>
                        <FormGroup row>
                            <Label for="currency" sm='3'>Валюта</Label>
                            <Col sm='9'>
                                <Field
                                    name="currency"
                                    id="currency"
                                    as={Input}
                                    type="select"
                                    disabled={editForm}
                                    invalid={Boolean(touched.currency && errors.currency)}>
                                    {currencies.map(x => (
                                        <option key={x.value} value={x.value}>{x.label}</option>
                                    ))}
                                </Field>
                                <ErrorMessage name="currency" component={FormFeedback} />
                            </Col>
                        </FormGroup>
                        <Field
                            id='isEnabled'
                            label="Активно"
                            name="isEnabled"
                            type="switch"
                            component={FormikRowCheckbox} />
                        <FormGroup row>
                            <Label sm={3}>Тип курса</Label>
                            <Col sm={9} role='group'>
                                <FormGroup check>
                                    <Input
                                        type='radio'
                                        name='isStatic'
                                        id='currency-static'
                                        value={1}
                                        checked={values.isStatic}
                                        onChange={() => setFieldValue('isStatic', true)} />
                                    <Label check>Фиксированный</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input
                                        type='radio'
                                        name='isStatic'
                                        id='currency-dynamic'
                                        value={0}
                                        checked={!values.isStatic}
                                        onChange={() => setFieldValue('isStatic', false)} />
                                    <Label check>Динамический</Label>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                        {
                            values.isStatic ? (
                                <Field
                                    id='value'
                                    label="Курс"
                                    name="value"
                                    type="number"
                                    placeholder="1"
                                    component={FormikRowInput} />
                            ) : (
                                <FormGroup row>
                                    <Label for="source" sm='3'>Источник курса</Label>
                                    <Col sm={9}>
                                        <Field
                                            id='source'
                                            name="source"
                                            type="select"
                                            invalid={Boolean(touched.source && errors.source)}
                                            as={Input}>
                                            <option value={ExchangeRateSource.CBR}>ЦБ РФ</option>
                                        </Field>
                                    </Col>
                                </FormGroup>
                            )
                        }
                        <Field
                            label="Минимальная сумма"
                            type="number"
                            name="donateMin"
                            placeholder="0"
                            description="Минимальная сумма пополнения (0 для отключения)"
                            component={FormikRowInput} />
                        <Field
                            label="Максимальная сумма"
                            type="number"
                            name="donateMax"
                            placeholder="0"
                            description="Максимальная сумма пополнения (0 для отключения)"
                            component={FormikRowInput} />
                        <div className="text-center mt-3">
                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                {isSubmitting && <Spinner size="sm" color="red" />}
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}

export default CurrencyForm;