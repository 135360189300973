import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { Loadable } from "@interfaces/helpers";
import { IBucket, GServer, LServer, Project, ProjectsHolder, ISmtpSettings, IBackendEndpoint } from "@interfaces/consumer/projects";
import ProjectsService from "../../services/consumer/projectsService";
import {
    CreateProjectModel,
    PatchGameServerModel,
    PatchLoginServerModel,
    PutGameServerModel,
    PutLoginServerModel,
    UpdateProjectModel,
    PatchBackendEndpointModel,
    PatchSmtpSettings,
    PutSmtpSettings
} from '@models/consumer/projects';
import { PutConsumerBucketModel } from "@models/consumer/storages";
import {
    PatchAntilopayPaymentSettings,
    PatchEnotPaymentSettings,
    PatchPayPalychPaymentSettings,
    PatchPrimePaymentSettings, PatchStreamPayPaymentSettings,
    PatchUnitpayPaymentSettings,
    PutAntilopayPaymentSettings,
    PutEnotPaymentSettings,
    PutPayPalychPaymentSettings,
    PutPrimePaymentSettings, PutStreamPayPaymentSettings,
    PutUnitpayPaymentSettings
} from "@models/consumer/payments";
import {
    IAntilopaySettings,
    IEnotPaymentSettings,
    IPaymentSettings,
    IPayPalychSettings,
    IPrimePaymentSettings, IStreamPaySettings,
    IUnitpayPaymentSettings
} from "@interfaces/consumer/payments";
import PaymentsService from "@api/consumer/paymentsService";

export type ProjectsStore = Loadable<ProjectsHolder>;

const emptyData: ProjectsHolder = {
    buckets: [],
    gameServers: [],
    loginServers: [],
    payments: {
        unitpay: [],
        enot: [],
        prime: [],
        payPalych: [],
        antilopay: [],
        streamPay: []
    },
    projects: [],
    providers: [],
    smtps: [],
    endpoints: []
}

const projectsSlice = createSlice({
    name: 'projectsStore',
    initialState: {
        loaded: false,
        loading: false,
        error: null,
        data: { ...emptyData }
    } as ProjectsStore,
    reducers: {
        setData: (state, action: PayloadAction<ProjectsHolder>) => {
            state.error = null;
            state.loaded = true;
            state.loading = false;
            state.data = {
                ...state.data,
                ...action.payload
            };
        },
        setBuckets: (state, action: PayloadAction<IBucket[]>) => {
            state.data.buckets = action.payload || [];
        },
        setPayments: (state, action: PayloadAction<IPaymentSettings>) => {
            state.data.payments = action.payload || {
                unitpay: []
            }
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setError: (state, action) => {
            state.loading = false;
            //state.loaded = false;
            state.error = action.payload;
            //state.data = { ...emptyData };
        },
        updateProject: (state, action: PayloadAction<Project>) => {
            const idx = state.data.projects.findIndex(p => p.id === action.payload.id);
            if (idx >= 0) {
                state.data.projects[idx] = action.payload;
            }
            else {
                state.data.projects.push(action.payload);
            }
        },
        updateSmtp: (state, action: PayloadAction<ISmtpSettings>) => {
            const idx = state.data.smtps.findIndex(s => s.id === action.payload.id);
            if (idx >= 0) {
                state.data.smtps[idx] = action.payload;
            }
            else {
                state.data.smtps.push(action.payload);
            }
        },
        updateLoginServer: (state, action: PayloadAction<LServer>) => {
            const idx = state.data.loginServers.findIndex(l => l.id === action.payload.id);
            if (idx >= 0) {
                state.data.loginServers[idx] = action.payload;
            }
            else {
                state.data.loginServers.push(action.payload);
            }
        },
        updateGameServer: (state, action: PayloadAction<GServer>) => {
            const idx = state.data.gameServers.findIndex(g => g.id === action.payload.id);
            if (idx >= 0) {
                state.data.gameServers[idx] = action.payload;
            }
            else {
                state.data.gameServers.push(action.payload);
            }
        },
        updateBucket: (state, action: PayloadAction<IBucket>) => {
            const idx = state.data.buckets.findIndex(b => b.id === action.payload.id);
            if (idx >= 0) {
                state.data.buckets[idx] = action.payload;
            }
            else {
                state.data.buckets.push(action.payload);
            }
        },
        setSmtps: (state, action: PayloadAction<ISmtpSettings[]>) => {
            state.data.smtps = action.payload;
            state.loading = false;
        },
        setEndpoints: (state, action: PayloadAction<IBackendEndpoint[]>) => {
            state.data.endpoints = action.payload || [];
        },
        updateEndpont: (state, action: PayloadAction<IBackendEndpoint>) => {
            const endpoint = action.payload;
            const idx = state.data.endpoints.findIndex(e => e.projectId === endpoint.projectId && e.serverId === endpoint.serverId);
            if (idx >= 0) {
                state.data.endpoints[idx] = endpoint;
            }
            else {
                state.data.endpoints.push(endpoint);
            }
        },

        updateUnitpay: (state, action: PayloadAction<IUnitpayPaymentSettings>) => {
            const idx = state.data.payments.unitpay.findIndex(s => s.id === action.payload.id);
            if (idx >= 0)
                state.data.payments.unitpay[idx] = action.payload
            else
                state.data.payments.unitpay.push(action.payload);
        },
        deleteUnitpay: (state, { payload: id }: PayloadAction<string>) => {
            state.data.payments.unitpay = state.data.payments.unitpay.filter(p => p.id !== id)
        },
        updateEnot: (state, action: PayloadAction<IEnotPaymentSettings>) => {
            const idx = state.data.payments.enot.findIndex(s => s.id === action.payload.id);
            if (idx >= 0)
                state.data.payments.enot[idx] = action.payload
            else
                state.data.payments.enot.push(action.payload);
        },
        deleteEnot: (state, { payload: id }: PayloadAction<string>) => {
            state.data.payments.enot = state.data.payments.enot.filter(p => p.id !== id)
        },
        updatePrime: (state, action: PayloadAction<IPrimePaymentSettings>) => {
            const idx = state.data.payments.prime.findIndex(s => s.id === action.payload.id);
            if (idx >= 0)
                state.data.payments.prime[idx] = action.payload
            else
                state.data.payments.prime.push(action.payload);
        },
        deletePrime: (state, { payload: id }: PayloadAction<string>) => {
            state.data.payments.prime = state.data.payments.prime.filter(p => p.id !== id)
        },

        updateAntilopay: (state, action: PayloadAction<IAntilopaySettings>) => {
            const idx = state.data.payments.antilopay.findIndex(s => s.id === action.payload.id);
            if (idx >= 0)
                state.data.payments.antilopay[idx] = action.payload
            else
                state.data.payments.antilopay.push(action.payload);
        },
        deleteAntilopay: (state, { payload: id }: PayloadAction<string>) => {
            state.data.payments.antilopay = state.data.payments.antilopay.filter(p => p.id !== id)
        },

        updatePayPalych: (state, action: PayloadAction<IPayPalychSettings>) => {
            const idx = state.data.payments.payPalych.findIndex(s => s.id === action.payload.id);
            if (idx >= 0)
                state.data.payments.payPalych[idx] = action.payload
            else
                state.data.payments.payPalych.push(action.payload);
        },
        deletePayPalych: (state, { payload: id }: PayloadAction<string>) => {
            state.data.payments.payPalych = state.data.payments.payPalych.filter(p => p.id !== id)
        },

        updateStreamPay: (state, action: PayloadAction<IStreamPaySettings>) => {
            const idx = state.data.payments.streamPay.findIndex(s => s.id === action.payload.id);
            if (idx >= 0)
                state.data.payments.streamPay[idx] = action.payload
            else
                state.data.payments.streamPay.push(action.payload);
        },
        deleteStreamPay: (state, { payload: id }: PayloadAction<string>) => {
            state.data.payments.streamPay = state.data.payments.streamPay.filter(p => p.id !== id)
        }
    }
});

export const { reducer: projectsReducer } = projectsSlice;
const {
    setData,
    //setBuckets,
    //setStorages,
    //setPayments,
    setLoading,
    setError,
    updateProject,
    setSmtps,
    updateSmtp,
    updateLoginServer,
    updateGameServer,
    updateBucket,
    //setEndpoints,
    updateEndpont,

    deleteEnot,
    deletePrime,
    deleteUnitpay,
    deleteAntilopay,
    deletePayPalych,
    deleteStreamPay,

    updateEnot,
    updatePrime,
    updateUnitpay,
    updateAntilopay,
    updatePayPalych,
    updateStreamPay
} = projectsSlice.actions;

export const projectActions = {
    fetchAll: (silent: boolean = false) => async (dispatch: Dispatch) => {

        const projectApi = new ProjectsService();
        const paymentApi = new PaymentsService();

        if (!silent) {
            dispatch(setLoading());
        }

        try {
            const [data, buckets, smtps, endpoints, payments] = await Promise.all([
                projectApi.fetchProjectsAll(),
                projectApi.fetchBuckets(),
                projectApi.fetchSmtps(),
                projectApi.fetchEndpoints(),
                paymentApi.fetchPaymentsSettings()
            ]);

            const result: ProjectsHolder = {
                ...data,
                buckets,
                smtps,
                endpoints,
                payments
            };

            dispatch(setData(result));
            return result;
        }
        catch (e) {
            dispatch(setError(e));
            return undefined;
        }
    },
    addProject: (project: CreateProjectModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.putProject(project);
        if (result.ok && result.result) {
            dispatch(updateProject(result.result));
        }
        return result;
    },
    addLoginServer: (login: PutLoginServerModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.putLServer(login);
        if (result.ok && result.result) {
            dispatch(updateLoginServer(result.result));
        }
        return result;
    },
    addGameServer: (game: PutGameServerModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.putGServer(game);
        if (result.ok && result.result) {
            dispatch(updateGameServer(result.result));
        }
        return result;
    },
    addBucket: (bucket: PutConsumerBucketModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.putBucket(bucket);
        if (result.ok && result.result) {
            dispatch(updateBucket(result.result));
        }
        return result;
    },

    addSmtp: (smtp: PutSmtpSettings) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.putSmtp(smtp);
        if (result.ok && result.result) {
            dispatch(updateSmtp(result.result));
        }
        return result;
    },
    updateProject: (project: UpdateProjectModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.patchProject(project);
        if (result.ok && result.result) {
            dispatch(updateProject(result.result))
        }
        return result;
    },

    updateLoginServer: (login: PatchLoginServerModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.patchLServer(login);
        if (result.ok && result.result) {
            dispatch(updateLoginServer(result.result));
        }
        return result;
    },
    updateGameServer: (game: PatchGameServerModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.patchGServer(game);
        if (result.result) {
            dispatch(updateGameServer(result.result));
        }
        return result;
    },
    fetchSmtps: (silent: boolean = false) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();

        if (!silent)
            dispatch(setLoading());

        try {
            const smtps = await api.fetchSmtps();
            dispatch(setSmtps(smtps));
            return smtps;
        }
        catch (e) {
            if (!silent)
                dispatch(setError(e));
            return undefined;
        }
    },
    updateSmtp: (smtp: PatchSmtpSettings) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.patchSmtp(smtp);
        if (result.ok && result.result) {
            dispatch(updateSmtp(result.result));
        }
        return result;
    },
    updateEndpont: (endpoint: PatchBackendEndpointModel) => async (dispatch: Dispatch) => {
        const api = new ProjectsService();
        const result = await api.patchEndpoint(endpoint);
        if (result.ok && result.result) {
            dispatch(updateEndpont(result.result));
        }
        return result;
    }
}

export const paymentActions = {
    loadUnitpay: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.getUnitpaySettings(id);
        if (result.success) {
            dispatch(updateUnitpay(result.data));
        }
        return result;
    },
    addUnitpay: (model: PutUnitpayPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.putUnitpaySettings(model);
        if (result.success) {
            dispatch(updateUnitpay(result.data));
        }
        return result;
    },
    updateUnitpay: (unitpay: PatchUnitpayPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.patchUnitpaySettings(unitpay);
        if (result.success) {
            dispatch(updateUnitpay(result.data));
        }
        return result;
    },
    deleteUnitpay: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.deleteUnitpaySettings(id);
        if (result.ok) {
            dispatch(deleteUnitpay(id));
        }
        return result;
    },

    loadEnot: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.getEnotSettings(id);
        if (result.success) {
            dispatch(updateEnot(result.data));
        }
        return result;
    },
    addEnot: (model: PutEnotPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.putEnotSettings(model);
        if (result.success) {
            dispatch(updateEnot(result.data));
        }
        return result;
    },
    updateEnot: (unitpay: PatchEnotPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.patchEnotSettings(unitpay);
        if (result.success) {
            dispatch(updateEnot(result.data));
        }
        return result;
    },
    deleteEnot: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.deleteEnotSettings(id);
        if (result.ok) {
            dispatch(deleteEnot(id));
        }
        return result;
    },

    loadPrime: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.getPrimeSettings(id);
        if (result.success) {
            dispatch(updatePrime(result.data));
        }
        return result;
    },
    addPrime: (model: PutPrimePaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.putPrimeSettings(model);
        if (result.success) {
            dispatch(updatePrime(result.data));
        }
        return result;
    },
    updatePrime: (unitpay: PatchPrimePaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.patchPrimeSettings(unitpay);
        if (result.success) {
            dispatch(updatePrime(result.data));
        }
        return result;
    },
    deletePrime: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.deletePrimeSettings(id);
        if (result.ok) {
            dispatch(deletePrime(id));
        }
        return result;
    },

    loadPayPalych: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.getPayPalychSettings(id);
        if (result.success) {
            dispatch(updatePayPalych(result.data));
        }
        return result;
    },
    addPayPalych: (model: PutPayPalychPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.putPayPalychSettings(model);
        if (result.success) {
            dispatch(updatePayPalych(result.data));
        }
        return result;
    },
    updatePayPalych: (unitpay: PatchPayPalychPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.patchPayPalychSettings(unitpay);
        if (result.success) {
            dispatch(updatePayPalych(result.data));
        }
        return result;
    },
    deletePayPalych: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.deletePayPalychSettings(id);
        if (result.ok) {
            dispatch(deletePayPalych(id));
        }
        return result;
    },

    loadAntilopay: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.getAntilopaySettings(id);
        if (result.success) {
            dispatch(updateAntilopay(result.data));
        }
        return result;
    },
    addAntilopay: (model: PutAntilopayPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.putAntilopaySettings(model);
        if (result.success) {
            dispatch(updateAntilopay(result.data));
        }
        return result;
    },
    updateAntilopay: (model: PatchAntilopayPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.patchAntilopaySettings(model);
        if (result.success) {
            dispatch(updateAntilopay(result.data));
        }
        return result;
    },
    deleteAntilopay: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.deleteAntilopaySettings(id);
        if (result.ok) {
            dispatch(deleteAntilopay(id));
        }
        return result;
    },

    addStreamPay: (model: PutStreamPayPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.putStreamPaySettings(model);
        if (result.success) {
            dispatch(updateStreamPay(result.data));
        }
        return result;
    },
    updateStreamPay: (model: PatchStreamPayPaymentSettings) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.patchStreamPaySettings(model);
        if (result.success) {
            dispatch(updateStreamPay(result.data));
        }
        return result;
    },
    deleteStreamPay: (id: string) => async (dispatch: Dispatch) => {
        const api = new PaymentsService();
        const result = await api.deleteStreamPaySettings(id);
        if (result.ok) {
            dispatch(deleteStreamPay(id));
        }
        return result;
    },
}