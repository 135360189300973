import { MasterStorage } from "../../interfaces/master/storages";
import { PutMasterStorageModel, PatchMasterStorageModel } from "../../models/master/storages";
import ServiceBase from "../serviceBase";

export default class MasterStoragesService extends ServiceBase {
    async fetchStorages() {
        return await this.getAndThrowApi<MasterStorage[]>('/admin/master/storage/');
    }

    async putStorage(storage: PutMasterStorageModel) {
        return await this.putApi<MasterStorage>('/admin/master/storage/', storage);
    }

    async patchStorage(storage: PatchMasterStorageModel) {
        return await this.patchApi<MasterStorage>('/admin/master/storage/', storage);
    }
}