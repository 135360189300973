import {FC} from "react";
import {Card, CardHeader, CardTitle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import EnotSettingsForm from "./enotSettingsForm";
import PayPalychSettingsForm from "./payPalychSettingsForm";
import PrimePaymentsSettingsForm from "./primePaymentsSettingsForm";
import UnitpaySettingsForm from "./unitpaySettingsForm";
import AntilopaySettingsForm from "./antilopaySettingsForm";
import StreamPaySettingsForm from "./streamPaySettingsForm";

interface MatchProps {
    project_id: string;
    gateway: string;
    settings_id?: string;
}

const PaymentSettings: FC = () => {

    const params = useParams<MatchProps>();
    const history = useHistory();
    const goBack = () => {
        history.replace(`/p/${params.project_id}/ps/gw/${params.gateway}/`);
    }

    return (
        <Card>
            <CardHeader>
                <div className='card-actions float-start me-2'>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={goBack}/>
                </div>
                <CardTitle>
                    Настройки платежного шлюза
                </CardTitle>
            </CardHeader>
            <Switch>
                <Route path={`/p/:project_id/ps/gw/eio/:settings_id`}>
                    <EnotSettingsForm />
                </Route>
                <Route path={`/p/:project_id/ps/gw/ppl/:settings_id`}>
                    <PayPalychSettingsForm />
                </Route>
                <Route path={`/p/:project_id/ps/gw/pp/:settings_id`}>
                    <PrimePaymentsSettingsForm />
                </Route>
                <Route path={`/p/:project_id/ps/gw/up/:settings_id`}>
                    <UnitpaySettingsForm />
                </Route>
                <Route path={`/p/:project_id/ps/gw/anp/:settings_id`}>
                    <AntilopaySettingsForm />
                </Route>
                <Route path={`/p/:project_id/ps/gw/sp/:settings_id`}>
                    <StreamPaySettingsForm />
                </Route>
            </Switch>
        </Card>
    )
}

export default PaymentSettings;