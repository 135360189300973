import {FC, useCallback} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Spinner
} from "reactstrap";
import {EPaymentCurrency, IProjectSettings} from "@interfaces/consumer/payments";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useStateLoader} from "@hooks/loading.hooks";
import PaymentsService from "@api/consumer/paymentsService";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {toastr} from "react-redux-toastr";
import {withPage} from "@components/panel";
import * as Yup from 'yup';
import {currencies} from "../../../../utils/data";

const SettingsPage: FC = () => {

    const projectId = useCurrentProjectId();
    const loadSettings = useCallback(async (projectId: string) => {
        const api = new PaymentsService();
        return await api.getProjectSettings(projectId);
    }, []);

    const {
        loading,
        data: settings
    } = useStateLoader(loadSettings, projectId);

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    Настройки
                </CardTitle>
            </CardHeader>
            <CardBody>
                {loading && <Spinner size="sm" color='primary'/>}
                {settings && <SettingsForm settings={settings}/>}
            </CardBody>
        </Card>
    )
}

interface SettingsFormProps {
    settings: IProjectSettings;
}

const SettingsForm: FC<SettingsFormProps> = ({settings}) => {

    const validate = Yup.object().shape({
        defaultCurrency: Yup.string()
            .required('Обязательное поле'),
        donateMin: Yup.number()
            .required('Обязательное поле')
            .min(1, 'Минимальная сумма 1')
            .max(1000000, 'Максимальная сумма 1000000')
            .lessThan(Yup.ref('donateMax'), 'Минимальная сумма должна быть меньше максимальной'),
        donateMax: Yup.number()
            .required('Обязательное поле')
            .min(1, 'Минимальная сумма 1')
            .max(1000000, 'Максимальная сумма 1000000')
            .moreThan(Yup.ref('donateMin'), 'Максимальная сумма должна быть больше минимальной'),

    });

    const onSubmit = useCallback(async (values: IProjectSettings) => {
        const api = new PaymentsService();
        const result = await api.patchProjectSettings(values.projectId, {
            defaultCurrency: values.defaultCurrency,
            donateMin: values.donateMin,
            donateMax: values.donateMax
        });

        if(result.success){
            toastr.success('Настройки проекта', 'Настройки сохранены');
        }
        else {
            if(result.errorCode === 'ProjectNotFound') {
                toastr.error('Настройки проекта', 'Проект не найден');
            }
            else if(result.errorCode === 'SettingsNotFound') {
                toastr.error('Настройки проекта', 'Настройки проекта не найдены');
            }
            else {
                toastr.error('Настройки проекта', 'Неизвестная ошибка');
            }
        }
    }, []);

    return (
        <Formik initialValues={settings} onSubmit={onSubmit} validationSchema={validate}>
            {({isSubmitting, touched, errors}) => (
                <Form>
                    <FormGroup row>
                        <Label for="currency" sm='3'>Валюта по умолчанию</Label>
                        <Col sm='9'>
                            <Field
                                name="defaultCurrency"
                                id="defaultCurrency"
                                as={Input}
                                type="select"
                                invalid={Boolean(touched.defaultCurrency && errors.defaultCurrency)}>
                                {currencies.map(x => (
                                    <option key={x.value} value={x.value}>{x.label}</option>
                                ))}
                            </Field>
                            <ErrorMessage name="defaultCurrency" component={FormFeedback} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="donateMin" sm={3}>Минимальная сумма доната</Label>
                        <Col sm={9}>
                            <Field
                                name="donateMin"
                                id="donateMin"
                                as={Input}
                                type="number"
                                min={0}
                                max={1000000}
                                invalid={Boolean(touched.donateMin && errors.donateMin)}/>
                            <ErrorMessage name="donateMin" component={FormFeedback} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="donateMax" sm={3}>Максимальная сумма доната</Label>
                        <Col sm={9}>
                            <Field
                                name="donateMax"
                                id="donateMax"
                                as={Input}
                                type="number"
                                min={0}
                                max={1000000}
                                invalid={Boolean(touched.donateMax && errors.donateMax)}/>
                            <ErrorMessage name="donateMin" component={FormFeedback} />
                        </Col>
                    </FormGroup>
                    <div className="text-center mt-3">
                        <Button type="submit" color="primary" disabled={isSubmitting}>
                            {isSubmitting && <Spinner size="sm" color="red" />}
                            Сохранить
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default withPage(SettingsPage);