import React, { useCallback, useMemo } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { ChangePasswordModel } from '../../../models/user/data';
import UserService from '../../../services/user/userService';
import * as Yup from 'yup';
import { Card, CardHeader, CardTitle, CardBody, Button } from 'reactstrap';
import { FormikInput } from '../../panel/formik';

interface ChangePasswordFormValues {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
}

const ChangePassword: React.FC = () => {

    const onSubmit = useCallback(async (values: ChangePasswordFormValues, actions: FormikHelpers<ChangePasswordFormValues>) => {

        const data: ChangePasswordModel = {
            newPassword: values.newPassword,
            oldPassword: values.oldPassword
        }

        const api = new UserService();
        const result = await api.changePassword(data);

        if(result.ok) {
            const options: BasicToastrOptions = {
                timeOut: 10000,
                showCloseButton: true,
                progressBar: true,
    
            }
            toastr.success("Смена пароля", "Пароль успешно изменен", options);
            actions.resetForm();
        }
        else {
            actions.setFieldError('oldPassword', 'Неверный пароль');
        }
    }, []);

    const validation = useMemo(() => Yup.object().shape({
        oldPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(16, "Не более 16 символов")
            .required("Обязательно"),
        newPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(16, "Не более 16 символов")
            .required("Обязательно"),
        repeatPassword: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(16, "Не более 16 символов")
            .oneOf([Yup.ref("newPassword")], "Пароли не совпадают")
            .required("Обязательно"),
    }), []);

    const initial: ChangePasswordFormValues = {
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Смена пароля</CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initial}
                    validationSchema={validation}
                    onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Field
                                label="Текущий пароль"
                                type="password"
                                name="oldPassword"
                                placeholder="Ваш текущий пароль"
                                component={FormikInput} />
                            <Field
                                label="Новый пароль"
                                type="password"
                                name="newPassword"
                                placeholder="Желаемый новый пароль"
                                component={FormikInput} />
                            <Field
                                label="Подтверждение пароля"
                                type="password"
                                name="repeatPassword"
                                placeholder="Подтвердите новый пароль"
                                component={FormikInput} />
                            <div className="text-center mt-3">
                                <Button color="primary" size="lg" disabled={isSubmitting} type="submit">
                                    Сменить пароль
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default ChangePassword;