import {FC} from "react";
import {Card, CardBody, CardHeader, CardTitle, Spinner} from "reactstrap";
import {EditCurrencyForm} from "@components/consumer/payments/currencies";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {useProjectCurrencies} from "@selectors/consumer/currencies.selectors";
import {useHistory, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {withPage} from "@components/panel";

const EditCurrencyPage: FC = () => {

    const projectId = useCurrentProjectId();
    const history = useHistory();
    const {currency_id} = useParams<{currency_id: string}>();
    const {
        loading,
        data: currencies
    } = useProjectCurrencies(projectId);

    const currency = currencies?.find(c => c.id === currency_id);

    return (
        <Card>
            <CardHeader>
                <div className='card-actions float-start me-2'>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={history.goBack}/>
                </div>
                <CardTitle>
                    Редактирование валюты
                </CardTitle>
            </CardHeader>
            <CardBody>
                {loading && <Spinner size="sm" color='primary'/>}
                {currency && (
                    <EditCurrencyForm  currency={currency}/>)
                }
            </CardBody>
        </Card>
    )
}

export default withPage(EditCurrencyPage);