import { useSelector } from "react-redux";
import { IStore } from "../../redux";

/**
 * Url на аватар пользователя
 */
export function useUserAvatar() {
    return useSelector(({ user }: IStore) => user.info.avatar);
}

/**
 * Баланс пользователя
 */
export function useUserBalance() {
    return useSelector(({ user }: IStore) => user.info.balance);
}

/**
 * Имя пользователя
 */
export function useUserName() {
    return useSelector(({ user }: IStore) => user.info.userName);
}

/**
 * Список ролей пользователя
 */
export function useUserRoles() {
    return useSelector(({ user }: IStore) => user.info.roles);
}

/**
 * Принадлежит ли пользователь данной роли
 * @param role 
 */
export function useUserRole(role: string) {
    return useSelector(({ user }: IStore) => user.info.roles.includes(role));
}

/**
 * Уведомления пользователя
 */
export function useUserNotifications() {
    return useSelector(({ user }: IStore) => user.notifications);
}

/**
 * Количество непрочитанных уведомлений
 */
export function useUserUnreadedNotificationsCount() {
    return useSelector(({ user }: IStore) => user.notifications.filter(n => !n.isReaded).length);
}

export function useUserData() {
    return useSelector(({ user }: IStore) => user.info);
}