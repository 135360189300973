import { useDataLoading } from "@hooks/loading.hooks";
import { IStore } from "@store";
import { storagesActions } from "@store/consumer/storagesStore";
import { useSelector } from "react-redux";

export function useStorages() {
    const data = useSelector(({ consumer: { storages } }: IStore) => storages);
    return useDataLoading(storagesActions.fetchStorages, data);
}

export function useStorage(id: string) {
    return useSelector(({ consumer: { storages } }: IStore) => storages.data.find(s => s.id === id));
}