import {FC, useCallback, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {useUnitpays} from "@selectors/consumer/payments.selectors";
import {GatewaysList} from "@components/consumer/payments";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";
import {AddSettingsModal} from "@components/consumer/projects/payments";
import {paymentActions} from "@store/consumer/projectsStore";
import {useModal, usePromisedDispatch} from "../../../../utils/helpers";
import {PutUnitpayPaymentSettings} from "@models/consumer/payments";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";

const UnitpayList: FC = () => {

    const dispatch = usePromisedDispatch();
    const project_id = useCurrentProjectId();
    const gateways = useUnitpays(project_id);
    const {isOpened: isUOpened, toggle: toggleUnitpay} = useModal();
    const history = useHistory();

    const onSelect = useCallback((gatewayId: string) => {
        history.push(`/p/${project_id}/ps/gw/up/${gatewayId}/`);
    }, [history, project_id])

    const onDelete = useCallback((gatewayId: string) => dispatch(paymentActions.deleteUnitpay(gatewayId)),
        [dispatch]);

    const createUnitpay = useCallback((model: PutUnitpayPaymentSettings) => dispatch(paymentActions.addUnitpay(model)),
        [dispatch]);
    const nextUnitpayOrder = gateways.reduce((order, settings) => Math.max(order, settings.order + 1), 0);

    const initial = useMemo(() => {
        return {
            merchantId: 0
        }
    }, []);

    return (
        <>
            <GatewaysList
                title="Unitpay"
                gateways={gateways}
                onSelect={onSelect}
                onDelete={onDelete}
                onAdd={toggleUnitpay}
            />
            <AddSettingsModal
                isOpen={isUOpened}
                toggle={toggleUnitpay}
                projectId={project_id}
                nextOrder={nextUnitpayOrder}
                addInitial={initial}
                createSettings={createUnitpay}>
                <Field
                    label="ID проекта в Unitpay"
                    type="text"
                    name="merchantId"
                    placeholder="123456"
                    component={FormikRowInput}/>
            </AddSettingsModal>
        </>
    )
}

export default UnitpayList;