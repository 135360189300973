import React, {FC, useCallback} from "react";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {useParams} from "react-router-dom";
import {usePrime} from "@selectors/consumer/payments.selectors";
import {IPrimePaymentSettings} from "@interfaces/consumer/payments";
import {paymentActions} from "@store/consumer/projectsStore";
import {CardBody} from "reactstrap";
import SettingsForm from "./settingsForm";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";


const PrimePaymentsSettingsForm: FC = () => {
    const dispatch = usePromisedDispatch();
    const {settings_id} = useParams<{settings_id: string}>();
    const settings = usePrime(settings_id);

    const updatePrime = useCallback(async (settings: IPrimePaymentSettings) =>
        await dispatch(paymentActions.updatePrime(settings)), [dispatch]);

    if(!settings) {
        return (
            <CardBody>Настройки не найдены</CardBody>
        )
    }

    return (
        <SettingsForm settings={settings} updateSettings={updatePrime}>
            <Field
                label="ID проекта в PrimePayments"
                type="text"
                name="merchantId"
                placeholder="123456"
                disabled
                component={FormikRowInput} />
            <Field
                label="Публичный ключ"
                type="text"
                name="publicKey"
                placeholder="112233-a1b2c"
                component={FormikRowInput} />
            <Field
                label="Секретный ключ"
                type="text"
                name="secretKey"
                placeholder="123456"
                component={FormikRowInput} />
        </SettingsForm>
    )
}

export default PrimePaymentsSettingsForm;