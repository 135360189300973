import {shallowEqual, useSelector} from "react-redux";
import {IStore} from "@store";
import {useDataLoading} from "@hooks/loading.hooks";
import {currenciesActions} from "@store/consumer/currenciesStore";

export function useProjectCurrencies(projectId: string) {
    const currencies = useSelector((state: IStore) => state.consumer.currencies[projectId] || {
        loaded: false,
        loading: false,
        error: undefined,
        data: []
    }, shallowEqual);
    return useDataLoading(currenciesActions.loadCurrencies, currencies, projectId);
}