import React from 'react';
import { faPlus, faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Table, Col, Row } from 'reactstrap';
import { Project } from '../../../interfaces/consumer/projects';
import { fromUtcDate } from '../../../utils/helpers';
import AnimatedFontAwesomeIcon from '../../panel/animatedFontAwesomeIcon';
import { TableSpinner } from '../../panel';

interface ProjectListTableProps {
    projects: Project[];
    loading: boolean;
    onAddClick:() => void;
}

const ProjectListTable: React.FC<ProjectListTableProps> = ({ projects, onAddClick, loading }) => (
    <Card>
        <CardHeader>
            <div className="card-actions float-end">
                <AnimatedFontAwesomeIcon
                    icon={faPlus}
                    fixedWidth
                    className="align-middle"
                    onClick={onAddClick}
                    animate="spin"
                />
            </div>
            <CardTitle tag="h5">
                Список ваших проектов
            </CardTitle>
        </CardHeader>
        <Table className="table table-hover">
            <thead>
                <tr>
                    <th style={{ width: "40%" }}>Наименование</th>
                    <th style={{ width: "25%" }}>Адрес</th>
                    <th className="d-none d-md-table-cell" style={{ width: "25%" }}>
                        Дата создания
                    </th>
                    <th>Действия</th>
                </tr>
            </thead>
            <tbody>
                { loading ? <TableSpinner colSpan={4} className="mr-2" color="primary" /> :
                    projects.length > 0 ?
                    projects.map((p, idx) => (
                        <tr key={p.id}>
                            <td>
                                <Col>
                                    <Row>{p.name}</Row>
                                    <Row><small>{p.description}</small></Row>
                                </Col>
                            </td>
                            <td>{p.url}</td>
                            <td className="d-none d-md-table-cell">{fromUtcDate(p.createTime).format('lll')}</td>
                            <td className="table-action">
                                <Link to={`/projects/${p.id}`}>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        fixedWidth
                                        className="align-middle mr-1"
                                    />
                                </Link>
                                
                                <FontAwesomeIcon
                                    icon={faEye}
                                    fixedWidth
                                    className="align-middle"
                                />
                            </td>
                        </tr>
                    ))
                    : <tr><td align="center" colSpan={4}>Проектов пока нет</td></tr>
                }
            </tbody>
        </Table>
    </Card>
)

export default React.memo(ProjectListTable);