import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {KeyValueSet, Loadable} from "@interfaces/helpers";
import {ICurrency} from "@interfaces/consumer/payments";
import CurrenciesService from "@api/consumer/currenciesService";
import {PatchCurrencyModel, PutCurrencyModel} from "@models/consumer/currencies";
import {FetchResult} from "../../helpers";

const slice = createSlice({
    name: 'currencies',
    initialState: {} as KeyValueSet<Loadable<ICurrency[]>>,
    reducers: {
        setLoading: (state, {payload: projectId}:PayloadAction<string>) => {
            state[projectId] = {
                ...state[projectId],
                loading: true,
                loaded: false,
                error: null
            }
        },
        setLoaded: (state, {payload: {projectId, data}}:PayloadAction<{projectId: string, data: ICurrency[]}>) => {
            state[projectId] = {
                ...state[projectId],
                loading: false,
                loaded: true,
                error: null,
                data
            }
        },
        setError: (state, {payload: {projectId, error}}:PayloadAction<{projectId: string, error: any}>) => {
            state[projectId] = {
                loading: false,
                loaded: false,
                error: error,
                data: []
            }
        },
        updateCurrency: (state, {payload: currency}:PayloadAction<ICurrency>) => {
            const projectId = currency.projectId;
            state[projectId] = {
                ...state[projectId],
                data: state[projectId].data.map(item => item.id === currency.id ? currency : item)
            }
        },
        addCurrency: (state, {payload: currency}:PayloadAction<ICurrency>) => {
            const projectId = currency.projectId;
            state[projectId] = {
                ...state[projectId],
                data: [...state[projectId].data, currency]
            }
        },
        deleteCurrency: (state, {payload: {projectId, currencyId}}:PayloadAction<{projectId: string, currencyId: string}>) => {
            state[projectId] = {
                ...state[projectId],
                data: state[projectId].data.filter(item => item.id !== currencyId)
            }
        }
    }
});

const {actions: {
    setLoading,
    setLoaded,
    setError,
    updateCurrency,
    addCurrency,
    deleteCurrency
}} = slice;
export const {reducer: currenciesReducer} = slice;

export const currenciesActions = {
    loadCurrencies: (projectId: string) => async (dispatch: Dispatch) => {
        dispatch(setLoading(projectId));

        try {
            const api = new CurrenciesService();
            const data = await api.getCurrencies(projectId);

            dispatch(setLoaded({projectId, data}));
            return data;
        }
        catch (error) {
            dispatch(setError({projectId, error}));
            return [];
        }
    },
    addCurrency: (projectId: string, currency: PutCurrencyModel) => async (dispatch: Dispatch) => {
        try {
            const api = new CurrenciesService();
            const result = await api.putCurrency(projectId, currency);

            if(result.success) {
                dispatch(addCurrency(result.data));
            }
            return result;
        }
        catch (error) {
            dispatch(setError({projectId, error}));
            return error as FetchResult<ICurrency>;
        }
    },
    updateCurrency: (projectId: string, currency: PatchCurrencyModel) => async (dispatch: Dispatch) => {
        try {
            const api = new CurrenciesService();
            const result = await api.patchCurrency(projectId, currency);

            if(result.success) {
                dispatch(updateCurrency(result.data));
            }
            return result;
        }
        catch (error) {
            dispatch(setError({projectId, error}));
            return error as FetchResult<ICurrency>;
        }
    },
    deleteCurrency: (projectId: string, currencyId: string) => async (dispatch: Dispatch) => {
        try {
            const api = new CurrenciesService();
            const result = await api.deleteCurrency(projectId, currencyId);

            if(result.success) {
                dispatch(deleteCurrency({projectId, currencyId}));
            }
            return result;
        }
        catch (error) {
            dispatch(setError({projectId, error}));
            return error as FetchResult<ICurrency>;
        }
    }
}