import {FC} from "react";
import CurrencyForm, {CurrencyFormValues} from "@components/consumer/payments/currencies/currencyForm";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {toastr} from "react-redux-toastr";
import {EPaymentCurrency, ExchangeRateSource} from "@interfaces/consumer/payments";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {currenciesActions} from "@store/consumer/currenciesStore";
import {useHistory} from "react-router-dom";
import {FormikHelpers} from "formik";

const AddCurrencyForm: FC = () => {

    const history = useHistory();
    const dispatch = usePromisedDispatch();
    const projectId = useCurrentProjectId();
    const onSubmit = async (values: CurrencyFormValues, actions: FormikHelpers<CurrencyFormValues>) => {
        const result = await dispatch(currenciesActions.addCurrency(projectId, {
            currency: values.currency,
            isEnabled: values.isEnabled,
            isStatic: values.isStatic,
            source: !values.isStatic ? values.source : undefined,
            value: values.isStatic ? values.value : undefined,
            donateMin: values.donateMin,
            donateMax: values.donateMax
        }));

        if(result.success) {
            toastr.success("Настройки валют", "Валюта добавлена");
            history.replace(`/p/${projectId}/ps/cr/${result.data.id}/`);
        }
        else {
            if(result.errorCode === 'CurrencyAlreadyExists') {
                toastr.error("Настройки валют", "Валюта уже существует");
            }
            else if(result.errorCode === 'ProjectNotFound') {
                toastr.error("Настройки валют", "Проект не найден");
            }
            else if(result.errorCode === 'ValueRequired') {
                toastr.error("Настройки валют", "Не указано значение");
            }
            else if(result.errorCode === 'SourceRequired') {
                toastr.error("Настройки валют", "Не указан источник");
            }
            else if(result.errorCode === "DefaultCurrency") {
                actions.setFieldError('currency', 'Нельзя установить курс для валюты по умолчанию');
            }
            else {
                toastr.error("Настройки валют", "Неизвестная ошибка");
            }
        }
    }

    const initialValues: CurrencyFormValues = {
        currency: EPaymentCurrency.RUB,
        source: ExchangeRateSource.CBR,
        value: 100,
        isStatic: true,
        isEnabled: true,
        donateMin: 0,
        donateMax: 0
    }

    return (
        <CurrencyForm initialValues={initialValues} onSubmit={onSubmit} />
    )
}

export default AddCurrencyForm;