import {FC} from "react";
import {SmtpSettingsTab} from "@components/consumer/projects/tabs";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {withPage} from "@components/panel";

const SmtpSettingsPage: FC = () => {

    const projectId = useCurrentProjectId();

    return (
        <SmtpSettingsTab projectId={projectId} />
    )
}

export default withPage(SmtpSettingsPage);