import {FC} from "react";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {AddCurrencyForm} from "@components/consumer/payments/currencies";
import {withPage} from "@components/panel";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";

const AddCurrencyPage: FC = () => {

    const history = useHistory();
    return (
        <Card>
            <CardHeader>
                <div className='card-actions float-start me-2'>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={history.goBack}/>
                </div>
                <CardTitle>
                    Добавление валюты
                </CardTitle>
            </CardHeader>
            <CardBody>
                <AddCurrencyForm />
            </CardBody>
        </Card>
    )
}

export default withPage(AddCurrencyPage)