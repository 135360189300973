import { Formik, FormikHelpers, Form, Field } from 'formik';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody,  FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import { auth } from '../../../services/authService';
import { useRecaptchaPublic } from '../../../utils/hooks/panel.hooks';
import { FormikInput } from '../../panel/formik';

interface SignUpFormValues {
    username: string
    email: string,
    password: string,
    repeatPassword: string,
    rules: boolean
}

const SignUpPage: React.FC = () => {

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<string | null>(null);
    const recaptchaKey = useRecaptchaPublic();

    const initialValues: SignUpFormValues = {
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        rules: false,
    }

    const validate = useMemo(() => Yup.object().shape<SignUpFormValues>({
        username: Yup.string()
            .min(5, "Не менее 5 символов")
            .max(32, "Не более 32 символов")
            .required("Обязательно"),
        email: Yup.string()
            .email("Введите корректный e-mail")
            .required("Обязательно"),
        password: Yup.string()
            .min(6, "Не менее 6 символов")
            .max(64, "Не более 64 символов")
            .required("Обязательно")
            .matches(/[A-Z]/, "Должен содержать заглавные буквы")
            .matches(/[a-z]/, "Должен содержать прописные буквы")
            .matches(/[0-9]/, "Должен содержать цифры"),
        repeatPassword: Yup.string()
            .required()
            .oneOf([Yup.ref('password')], "Пароли не совпадают"),
        rules: Yup.boolean()
    }), [])

    const onSubmit = useCallback(async (values: SignUpFormValues, actions: FormikHelpers<SignUpFormValues>) => {
        if (!recaptcha) {
            actions.setFieldError("username", "ReCAPTCHA обязательна");
            return;
        }

        const res = await auth().signUp({
            userName: values.username,
            email: values.email,
            password: values.password,
            confirmPassword: values.repeatPassword,
            recaptcha
        });

        if (res.success) {
            const result = res.data.result;

            if (result === 'CheckMail') {
                toastr.success("Регистрация", "Аккаунт успешно зарегистрирован. Проверьте почту и подтвердите ваш email");
            }
            else if (result === 'SmtpError') {
                toastr.warning("Регистрация", "Аккаунт успешно разрегистрирован, но не удалось отправить сообщение на почту. Повторите попытку позднее.");
            }
            else {
                toastr.success("Регистрация", "Аккаунт успешно зарегистрирован.");
            }
        }
        else {
            if (res.errorCode === 'DuplicateUserName') {
                actions.setFieldError("username", "Имя уже занято");
            }
            else if (res.errorCode === 'DuplicateEmail') {
                actions.setFieldError("email", "Данная почта уже зарегистрирована");
            }
            else if(res.errorCode === 'InvalidUserName') {
                actions.setFieldError("username", "Некорректное имя пользователя");
            }
            else {
                toastr.error("Регистрация", "Что-то пошло не так");
            }
            recaptchaRef.current?.reset();
        }
    }, [recaptcha]);

    return (
        <>
            <div className="text-center mt-4">
                <p className="lead">
                    Регистрация нового аккаунта
                </p>
            </div>
            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validate}>
                            {
                                ({ isSubmitting, isValid }) => (
                                    <Form>
                                        <Field
                                            name="username"
                                            type="text"
                                            label="Имя пользователя"
                                            placeholder="Введите имя пользователя"
                                            component={FormikInput}
                                        />
                                        <Field
                                            name="email"
                                            type="email"
                                            label="Email"
                                            placeholder="Введите email"
                                            component={FormikInput}
                                        />
                                        <Field
                                            name="password"
                                            type="password"
                                            label="Пароль"
                                            placeholder="Введите пароль"
                                            component={FormikInput}
                                        />
                                        <Field
                                            name="repeatPassword"
                                            type="password"
                                            label="Подтверждение пароля"
                                            placeholder="Подтвердите пароль"
                                            component={FormikInput}
                                        />
                                        <FormGroup className="text-center">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={recaptchaKey}
                                                onChange={setRecaptcha}
                                                badge="inline"
                                                size="normal"
                                                {...{ style: { display: 'inline-block' } }}
                                            />
                                        </FormGroup>
                                        <small>
                                            <Link to="/signin/">Авторизация</Link>
                                            {' | '}
                                            <Link to="/forgot/">Восстановить пароль</Link>
                                        </small>
                                        <div className="text-center mt-3">
                                            <Button color="primary" size="lg" disabled={isSubmitting || !isValid} type="submit">
                                                Регистрация
                                            </Button>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default SignUpPage;