import {IPaymentSetting} from "@interfaces/consumer/payments";
import {PropsWithChildren, useCallback, useState} from "react";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {toastr} from "react-redux-toastr";
import {projectActions} from "@store/consumer/projectsStore";
import {Button, CardBody, Col, FormFeedback, FormGroup, Input, Label, Spinner} from "reactstrap";
import {FormikRowCheckbox, FormikRowInput} from "@components/panel/formik";
import {FetchResult} from "../../../../helpers";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {currencies} from "../../../../utils/data";

interface SettingsProps<T extends IPaymentSetting> {
    settings: T;
    updateSettings: (settings: T) => Promise<FetchResult>;
}

const components = {
    DropdownIndicator: null,
};

const PaymentSettingsForm = <T extends IPaymentSetting>({ settings, updateSettings, children }: PropsWithChildren<SettingsProps<T>>) => {

    const dispatch = usePromisedDispatch();
    const [inputValue, setInputValue] = useState('');

    const initial: T = {
        ...settings
    }

    const onSubmit = useCallback(async (values: T) => {

        const result = await updateSettings({
            ...settings,
            ...values
        });

        if (result.ok) {
            toastr.success("Настройки платежей", "Настройки сохранены");
        }
        else {
            if (result.errorCode === 'ProjectNotFound') {
                toastr.error("Настройки платежей", "Проект не найден.");
                await dispatch(projectActions.fetchAll(true));
            }
            else {
                toastr.error("Настройки платежей", "Что-то пошло не так.");
            }

        }
    }, [dispatch, settings, updateSettings]);

    return (
        <CardBody>
            <Formik
                initialValues={initial}
                onSubmit={onSubmit}>
                {
                    ({ isSubmitting, touched, values, errors, setFieldValue }) => (
                        <Form>
                            <Field
                                label="Название"
                                type="text"
                                name="name"
                                placeholder="Название платежки"
                                component={FormikRowInput} />
                            {children}
                            <Field
                                label="Активно"
                                name="isActive"
                                id={settings.id + 'acb'}
                                type="switch"
                                component={FormikRowCheckbox} />
                            <Field
                                label="Описание платежа"
                                type="text"
                                name="paymentDesc"
                                placeholder="Пополнение игрового баланса"
                                component={FormikRowInput} />
                            <Field
                                label="Месячный лимит"
                                type="number"
                                name="monthLimit"
                                placeholder="600000"
                                description="за месяц на этот кошелек будет принято доната не более данной суммы (0 для отключения)"
                                component={FormikRowInput} />
                            <Field
                                label="Минимальная сумма"
                                type="number"
                                name="donateMin"
                                placeholder="0"
                                description="Минимальная сумма пополнения (0 для отключения)"
                                component={FormikRowInput} />
                            <Field
                                label="Максимальная сумма"
                                type="number"
                                name="donateMax"
                                placeholder="0"
                                description="Максимальная сумма пополнения (0 для отключения)"
                                component={FormikRowInput} />
                            <Field
                                label="Не фиксировать сумму платежа"
                                name="canOverrideSum"
                                id={settings.id + 'cos'}
                                type="switch"
                                component={FormikRowCheckbox} />
                            <FormGroup row>
                                <Label for="tags" sm={3}>Теги</Label>
                                <Col sm={9}>
                                    <CreatableSelect
                                        components={components}
                                        inputValue={inputValue}
                                        isClearable
                                        isMulti
                                        menuIsOpen={false}
                                        onChange={(newValue) => setFieldValue('tags', newValue.map(x => x.value))}
                                        onInputChange={(newValue) => setInputValue(newValue)}
                                        onKeyDown={e => {
                                            if (!inputValue) return;
                                            switch (e.key) {
                                                case 'Tab':
                                                case 'Enter':
                                                    setFieldValue('tags', [...values.tags, inputValue]);
                                                    setInputValue('');
                                                    e.preventDefault();
                                                    break;
                                            }
                                        }}
                                        placeholder="Теги"
                                        value={values.tags.map(x => ({label: x, value: x }))}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="defaultCurrency" sm='3'>Валюта по умолчанию</Label>
                                <Col sm='9'>
                                    <Field
                                        name="defaultCurrency"
                                        id={settings.id + 'dc'}
                                        as={Input}
                                        type="select"
                                        invalid={Boolean(touched.defaultCurrency && errors.defaultCurrency)}>
                                        {currencies.map(x => (
                                            <option key={x.value} value={x.value}>{x.label}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="login" component={FormFeedback} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="currencies" sm={3}>Валюты</Label>
                                <Col sm={9}>
                                    <Select
                                        isClearable
                                        isMulti
                                        options={currencies}
                                        onChange={(newValue) => setFieldValue('currencies', newValue.map(x => x.value))}
                                        value={values.currencies.map(x => ({label: x, value: x}))}
                                        placeholder={'Валюты'}
                                    />
                                </Col>
                            </FormGroup>
                            <div className="text-center mt-3">
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    {isSubmitting && <Spinner size="sm" color="red" />}
                                    Сохранить
                                </Button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </CardBody>
    )
}

export default PaymentSettingsForm;