import { FieldProps } from 'formik';
import { memo } from 'react';
import { FormGroup, Label, FormFeedback, Col, Input, FormText } from 'reactstrap';

interface Props {
    description?: string;
    label: string;
    showValid?: boolean;
    id?: string;
    placeholder?: string;
}

const FormikRowCheckbox: React.FC<Props & FieldProps> = (prop: any) => {
    let {
        field: { ...fields },
        form: { touched, errors },
        description,
        label,
        id,
        placeholder,
        ...props
    } = prop;

    if (id === undefined) {
        id = fields.name;
    }

    return (
        <FormGroup row>
            <Label for={id} className={"label-color"} sm="3" check>{label}</Label>
            <Col sm="9">
                <Input checked={fields.value} id={id} label={placeholder} {...props} {...fields} invalid={Boolean(touched[fields.name] && errors[fields.name])} />
                {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
                {description ? <FormText>{description}</FormText> : ''}
            </Col>
        </FormGroup>
    );
}
export default memo(FormikRowCheckbox);