import { FormikCheckbox, FormikInput } from '@components/panel/formik';
import { IMasterProxy } from '@interfaces/master/proxies';
import { masterProxyActions } from '@store/master/proxyStore';
import { Field, Form, Formik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { FetchResult } from '../../../helpers';
import { usePromisedDispatch } from '../../../utils/helpers';

interface AddProxyModalProps {
    isOpen: boolean;
    toggle: () => void;
}

interface AddProxyModalValues {
    ownerId: string;
    projectId: string;
    isPublic: boolean;
    serverType: string;
    publicIP: string;
    privateIP: string;
    port: number;
    createNew: boolean;
}

const AddProxyModal: FC<AddProxyModalProps> = ({ isOpen, toggle }) => {

    const dispatch = usePromisedDispatch();

    const initial: AddProxyModalValues = {
        isPublic: true,
        ownerId: '',
        projectId: '',
        serverType: '',
        createNew: true,
        port: 3128,
        privateIP: '',
        publicIP: ''
    }

    const validation = useMemo(() => Yup.object().shape({
        isPublic: Yup.boolean()
            .required(),
        ownerId: Yup.string(),
        projectId: Yup.string(),
        serverType: Yup.string()
            .when("createNew", {
                is: true,
                then: Yup.string().required()
            }),
        publicIP: Yup.string()
            .when("createNew", {
                is: false,
                then: Yup.string().required()
            }),
        privateIP: Yup.string()
            .when("createNew", {
                is: false,
                then: Yup.string().required()
            }),
        port: Yup.number()
            .when("createNew", {
                is: false,
                then: Yup.number().required()
            })
    }), []);

    const onSubmit = useCallback(async (values: AddProxyModalValues) => {

        let result: FetchResult<IMasterProxy>;

        if(values.createNew) {
            result = await dispatch(masterProxyActions.createProxy({
                isPublic: values.isPublic,
                ownerId: values.ownerId || undefined,
                projectId: values.projectId || undefined,
                serverType: values.projectId || undefined
            }));
        }
        else {
            result = await dispatch(masterProxyActions.addProxy({
                isPublic: values.isPublic,
                ownerId: values.ownerId || undefined,
                projectId: values.projectId || undefined,
                publicIP: values.publicIP,
                privateIP: values.privateIP,
                port: values.port
            }));
        }

        if (result.success) {
            toastr.success('Создание прокси', 'Новый сервер успешно создан');
            toggle();
        }
        else {
            if (result.errorCode === 'APIException') {
                toastr.error('Создание прокси', 'Не удалось создать виртуальную машину');
            }
            else if (result.errorCode === 'Exception') {
                toastr.error('Создание прокси', 'Не удалось создать сервер');
            }
            else {
                toastr.error('Создание прокси', 'Что-то пошло не так');
            }
        }
    }, [dispatch, toggle]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Добавить прокси
            </ModalHeader>
            <Formik
                initialValues={initial}
                onSubmit={onSubmit}
                validation={validation}>
                {
                    ({ isSubmitting, isValid, values }) => (
                        <Form>
                            <ModalBody>
                                <Field
                                    label="ID клиента"
                                    type="text"
                                    name="ownerId"
                                    placeholder="Введите ID клиента"
                                    component={FormikInput} />
                                <Field
                                    label="ID проекта"
                                    type="text"
                                    name="projectId"
                                    placeholder="Введите ID проекта"
                                    description="Зарезервировать прокси за конкретным проектом"
                                    component={FormikInput} />
                                <Field
                                    id="is-public-switch"
                                    label="Публичный сервер"
                                    name="isPublic"
                                    type="switch"
                                    description="Публичные сервера выделяются проектам без купленного прокси"
                                    component={FormikCheckbox} />
                                <Field
                                    id="create-new-switch"
                                    label="Создать новый"
                                    name="createNew"
                                    type="switch"
                                    description="Публичные сервера выделяются проектам без купленного прокси"
                                    component={FormikCheckbox} />
                                {
                                    values.createNew && (
                                        <Field
                                            label="Тип сервера"
                                            type="text"
                                            name="serverType"
                                            placeholder="Введите тип сервера"
                                            component={FormikInput} />
                                    )
                                }
                                {
                                    !values.createNew && (
                                        <>
                                            <Field
                                                label="Публичный IP"
                                                type="text"
                                                name="publicIP"
                                                placeholder="Введите публичный IP"
                                                component={FormikInput} />
                                            <Field
                                                label="Локальный IP"
                                                type="text"
                                                name="privateIP"
                                                placeholder="Введите локальный IP"
                                                component={FormikInput} />
                                            <Field
                                                label="Порт"
                                                type="number"
                                                name="port"
                                                placeholder="Введите порт"
                                                component={FormikInput} />
                                        </>
                                    )
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" type="button" onClick={toggle}>
                                    Закрыть
                                </Button>{" "}
                                <Button type="submit" color="primary" disabled={isSubmitting || !isValid}>
                                    Создать
                                </Button>
                            </ModalFooter>
                        </Form>
                    )
                }
            </Formik>
        </Modal>
    )
}

export default AddProxyModal;