import { FC, memo, useCallback, useMemo } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { useBackendEndpoint } from '../../../../utils/selectors/consumer/projects.selectors';
import * as Yup from 'yup';
import { usePromisedDispatch } from '../../../../utils/helpers';
import { projectActions } from '../../../../redux/consumer/projectsStore';
import { toastr } from 'react-redux-toastr';
import { Field, Form, Formik } from "formik";
import { FormikRowInput } from "../../../panel/formik";

interface EndpointSettingsTabProps {
    serverId: string;
    projectId: string;
}

interface EndpointSettingsTabForm {
    url: string;
    secret: string;
    version: string;
}

const EndpointSettingsTab: FC<EndpointSettingsTabProps> = ({ serverId, projectId }) => {

    const dispatch = usePromisedDispatch();
    const endpoint = useBackendEndpoint(projectId, serverId);

    const initial: EndpointSettingsTabForm = {
        url: endpoint?.url || '',
        secret: endpoint?.secret || '',
        version: endpoint?.version || ''
    }

    const validate = useMemo(() => Yup.object().shape({
        url: Yup.string()
            .url()
            .required(),
        secret: Yup.string()
            .length(32)
            .required()
    }), []);

    const onSubmit = useCallback(async ({ url, secret }: EndpointSettingsTabForm) => {
        const result = await dispatch(projectActions.updateEndpont({
            projectId,
            serverId,
            url,
            secret
        }));

        if (result.ok) {
            toastr.success('Настройка подключения', 'Подключение успешно обновлено');
        }
        else if (result.errorCode === 'EndpointUnavailable') {
            toastr.error('Настройка подключения', 'Url недоступен');
        }
        else if(result.errorCode){
            toastr.error('Настройка подключения', result.errorCode);
        }
    }, [dispatch, projectId, serverId]);

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    Настройка подключения
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initial}
                    validationSchema={validate}
                    onSubmit={onSubmit}>
                    {
                        ({ isSubmitting }) => (
                            <Form>
                                <Field
                                    label="Url"
                                    type="text"
                                    name="url"
                                    placeholder="Url обработчика сервера"
                                    component={FormikRowInput} />
                                <Field
                                    label="Secret"
                                    type="text"
                                    name="secret"
                                    placeholder="Секретный ключ"
                                    component={FormikRowInput} />
                                <Field
                                    label="Версия"
                                    type="text"
                                    name="version"
                                    placeholder="Используемая версия разработчика"
                                    disabled
                                    component={FormikRowInput} />
                                <div className="text-center mt-3">
                                    <Button type="submit" color="primary" disabled={isSubmitting}>
                                        Сохранить
                                    </Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </CardBody>
        </Card>
    )
}

export default memo(EndpointSettingsTab);