import React, {FC, useCallback} from "react";
import {IUnitpayPaymentSettings} from "@interfaces/consumer/payments";
import {paymentActions} from "@store/consumer/projectsStore";
import {usePromisedDispatch} from "../../../../utils/helpers";
import {useParams} from "react-router-dom";
import {useUnitpay} from "@selectors/consumer/payments.selectors";
import SettingsForm from "@components/consumer/payments/settings/settingsForm";
import {CardBody} from "reactstrap";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";

const UnitpaySettingsForm: FC = () => {
    const dispatch = usePromisedDispatch();
    const {settings_id} = useParams<{settings_id: string}>();
    const unitpay = useUnitpay(settings_id);

    const updateUnitpay = useCallback(async (settings: IUnitpayPaymentSettings) =>
        await dispatch(paymentActions.updateUnitpay(settings)), [dispatch]);

    if(!unitpay) {
        return (
            <CardBody>Настройки не найдены</CardBody>
        )
    }

    return (
        <SettingsForm settings={unitpay} updateSettings={updateUnitpay}>
            <Field
                label="ID проекта в Unitpay"
                type="text"
                name="merchantId"
                placeholder="123456"
                disabled
                component={FormikRowInput} />
        </SettingsForm>
    )
}

export default UnitpaySettingsForm;