import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface AnimatedFontAwesomeIconProps {
    animate?: "spin" | "pulse"
}

const AnimatedFontAwesomeIcon: React.FC<AnimatedFontAwesomeIconProps & FontAwesomeIconProps> = ({ animate = "spin", ...faProps }) => {

    const [hovered, setHovered] = useState(false);

    return (
        <FontAwesomeIcon
            {...faProps}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            spin={animate === "spin" && hovered}
            pulse={animate === "pulse" && hovered} />
    )
}

export default AnimatedFontAwesomeIcon;