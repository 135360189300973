import React, { useCallback, useMemo } from 'react';
import { FormikHelpers, Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Card, CardHeader, CardTitle, CardBody, Button, FormGroup, Label } from 'reactstrap';
import { LServer } from '../../../../interfaces/consumer/projects';
import { PatchLoginServerModel } from '../../../../models/consumer/projects';
import { FormikRowInput, FormikRowCheckbox } from '../../../panel/formik';
import { toPromise } from '../../../../utils/helpers';
import { projectActions } from '../../../../redux/consumer/projectsStore';
import * as Yup from 'yup';

interface LoginServerSettingsTabProps {
    server: LServer;
}

interface LoginServerSettingsTabForm {
    name: string;
    maxAccounts: number;
    isActive: boolean;
    canCharge: boolean;
    chargePower: number;
    accountPattern: string;
    autoCreateAccount: boolean;
}

const LoginServerSettingsTab: React.FC<LoginServerSettingsTabProps> = ({ server }) => {

    const dispatch = useDispatch();

    const onSubmit = useCallback(async (values: LoginServerSettingsTabForm, actions: FormikHelpers<LoginServerSettingsTabForm>) => {

        const login: PatchLoginServerModel = {
            id: server.id,
            projectId: server.projectId,
            name: values.name,
            maxAccounts: values.maxAccounts,
            isActive: values.isActive,
            canCharge: values.canCharge,
            chargePower: values.chargePower,
            accountPattern: values.accountPattern,
            autoCreateAccount: values.autoCreateAccount
        }

        const result = await toPromise(dispatch(projectActions.updateLoginServer(login)))

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true,
        }

        if (result.ok) {
            toastr.success("Настройки сервера авторизации", "Настройки сохранены", options);
        }
        else {
            toastr.error("Настройки сервера авторизации", "Ошибка запроса. Обновите страницу", options);
        }
    }, [dispatch, server.id, server.projectId]);

    const validation = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .min(6)
            .max(64)
            .required(),
        maxAccounts: Yup.number()
            .min(0)
            .max(64)
            .required(),
        chargePower: Yup.number()
            .min(0)
            .max(10000000)
            .required(),
        accountPattern: Yup.string()
            .required()
    }), []);

    const initial: LoginServerSettingsTabForm = {
        name: server.name || '',
        maxAccounts: server.maxAccounts,
        isActive: server.isActive,
        canCharge: server.canCharge,
        chargePower: server.chargePower,
        accountPattern: server.accountPattern,
        autoCreateAccount: server.autoCreateAccount
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Настройки сервера
                    </CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initial}
                    validationSchema={validation}
                    onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup row>
                                <Label sm="3">ID сервера</Label>
                                <Label sm="9">{server.id}</Label>
                            </FormGroup>
                            <Field
                                label="Название сервера"
                                type="text"
                                name="name"
                                placeholder="Введите название сервера"
                                component={FormikRowInput} />
                            <Field
                                label="Максимальное количество аккаунтов"
                                type="number"
                                name="maxAccounts"
                                placeholder="Введите максимальное количество аккаунтов на этом сервере"
                                component={FormikRowInput} />
                            <Field
                                label="Автосоздание аккаунта"
                                type="switch"
                                name="autoCreateAccount"
                                placeholder="На этом логине будет автоматически создан аккаунт при регистрации в лк"
                                component={FormikRowCheckbox} />
                            <Field
                                label="Активен"
                                name="isActive"
                                type="switch"
                                description="Неактивный сервер не отображается в личном кабинете"
                                component={FormikRowCheckbox} />
                            <Field
                                label="Пополнение баланса"
                                name="canCharge"
                                type="switch"
                                description="Доступен ли перевод средство на аккаунт (запрос в бд логина)"
                                component={FormikRowCheckbox} />
                            <Field
                                label="Коэффициент пополнения"
                                type="number"
                                name="chargePower"
                                description="Списанная с баланса сумма умножается на этот коэффициент"
                                component={FormikRowInput} />
                            <Field
                                label="Паттерн имен аккаунтов"
                                type="text"
                                name="accountPattern"
                                placeholder="/^[A-Z0-9]{4,16}+$/i"
                                description="Regexp паттерн допустимого имени аккаунта"
                                component={FormikRowInput} />
                            <div className="text-center mt-3">
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    Сохранить
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )

}

export default LoginServerSettingsTab;