import {
    IBucket,
    DataProvider,
    GServer,
    LServer,
    Project,
    ProjectsHolder,
    ISmtpSettings,
    SmtpSettingsCheck,
    IBackendEndpoint,
} from '@interfaces/consumer/projects';
import { CreateProjectModel, PatchBackendEndpointModel, PatchGameServerModel, PatchLoginServerModel, PatchSmtpSettings, PutGameServerModel, PutLoginServerModel, PutSmtpSettings, UpdateProjectModel } from "@models/consumer/projects";
import { PutConsumerBucketModel } from "@models/consumer/storages";
import ServiceBase from "../serviceBase";

export default class ProjectsService extends ServiceBase {

    async fetchProjectsAll() {
        return await this.getAndThrowApi<ProjectsHolder>('/admin/projects/all/');
    }

    async putProject(project: CreateProjectModel) {
        return await this.putApi<Project>('/admin/projects/', project);
    }

    async putGServer(server: PutGameServerModel) {
        return await this.putApi<GServer>('/admin/projects/gs/', server);
    }

    async putLServer(server: PutLoginServerModel) {
        return await this.putApi<LServer>('/admin/projects/ls/', server);
    }

    async patchProject(project: UpdateProjectModel) {
        return await this.patchApi<Project>('/admin/projects/', project);
    }

    async patchGServer(server: PatchGameServerModel) {
        return await this.patchApi<GServer>('/admin/projects/gs/', server);
    }

    async patchLServer(server: PatchLoginServerModel) {
        return await this.patchApi<LServer>('/admin/projects/ls/', server);
    }

    async patchSmtp(smtp: PatchSmtpSettings) {
        return await this.patchApi<ISmtpSettings>('/admin/projects/smtp/', smtp);
    }

    async putSmtp(smtp: PutSmtpSettings) {
        return await this.putApi<ISmtpSettings>('/admin/projects/smtp/', smtp);
    }

    async fetchProjects(): Promise<Project[]> {
        return await this.getAndThrowApi<Project[]>('/admin/projects/');
    }

    async fetchProject(id: string) {
        return await this.getAndThrowApi<Project>(`/admin/projects/${id}`);
    }

    async fetchSmtps(): Promise<ISmtpSettings[]> {
        return await this.getAndThrowApi<ISmtpSettings[]>('/admin/projects/smtp/');
    }

    async fetchSmtp(id: number) {
        return await this.getAndThrowApi<ISmtpSettings>(`/admin/projects/smtp/${id}/`);
    }

    async fetchGs(): Promise<GServer[]> {
        return await this.getAndThrowApi<GServer[]>('/admin/projects/gs/');
    }

    async fetchLs(): Promise<LServer[]> {
        return await this.getAndThrowApi<LServer[]>('/admin/projects/ls/');
    }

    async fetchProviders(): Promise<DataProvider[]> {
        return await this.getAndThrowApi('/admin/projects/providers/');
    }

    async testConsumerSmtp(check: SmtpSettingsCheck) {
        return await this.postApi('/admin/projects/smtp/', check);
    }

    async putBucket(bucket: PutConsumerBucketModel) {
        return await this.putApi<IBucket>('/admin/projects/bucket/', bucket);
    }

    async fetchBuckets() {
        return await this.getAndThrowApi<IBucket[]>('/admin/projects/bucket/');
    }

    async fetchEndpoints() {
        return await this.getAndThrowApi<IBackendEndpoint[]>('/admin/projects/endpoint/');
    }

    async patchEndpoint(endpoint: PatchBackendEndpointModel) {
        return await this.patchApi<IBackendEndpoint>('/admin/projects/endpoint/', endpoint);
    }
}