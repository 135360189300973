import React, { useCallback, useMemo, useState } from 'react';
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Table } from 'reactstrap';
import { GServer, Project } from '@interfaces/consumer/projects';
import { useProjects } from '@selectors/consumer/projects.selectors';
import { AnimatedFontAwesomeIcon } from '../../panel';
import { AddGameServerModal } from './modals';

interface GameServerListTableProps {
    project: Project;
}

const GameServerListTable: React.FC<GameServerListTableProps> = ({ project }) => {

    const { data: { gameServers } } = useProjects();
    const [isModalOpen, setModalOpen] = useState(false);
    const servers = useMemo(() => gameServers.filter((gs) => gs.projectId === project.id), [gameServers, project.id]);
    const toggleModal = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen]);

    const childs = useMemo(() => servers.length > 0 ? servers.map((server) =>
        (
            <TableItem server={server} key={server.id} />
        )) : (
            <tr key="noservers">
                <td align="center" colSpan={2}>Серверов еще нет</td>
            </tr>
        ), [servers]);

    return (
        <Card>
            <TableList toggleModal={toggleModal}>
                {childs}
            </TableList>
            <AddGameServerModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                project={project} />
        </Card>
    )
}

interface ListProps {
    toggleModal: () => void;
}

const TableList: React.FC<ListProps> = React.memo(({ children, toggleModal }) => (
    <>
        <CardHeader>
            <div className="card-actions float-end">
                <AnimatedFontAwesomeIcon
                    icon={faPlus}
                    fixedWidth
                    className="align-middle"
                    onClick={toggleModal}
                    animate="spin"
                />
            </div>
            <CardTitle tag="h5">
                Список игровых серверов
            </CardTitle>
        </CardHeader>
        <Table className="table table-hover">
            <thead>
                <tr>
                    <th style={{ width: "90%" }}>Наименование</th>
                    <th>Действия</th>
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </Table>
    </>
))

interface ItemProps {
    server: GServer;
}

const TableItem: React.FC<ItemProps> = React.memo(({ server }) => (
    <tr key={server.id}>
        <td>{server.name}</td>
        <td>
            <Link to={`/p/${server.projectId}/s/g/${server.id}/`}>
                <FontAwesomeIcon
                    icon={faPen}
                    fixedWidth
                    className="align-middle mr-1"
                />
            </Link>
        </td>
    </tr>
))

export default GameServerListTable;