import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, Spinner, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useBreadcrumbs } from '@hooks/panel.hooks';
import { useProjects } from '@selectors/consumer/projects.selectors';
import { GameServerSettingsTab, EndpointSettingsTab } from '../../../../consumer/projects/tabs';
import { withPage } from '../../../../panel';
import classNames from 'classnames';

interface EditGameServerPagePath {
    server_id: string;
}

const EditGameServerPage: React.FC = () => {

    const [currentTab, openTab] = useState(0);
    const { server_id } = useParams<EditGameServerPagePath>();
    const { loading, data: { gameServers, projects } } = useProjects();

    const server = useMemo(() => gameServers.find(g => g.id.toString() === server_id), [gameServers, server_id]);

    const project = useMemo(() => server && projects.find(p => p.id === server.projectId), [projects, server]);

    useBreadcrumbs('admin.consumer.project.game.name', true, server?.name);
    useBreadcrumbs('admin.consumer.project.name', false, project?.name, `/p/${project?.id}/`);

    if (!server) {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        Настройки игрового сервера
                    </CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                    {loading ? <Spinner style={{ width: '3rem', height: '3rem' }} /> : 'Сервер не найден'}
                </CardBody>
            </Card>
        )
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    Настройки игрового сервера
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: currentTab === 0 })}
                            onClick={() => openTab(0)}>
                            Основные
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: currentTab === 1 })}
                            onClick={() => openTab(1)}>
                            Подключение
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={currentTab}>
                    <TabPane tabId={0}>
                        <GameServerSettingsTab server={server} />
                    </TabPane>
                    <TabPane tabId={1}>
                        <EndpointSettingsTab
                            projectId={server.projectId}
                            serverId={server.id} />
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    )

}

export default withPage(EditGameServerPage);