import React, { useCallback } from 'react';
import { FormikHelpers, Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Card, CardHeader, CardTitle, CardBody, Button } from 'reactstrap';
import { userActions } from '../../../redux/user/userStore';
import { toPromise } from '../../../utils/helpers';
import { useUserData } from '../../../utils/selectors/user.selectors';
import { FormikInput } from '../../panel/formik';

interface UserInfoForm {
    userName: string;
    publicName: string;
    email: string;
}

const UserInfo: React.FC = () => {

    const { userName, publicName, email } = useUserData();
    const dispatch = useDispatch();

    const initial: UserInfoForm = {
        userName,
        publicName,
        email
    }

    const onSubmit = useCallback(async (values: UserInfoForm, actions: FormikHelpers<UserInfoForm>) => {

        const result = await toPromise(dispatch(userActions.editUserData({ publicName: values.publicName })));

        if (result.ok) {
            toastr.success("Данные пользователя", "Данные успешно обновлены");
        }
        else {
            toastr.error("Данные пользователя", "Ошибка обновления данных. Обновите страницу и попробуйте еще раз");
        }
    }, [dispatch]);
    
    return (
        <Card>
            <CardHeader>
                <CardTitle>Ваш аккаунт</CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initial}
                    onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Field
                                label="Ваш логин"
                                type="text"
                                name="userName"
                                disabled
                                component={FormikInput} />
                            <Field
                                label="Ваша почта"
                                type="email"
                                name="email"
                                disabled
                                component={FormikInput} />
                            <Field
                                label="Публичное имя"
                                type="text"
                                name="publicName"
                                component={FormikInput} />
                            <div className="text-center mt-3">
                                <Button color="primary" size="lg" disabled={isSubmitting} type="submit">
                                    Сохранить
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default UserInfo;