import React, { useCallback, useMemo } from 'react';
import { FormikHelpers, Formik, Field, Form, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Col, Input, FormFeedback, Button } from 'reactstrap';
import { GServer } from '../../../../interfaces/consumer/projects';
import { PatchGameServerModel } from '../../../../models/consumer/projects';
import { FormikRowInput, FormikRowCheckbox } from '../../../panel/formik';
import * as Yup from 'yup';
import { useProjects } from '../../../../utils/selectors/consumer/projects.selectors';
import { toPromise } from '../../../../utils/helpers';
import { projectActions } from '../../../../redux/consumer/projectsStore';

interface GameServerSettingsTabProps {
    server: GServer;
}

interface GameServerSettingsTabForm {
    name: string;
    description: string;
    login: string;
    isActive: boolean;
    canCharge: boolean;
    chargePower: number;
}

const GameServerSettingsTab: React.FC<GameServerSettingsTabProps> = ({ server }) => {

    const dispatch = useDispatch();
    const { data: { loginServers } } = useProjects();
    const logins = useMemo(() => loginServers.filter((ls) => ls.projectId === server.projectId), [loginServers, server.projectId]);

    const onSubmit = useCallback(async (values: GameServerSettingsTabForm, actions: FormikHelpers<GameServerSettingsTabForm>) => {

        const game: PatchGameServerModel = {
            id: server.id,
            projectId: server.projectId,
            description: values.description,
            name: values.name,
            loginId: values.login,
            isActive: values.isActive,
            canCharge: values.canCharge,
            chargePower: values.chargePower
        }

        const result = await toPromise(dispatch(projectActions.updateGameServer(game)));

        const options: BasicToastrOptions = {
            timeOut: 10000,
            showCloseButton: true,
            progressBar: true,
        }

        if (result.ok) {
            toastr.success("Настройки игрового сервера", "Настройки сохранены", options);
        }
        else {
            toastr.error("Настройки игрового сервера", "Ошибка запроса. Обновите страницу", options);
        }
    }, [dispatch, server.id, server.projectId]);

    const validation = useMemo(() => Yup.object().shape({
        name: Yup.string()
            .min(6)
            .max(64)
            .required(),
        description: Yup.string()
            .max(256)
            .notRequired(),
        login: Yup.string()
            .required(),
        chargePower: Yup.number()
            .min(0)
            .max(10000000)
            .required()
    }), []);

    const initial: GameServerSettingsTabForm = {
        name: server.name || '',
        description: server.description || '',
        login: server.loginId || '',
        isActive: server.isActive,
        canCharge: server.canCharge,
        chargePower: server.chargePower
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    Настройки сервера
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initial}
                    validationSchema={validation}
                    onSubmit={onSubmit}>
                    {({ isSubmitting, values, handleChange, setFieldValue, errors, touched, setFieldTouched }) => (
                        <Form>
                            <FormGroup row>
                                <Label sm="3">ID сервера</Label>
                                <Label sm="9">{server.id}</Label>
                            </FormGroup>
                            <Field
                                label="Название сервера"
                                type="text"
                                name="name"
                                placeholder="Введите название сервера"
                                component={FormikRowInput} />
                            <Field
                                label="Описание сервера"
                                type="text"
                                name="description"
                                placeholder="Введите описание сервера (необязательно)"
                                component={FormikRowInput} />
                            <Field
                                label="Активен"
                                name="isActive"
                                type="switch"
                                description="Неактивный сервер не отображается в личном кабинете"
                                component={FormikRowCheckbox} />
                            <Field
                                label="Пополнение баланса"
                                name="canCharge"
                                type="switch"
                                description="Доступен ли перевод средство на аккаунт (запрос в бд гейма)"
                                component={FormikRowCheckbox} />
                            <Field
                                label="Коэффициент пополнения"
                                type="number"
                                name="chargePower"
                                placeholder="Списанная с баланса сумма умножается на этот коэффициент"
                                component={FormikRowInput} />
                            <FormGroup row>
                                <Label for="login" className={"label-color"} sm="3">Сервер авторизации</Label>
                                <Col sm="9">
                                    <Field
                                        name="login"
                                        id="login"
                                        as={Input}
                                        type="select"
                                        invalid={Boolean(touched.login && errors.login)}>
                                        <option value={''} key={'def'}>Выберите сервер авторизации</option>
                                        {logins.map((ls) => <option value={ls.id} key={ls.id}>{ls.name}</option>)}
                                    </Field>
                                    <ErrorMessage name="login" component={FormFeedback} />
                                </Col>
                            </FormGroup>
                            <div className="text-center mt-3">
                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                    Сохранить
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default GameServerSettingsTab;