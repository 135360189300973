
export interface IPaymentSetting {
    id: string;
    projectId: string;
    name: string;
    order: number;
    monthLimit: number;
    isActive: boolean;
    paymentDesc: string;
    defaultCurrency: EPaymentCurrency;
    currencies: EPaymentCurrency[];
    tags: string[];
    donateMin: number;
    donateMax: number;
    canOverrideSum: boolean;
}

export interface IPaymentSettings {
    unitpay: IUnitpayPaymentSettings[];
    prime: IPrimePaymentSettings[];
    enot: IEnotPaymentSettings[];
    payPalych: IPayPalychSettings[];
    antilopay: IAntilopaySettings[];
    streamPay: IStreamPaySettings[];
}

export interface IUnitpayPaymentSettings extends IPaymentSetting {
    merchantId: number;
    publicKey: string;
    secretKey: string;
}

export interface IEnotPaymentSettings extends IPaymentSetting {
    merchantId: number;
    publicKey: string;
    secretKey: string;
}

export interface IPrimePaymentSettings extends IPaymentSetting {
    merchantId: number;
    publicKey: string;
    secretKey: string;
}

export interface IPayPalychSettings extends IPaymentSetting {
    merchantId: string;
    linkName: string;
    apiKey: string;
}

export interface IAntilopaySettings extends IPaymentSetting {
    merchantId: string;
    projectIdentificator: string;
    productName: string;
    publicKey: string;
    secretKey: string;
}

export interface IStreamPaySettings extends IPaymentSetting {
    merchantId: string;
    publicKey: string;
    privateKey: string;
}

export enum EPaymentCurrency {
    RUB = 'RUB',
    EUR = 'EUR',
    USD = 'USD',
    BYN = 'BYN',
    KZT = 'KZT',
    UAH = 'UAH'
}

export enum ExchangeRateSource {
    CBR = 'CBR'
}

export interface ICurrency {
    id: string;
    projectId: string;
    currency: EPaymentCurrency;
    isEnabled: boolean;
    isStatic: boolean;
    value: number;
    source?: ExchangeRateSource;
    donateMin: number;
    donateMax: number;
}

export interface IProjectSettings {
    projectId: string;
    defaultCurrency: EPaymentCurrency;
    donateMin: number;
    donateMax: number;
}