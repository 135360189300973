import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { panelReducer, PanelStore } from "./panel/panelStore";
import { authReducer, AuthStore } from "./panel/authStore";
import { reducer as toastr, ToastrState } from "react-redux-toastr";
import thunk from "redux-thunk";
import { userReducer, UserStore } from "./user/userStore";
import { masterReducer, MasterStore } from "./master";
import { consumerReducer, ConsumerStore } from "./consumer";

export interface IStore {
    panel: PanelStore;
    auth: AuthStore;
    toastr: ToastrState;
    user: UserStore;
    master: MasterStore;
    consumer: ConsumerStore;
}

const rootReducer = combineReducers<IStore>({
    panel: panelReducer,
    auth: authReducer,
    toastr: toastr,
    user: userReducer,
    master: masterReducer,
    consumer: consumerReducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk]
});