import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../../redux/panel/authStore';
import { auth } from '../../../services/authService';

const SignOutPage: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        auth().signOut();
        dispatch(authActions.setAuthed(false));
        history.replace('/signin/');
    });

    return null;
}

export default SignOutPage;