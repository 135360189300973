import {FC} from "react";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {ApiTokensTab} from "@components/consumer/projects/tabs";
import {withPage} from "@components/panel";

const ApiTokensPage: FC = () => {
    const projectId = useCurrentProjectId();

    return (
        <ApiTokensTab projectId={projectId} />
    )
}

export default withPage(ApiTokensPage);