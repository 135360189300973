import React from 'react';
import { Card, CardHeader, CardTitle, ListGroup, ListGroupItem } from 'reactstrap';

interface NavigationProps {
    selected: number;
    onSelect: (id: number)=> void;
}
const Navigation: React.FC<NavigationProps> = ({ selected, onSelect }) => (
    <Card>
        <CardHeader>
            <CardTitle tag="h5" className="mb-0">
                Настройки личного кабинета
            </CardTitle>
        </CardHeader>
        <ListGroup flush>
            <ListGroupItem action active={selected === 0} onClick={() => onSelect(0)}>
                Главная
            </ListGroupItem>
            <ListGroupItem action active={selected === 1} onClick={() => onSelect(1)}>
                Пароль
            </ListGroupItem>
            <ListGroupItem action active={selected === 2} onClick={() => onSelect(2)}>
                Безопасность
            </ListGroupItem>
        </ListGroup>
    </Card>
);

export default React.memo(Navigation);