import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export function fromUtcDate(date: string) {
    moment.locale('ru');
    return moment.tz(date, 'Etc/UTC').tz(moment.tz.guess());
}

export function dateFormatter(utc: string) {
    return fromUtcDate(utc).format('lll');
}

/**
 * Get promise from the store's action creator async function.
 * Use this to intercept the results of your requests.
 * @param asyncActionCreator
 */
export function toPromise<V>(asyncActionCreator: (dispatch: Dispatch) => Promise<V>): Promise<V> {
    return (asyncActionCreator as any) as Promise<V>;
}

export function usePromisedDispatch() {
    return useDispatch() as <T>(action: ((dispatch: Dispatch) => T) | PayloadAction<any>) => T;
}

export function useModal() {
    const [isOpened, setOpened] = useState(false);

    const toggle = useCallback(() => setOpened(!isOpened), [isOpened]);
    const close = useCallback(() => setOpened(false), []);
    const open = useCallback(() => setOpened(true), []);

    return {
        isOpened,
        setOpened,
        toggle,
        close,
        open
    }
}