import { FieldProps } from 'formik';
import React from 'react';
import { FormGroup, Label, Input, FormFeedback, Col, FormText } from 'reactstrap';

interface Props {
    description?: string;
    label: string;
    showValid?: boolean;
    id?: string;
}

const FormikRowInput: React.FC<Props & FieldProps> = (prop) => {
    const {
        field: { ...fields },
        form: { touched, errors },
        description,
        ...props
    } = prop;

    const invalid = Boolean(touched[fields.name] && errors[fields.name]);

    return (
        <FormGroup row>
            <Label for={props.id} className={"label-color"} sm="3">{props.label}</Label>
            <Col sm="9">
                <Input invalid={invalid} {...props} {...fields} />
                {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
                {description ? <FormText>{description}</FormText> : ''}
            </Col>
        </FormGroup>
    );
}
export default React.memo(FormikRowInput);