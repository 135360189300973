import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export interface AuthStore {
    isAuthed: boolean;
}

const authSlice = createSlice({
    name: 'authStore',
    initialState: {
        isAuthed: false
    } as AuthStore,
    reducers: {
        setAuthed: (state, action: PayloadAction<boolean>) => {
            state.isAuthed = action.payload
        }
    }
});

export const { reducer: authReducer } = authSlice;
const { setAuthed } = authSlice.actions;

export const authActions = {
    setAuthed: (isAuthed: boolean) => (dispatch: Dispatch) => {
        dispatch(setAuthed(isAuthed));
    }
}