import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useParams, Redirect } from 'react-router-dom';
import { Card, CardText } from 'reactstrap';
import { auth } from '../../../services/authService';

interface ConfirmEmailPath {
    code: string;
}

interface ConfirmEmailState {
    done: boolean;
    success: boolean;
    fetching: boolean;
    error: boolean;
}

const ConfirmEmailPage: React.FC = () => {

    const [state, setState] = useState<ConfirmEmailState>({
        done: false,
        success: false,
        fetching: false,
        error: false
    });

    //const history = useHistory();
    const { code } = useParams<ConfirmEmailPath>();

    useEffect(() => {

        const { fetching, done, success } = state;
        
        const confirm = async () => {

            try {
                const result = await auth().confirmEmail(code);
                setState({
                    fetching: false,
                    done: true,
                    success: Boolean(result.ok),
                    error: false
                });
            }
            catch(e) {
                setState({
                    fetching: false,
                    done: true,
                    success: false,
                    error: true
                });
            }
        }

        if (!fetching && !done) {
            setState({
                ...state,
                fetching: true
            });

            confirm();
        }
        if (success) {
            toastr.success("Подтведждение почты", "Ваша почта успешно подтверждена");
        }
    }, [state, code]);

    const { error, done, success } = state;

    return (
        <React.Fragment>
            <div className={classNames('splash', { 'active': !done })}>
                <div className="splash-icon"></div>
            </div>
            <Card body className="text-center">
                <CardText>
                    {
                        success ? <Redirect to="/signin/" /> :
                            error ? 'Не удалось проверить код. Обновите страницу или попробуйте позднее.' :
                                !success && 'Код подтверждения не найден либо истек срок его действия.'
                    }
                </CardText>
            </Card>
        </React.Fragment>
    )
}

export default ConfirmEmailPage;