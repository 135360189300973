import {FC} from "react";
import {Card, CardHeader, CardTitle, Col, Fade, ListGroup, ListGroupItem, Row, TabContent, TabPane} from "reactstrap";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";
import {useHistory, useParams} from "react-router-dom";
import {withPage} from "@components/panel";
import {
    AntilopayList,
    EnotList,
    PaymentSettings,
    PayPalychList,
    PrimePaymentsList, StreamPayList,
    UnitpayList
} from "@components/consumer/payments";

interface MatchProps {
    project_id: string;
    gateway: string;
    settings_id?: string;
}

const GatewaysPage: FC = () => {

    const history = useHistory();
    const projectId = useCurrentProjectId();
    const params = useParams<MatchProps>();

    const gw = params.gateway ?? 'empty';
    const tab = params.settings_id ? 'settings' : gw;

    const onClick = (gateway: string) => {
        history.push(`/p/${projectId}/ps/gw/${gateway}/`);
    }

    return (
        <Row>
            <Col md={3} xl={2}>
                <Card>
                    <CardHeader>
                        <CardTitle tag='h5' className='mb-0'>
                            Платежные шлюзы
                        </CardTitle>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem
                            onClick={() => onClick('up')} action active={gw === 'up'}>Unitpay</ListGroupItem>
                        <ListGroupItem
                            onClick={() => onClick('eio')} action active={gw === 'eio'}>Enot.io</ListGroupItem>
                        <ListGroupItem
                            onClick={() => onClick('ppl')} action active={gw === 'ppl'}>PayPalych</ListGroupItem>
                        <ListGroupItem
                            onClick={() => onClick('pp')} action active={gw === 'pp'}>PrimePayments</ListGroupItem>
                        <ListGroupItem
                            onClick={() => onClick('anp')} action active={gw === 'anp'}>Antilopay</ListGroupItem>
                        <ListGroupItem
                            onClick={() => onClick('sp')} action active={gw === 'sp'}>StreamPay</ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col md={9} xl={10}>
                <TabContent activeTab={tab}>
                    <TabPane tabId='empty'>

                    </TabPane>
                    <TabPane tabId='up'>
                        <Fade in={tab === 'up'}>
                            <UnitpayList />
                        </Fade>
                    </TabPane>
                    <TabPane tabId='eio'>
                        <Fade in={tab === 'eio'}>
                            <EnotList />
                        </Fade>
                    </TabPane>
                    <TabPane tabId='ppl'>
                        <Fade in={tab === 'ppl'}>
                            <PayPalychList />
                        </Fade>
                    </TabPane>
                    <TabPane tabId='pp'>
                        <Fade in={tab === 'pp'}>
                            <PrimePaymentsList />
                        </Fade>
                    </TabPane>
                    <TabPane tabId='anp'>
                        <Fade in={tab === 'anp'}>
                            <AntilopayList />
                        </Fade>
                    </TabPane>
                    <TabPane tabId='sp'>
                        <Fade in={tab === 'sp'}>
                            <StreamPayList />
                        </Fade>
                    </TabPane>
                    <TabPane tabId='settings'>
                        <Fade in={tab === 'settings'}>
                            <PaymentSettings />
                        </Fade>
                    </TabPane>
                </TabContent>
            </Col>
        </Row>
    )
}

export default withPage(GatewaysPage);