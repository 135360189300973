import ServiceBase from "@api/serviceBase";
import { IStorage } from "@interfaces/consumer/projects";
import { PatchStorageModel, PutStorageModel } from "@models/consumer/storages";

export default class StoragesService extends ServiceBase {
    async getStorages() {
        return await this.getAndThrowApi<IStorage[]>('/admin/projects/storage/');
    }

    async putStorage(storage: PutStorageModel) {
        return await this.putApi<IStorage>('/admin/projects/storage/', storage);
    }

    async patchStorage(storage: PatchStorageModel) {
        return await this.patchApi<IStorage>('/admin/projects/storage/', storage);
    }

    async getStorage(id: string) {
        return await this.getAndThrowApi<IStorage>(`/admin/projects/storage/${id}/`);
    }

    async deleteStorage(id: string) {
        return await this.deleteApi(`/admin/projects/storage/${id}/`);
    }
}