import ServiceBase from "@api/serviceBase";
import { IMasterProxy } from "@interfaces/master/proxies";
import { AddProxyModel, PutProxyModel } from "@models/master/proxies";

export default class MasterProxyService extends ServiceBase {

    async putProxy(model: PutProxyModel) {
        return await this.putApi<IMasterProxy>('/admin/master/proxy/create/', model);
    }

    async addProxy(model: AddProxyModel) {
        return await this.putApi<IMasterProxy>('/admin/master/proxy/add/', model);
    }

    async getProxies() {
        return await this.getApi<IMasterProxy[]>('/admin/master/proxy/');
    }

    async getProxy(id: string) {
        return await this.getApi<IMasterProxy>(`/admin/master/proxy/${id}/`);
    }
}