import React, {FC, useCallback} from "react";
import {useModal, usePromisedDispatch} from "../../../../utils/helpers";
import {useHistory} from "react-router-dom";
import {usePrimes} from "@selectors/consumer/payments.selectors";
import {paymentActions} from "@store/consumer/projectsStore";
import {PutPrimePaymentSettings} from "@models/consumer/payments";
import {GatewaysList} from "@components/consumer/payments";
import {AddSettingsModal} from "@components/consumer/projects/payments";
import {Field} from "formik";
import {FormikRowInput} from "@components/panel/formik";
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";

const PrimePaymentsList: FC = () => {

    const dispatch = usePromisedDispatch();
    const project_id = useCurrentProjectId();
    const gateways = usePrimes(project_id);
    const {isOpened: isPOpened, toggle: togglePrime} = useModal();
    const history = useHistory();

    const onSelect = useCallback((gatewayId: string) => {
        history.push(`/p/${project_id}/ps/gw/pp/${gatewayId}/`);
    }, [history, project_id])

    const onDelete = useCallback((gatewayId: string) => dispatch(paymentActions.deletePrime(gatewayId)),
        [dispatch]);

    const createPrime = useCallback(async (model: PutPrimePaymentSettings) => await dispatch(paymentActions.addPrime(model)),
        [dispatch]);
    const nextPrimeOrder = gateways.reduce((order, settings) => Math.max(order, settings.order + 1), 0);

    return (
        <>
            <GatewaysList
                title="PrimePayments"
                gateways={gateways}
                onSelect={onSelect}
                onDelete={onDelete}
                onAdd={togglePrime}
            />
            <AddSettingsModal
                isOpen={isPOpened}
                toggle={togglePrime}
                projectId={project_id}
                nextOrder={nextPrimeOrder}
                createSettings={createPrime}>
                <Field
                    label="ID проекта в PrimePayments"
                    type="text"
                    name="merchantId"
                    placeholder="123456"
                    component={FormikRowInput} />
            </AddSettingsModal>
        </>
    )
}

export default PrimePaymentsList;