import React from 'react';
import classNames from "classnames";
import { Col, Container, Row } from 'reactstrap';

interface Props {
    className?: string;
}

const GuestLayout: React.FC<Props> = ({ children, className }) => (
    <main className={classNames("main", "h-100", "w-100", className)}>
        <Container className="h-100">
            <Row className="h-100">
                <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                    <div className="d-table-cell align-middle">{children}</div>
                </Col>
            </Row>
        </Container>
    </main>
)

export default GuestLayout;