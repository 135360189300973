import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { panelActions } from '../../../redux/panel/panelStore';

const NavbarToggle: React.FC = () => {

    const dispatch = useDispatch();
    const toggleSidebar = useCallback(() => dispatch(panelActions.toggleSidebar()), [dispatch]);

    return (
        <span
            className="sidebar-toggle d-flex mr-2"
            onClick={toggleSidebar}
        >
            <i className="hamburger align-self-center" />
        </span>
    );
}

export default React.memo(NavbarToggle);