import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, Table, Spinner } from 'reactstrap';
import { UserAuthLog } from '../../../interfaces/user/user';
import UserService from '../../../services/user/userService';
import { fromUtcDate } from '../../../utils/helpers';

const UserAuthLogs: React.FC = () => {

    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState<UserAuthLog[]>([]);

    useEffect(() => {

        const load = async () => {
            const api = new UserService();
            try {
                const logs = await api.fetchAuthLogs();
                setLogs(logs);
            }
            catch (e) { }
            setLoading(false);
        }

        load();

    }, []);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5">
                    История авторизаций
                </CardTitle>
            </CardHeader>
            <Table striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Дата</th>
                        <th>IP</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? <tr><td align="center" colSpan={3}><Spinner className="mr-2" color="primary" /></td></tr> :
                            logs.length === 0 ? <tr><td align="center" colSpan={3}>Записи отсутствуют</td></tr> :
                                logs.map((log, i) => (
                                    <tr key={log.id}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{fromUtcDate(log.date).format('lll')}</td>
                                        <td>{log.ip}</td>
                                    </tr>
                                ))
                    }
                </tbody>
            </Table>
        </Card>
    )
}

export default UserAuthLogs;