import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownToggle, UncontrolledDropdown, DropdownMenu, ListGroup, DropdownItem } from 'reactstrap';

interface NavbarDropdownProps {
  indicator?: boolean;
  icon: IconProp;
  active?: boolean;
  count?: number;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const NavbarDropdown: React.FC<NavbarDropdownProps> = ({
  children,
  count,
  header,
  footer,
  icon,
  active,
  indicator
}) => (
    <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active}>
      <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
        <FontAwesomeIcon icon={icon} className="align-middle" />
        {indicator ? <span className="indicator" /> : ""}
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg py-0">
        <div className="dropdown-menu-header position-relative">
          {count} {header}
        </div>
        <ListGroup>{children}</ListGroup>
        {footer && <DropdownItem header className="dropdown-menu-footer">
          <span className="text-muted">{footer}</span>
        </DropdownItem>}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

export default NavbarDropdown;