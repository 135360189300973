import {useCallback, useEffect, useMemo, useState} from "react";
import { useDispatch } from "react-redux";
import { Loadable } from "@interfaces/helpers";
import { Dispatch } from "@reduxjs/toolkit";
import {FetchResult} from "../../helpers";

export function useDataLoading<T>(loadData: (...params: any) => (dispatch: Dispatch) => Promise<any>, data: Loadable<T>, ...params: any[]) {

    const dispatch = useDispatch();
    const { loading, loaded, error } = data;

    useEffect(() => {
        if (!loading && !loaded && !error) {
            dispatch(loadData(...params));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, error, loadData, loaded, loading, ...params]);

    return data;
}

export function useStateLoader<T>(loadState: (...params: any) => Promise<FetchResult<T>>, ...params: any[]) {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<T>();
    const [error, setError] = useState<FetchResult<T>>();

    const reload = useCallback(() => {
        setLoading(true);
    }, [setLoading]);

    useEffect(() => {

        const load = async () => {
            const result = await loadState(...params);

            if (result.ok) {
                setData(result.result);
            }
            else {
                setError(result);
            }
            setLoading(false);
        }

        if (loading) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadState, loading, ...params]);

    return useMemo(() => {
        return {
            loading,
            data,
            reload,
            error
        }
    }, [data, loading, reload, error]);
}