import { FieldProps } from 'formik';
import React, { memo } from 'react';
import { FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';

interface Props {
    description?: string;
    label: string;
    showValid?: boolean;
    id?: string;
}

const FormikInput: React.FC<FieldProps & Props> = (prop) => {

    const {
        field: { ...fields },
        form: { touched, errors },
        description,
        label,
        showValid,
        id = fields.name,
        ...props
    } = prop;

    const invalid = Boolean(touched[fields.name] && errors[fields.name]);

    return (
        <FormGroup>
            <Label for={id}>{label}</Label>
            <Input id={id} invalid={invalid} valid={showValid && !invalid} {...props} {...fields}/>
            <FormFeedback valid={false}>{errors[fields.name]}</FormFeedback>
            {description && <FormText>{description}</FormText>}
        </FormGroup>
    )
}

export default memo(FormikInput);