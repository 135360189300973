import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, Table } from 'reactstrap';
import { AddMasterStorageModal } from '.';
import { useMasterStorages } from '@selectors/master.hooks';
import { AnimatedFontAwesomeIcon, TableSpinner, withPage } from '../../panel';

const StoragesList: React.FC = () => {

    const [modalOpened, setModalOpened] = useState(false);
    const { loading, data: storages } = useMasterStorages();

    const toggleModal = useCallback(() => setModalOpened(!modalOpened), [modalOpened]);

    return (
        <Card>
            <CardHeader>
                <div className="card-actions float-end">
                    <AnimatedFontAwesomeIcon
                        icon={faPlus}
                        fixedWidth
                        className="align-middle"
                        onClick={toggleModal}
                        animate="spin"
                    />
                </div>
                <CardTitle tag="h5">
                    Список хранилищ
                </CardTitle>
            </CardHeader>
            <Table className="table table-hover">
                <thead>
                    <tr>
                        <th>Название</th>
                        <th>Адрес</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? <TableSpinner colSpan={3} /> :
                            storages.length === 0 ? <tr><td align="center" colSpan={3}>Хранилищ пока нет</td></tr> :
                                storages.map(storage => (
                                    <tr key={storage.id}>
                                        <td>{storage.name}</td>
                                        <td>{storage.endpoint}</td>
                                        <td className="table-action">
                                            <Link to={`/master/storage/${storage.id}`}>
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    fixedWidth
                                                    className="align-middle mr-1"
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                    }
                </tbody>
            </Table>
            <AddMasterStorageModal isOpen={modalOpened} toggle={toggleModal} />
        </Card>
    )
}

export default withPage(StoragesList);