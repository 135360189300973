import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { UserInfo, UserNotification } from "../../interfaces/user/user";
import { PatchUserDataModel } from "../../models/user/data";
import UserService from "../../services/user/userService";

export interface UserStore {
    fetching: boolean;
    info: UserInfo;
    notifications: UserNotification[];
}

const userSlice = createSlice({
    name: 'userStore',
    initialState: {
        fetching: false,
        info: {
            avatar: '',
            balance: 0,
            createTime: '',
            email: '',
            publicName: '',
            lang: '',
            roles: [],
            userName: ''
        },
        notifications: []
    } as UserStore,
    reducers: {
        setFetching: (state, action: PayloadAction<boolean>) => {
            state.fetching = action.payload;
        },
        setInfo: (state, action: PayloadAction<UserInfo>) => {
            state.info = action.payload;
        },
        setNotifications: (state, action: PayloadAction<UserNotification[]>) => {
            state.notifications = action.payload;
        },
        readNotification: (state, action: PayloadAction<string>) => {
            state.notifications = state.notifications.map(n => {
                if(n.id === action.payload) {
                    n.isReaded = true;
                }
                return n;
            })
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.notifications = state.notifications.filter(n => n.id !== action.payload);
        },
        setPublicName: (state, action: PayloadAction<string>) => {
            state.info = {
                ...state.info,
                publicName: action.payload
            }
        }
    }
});

export const { reducer: userReducer } = userSlice;
const { setFetching, setInfo, setNotifications, readNotification, setPublicName } = userSlice.actions;

export const userActions = {
    fetchUserInfo: (silent: boolean = false) => async (dispatch: Dispatch) => {
        const api = new UserService();

        if(!silent)
            dispatch(setFetching(true));

        try {
            const info = await api.fetchUserInfo();
            dispatch(setInfo(info));

            if(!silent)
                dispatch(setFetching(false));
            return info;
        }
        catch (e) {
            if(!silent)
                dispatch(setFetching(false));
            return undefined;
        }
    },
    fetchUserNotifications: () => async (dispatch: Dispatch) => {
        const api = new UserService();

        try {
            const nots = await api.fetchNotifications();
            dispatch(setNotifications(nots));
            return nots;
        }
        catch(e) {
            return undefined;
        }
    },
    fetchAll: (silent: boolean = false) => async(dispatch: Dispatch) => {
        const api = new UserService();

        if(!silent)
            dispatch(setFetching(true));
        
        try {
            await Promise.all([
                api.fetchUserInfo().then(u => dispatch(setInfo(u))).catch(),
                api.fetchNotifications().then(n => dispatch(setNotifications(n))).catch()
            ]);
        }
        catch(e) {

        }

        if(!silent)
            dispatch(setFetching(false));

    },
    readNotification: (id: string) => async (dispatch: Dispatch) => {
        const api = new UserService();
        const result = await api.readNotification(id);
        if(result.ok) {
            dispatch(readNotification(id));
        }
        return result;
    },
    editUserData: (data: PatchUserDataModel) => async (dispatch: Dispatch) => {
        const api = new UserService();
        const result = await api.patchUserData(data);
        if(result.ok) {
            dispatch(setPublicName(data.publicName));
        }
        return result;
    }
}