import { GameServerListTable } from "@components/consumer/projects";
import { withPage } from "@components/panel";
import { useCurrentProject } from "@selectors/consumer/projects.selectors";
import { FC } from "react";

const GameServersListPage: FC = () => {

    const project = useCurrentProject();

    return (
        <GameServerListTable project={project} />
    )
}

export default withPage(GameServersListPage);