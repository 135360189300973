import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { SidebarItemLink } from '../../../interfaces/panel/sidebar';
import {useCurrentProjectId} from "@selectors/consumer/projects.selectors";

interface SidebarItemProps {
    item: SidebarItemLink;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ item }) => {

    const location = useLocation();
    const {
        name,
        badgeColor,
        badgeText,
        icon,
        path
    } = item;

    const projectId = useCurrentProjectId();
    const getSidebarItemClass = (path: string) => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <li className={"sidebar-item " + getSidebarItemClass(path)}>
            <NavLink to={path.replace(":project_id", projectId)} className="sidebar-link" activeClassName="active">
                {icon ? (
                    <React.Fragment>
                        <FontAwesomeIcon
                            icon={icon}
                            fixedWidth
                            className="align-middle mr-2"
                        />{" "}
                        <span className="align-middle">{name}</span>
                    </React.Fragment>
                ) : (
                        name
                    )}{" "}
                {badgeColor && badgeText ? (
                    <Badge color={badgeColor} size={18} pill className="sidebar-badge">
                        {badgeText}
                    </Badge>
                ) : null}
            </NavLink>
        </li>
    );
}

export default SidebarItem;