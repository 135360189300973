import {FC, useState} from "react";
import {Button, Card, CardHeader, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import {IPaymentSetting} from "@interfaces/consumer/payments";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {toastr} from "react-redux-toastr";
import {AnimatedFontAwesomeIcon} from "@components/panel";

interface GatewaysListProps {
    title: string;
    gateways: IPaymentSetting[];
    onSelect: (gatewayId: string) => void;
    onDelete: (gatewayId: string) => void;
    onAdd(): void;
}

const GatewaysList: FC<GatewaysListProps> = ({title, gateways, onSelect, onDelete, onAdd}) => {

    const [selected, setSelected] = useState<string | null>(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const toggle = () => setDeleteModal(!deleteModal);
    const confirm = () => {
        if(selected) {
            onDelete(selected);
        }
        toggle();
        setSelected(null);
    }

    const requestDelete = (gateway: IPaymentSetting) => {
        if(gateway.isActive){
            toastr.error("Ошибка", "Нельзя удалить активный шлюз");
            return;
        }
        setSelected(gateway.id);
        toggle();
    }

    return (
        <Card>
            <CardHeader>
                <div className='card-actions float-end'>
                    <AnimatedFontAwesomeIcon
                        icon={faPlus}
                        onClick={onAdd}
                    />
                </div>
                <CardTitle className='mb-0'>
                    {title}
                </CardTitle>
            </CardHeader>
            <Table>
                <thead>
                <tr>
                    <th>Название</th>
                    <th>Лимит</th>
                    <th>Валюты</th>
                    <th>Статус</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {
                    gateways.length === 0 ? (
                            <tr>
                                <td colSpan={5} align='center'>Нет шлюзов данного типа</td>
                            </tr>
                        ) :
                        gateways.map(g => (
                            <tr key={g.id}>
                                <td>{g.name}</td>
                                <td>{g.monthLimit}</td>
                                <td>{g.currencies?.join(', ') || g.defaultCurrency}</td>
                                <td>{g.isActive ? 'Активен' : 'Неактивен'}</td>
                                <td>
                                    <FontAwesomeIcon icon={faPen} onClick={() => onSelect(g.id)} className='me-2'/>
                                    <FontAwesomeIcon icon={faTrash} onClick={() => requestDelete(g)}/>
                                </td>
                            </tr>
                        ))
                }
                </tbody>
            </Table>
            <ConfirmDeleteModal
                gatewayName={gateways.find(x => x.id === selected)?.name ?? ''}
                isOpen={deleteModal} toggle={toggle}
                confirm={confirm} />
        </Card>
    )
}

interface ConfirmDeleteModalProps {
    gatewayName: string;
    isOpen: boolean;
    toggle(): void;
    confirm(): void;
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({gatewayName, isOpen, confirm, toggle}) => (
    <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader>
            Подтвердите удаление
        </ModalHeader>
        <ModalBody>
            Вы уверены, что хотите удалить шлюз {gatewayName}?
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={toggle}>Отмена</Button>
            <Button color="danger" onClick={confirm}>Удалить</Button>
        </ModalFooter>
    </Modal>
)

export default GatewaysList;